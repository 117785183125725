//ponemos el titulo de la pagina de acuerdo al modo de node
document.title = process.env.VUE_APP_TITLE;

try {
  window._ = require("lodash");
  window.Popper = require("popper.js").default;
  window.axios = require("axios");
  window.swal = require("sweetalert");
  window.moment = require("moment");
  moment.updateLocale("es-MX", {
    weekdays: "Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado".split("_"),
    months: "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split(
      "_"
    ),
    monthsShort: "Ene_Feb_Mzo_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split("_")
  });
  // eslint-disable-next-line no-empty
} catch (e) {}
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

if (localStorage.token) {
  window.axios.defaults.headers.common.Authorization =
    "Bearer " + localStorage.token;
}
