var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Buscar","hide-details":"auto","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-3",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","mobile-breakpoint":"0","items-per-page":_vm.items.length,"loading":_vm.loader,"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.organico",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.organico))+" ")]}},{key:"item.convencional",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.convencional))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.updated_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"success"}},[_c('v-icon',{on:{"click":function($event){return _vm.editar(item)}}},[_vm._v("mdi-pencil")])],1)]}}],null,true)}),_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Cambiar Precio "+_vm._s(_vm.resource.tipo_corte)+" ")]),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.updatePrice($event)}}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Orgánico","error-messages":_vm.errors.organico,"hide-details":"auto","type":"number"},model:{value:(_vm.resource.organico),callback:function ($$v) {_vm.$set(_vm.resource, "organico", $$v)},expression:"resource.organico"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Convencional","error-messages":_vm.errors.convencional,"hide-details":"auto","type":"number"},model:{value:(_vm.resource.convencional),callback:function ($$v) {_vm.$set(_vm.resource, "convencional", $$v)},expression:"resource.convencional"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v("actualizar")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }