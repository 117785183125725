import Index from "./../views/calculadora/Index.vue";

const routes = [
  {
    path: "/Calculadora-de-pagos",
    name: "calculadora",
    component: Index,
    meta: {
      name: "Cálculo de Pagos"
    }
  }
];
export default routes;
