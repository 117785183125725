var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items)?_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"purple lighten-4"}},[_vm._v(" Comisionistas ")]),_c('v-expansion-panel-content',[_c('v-row',{staticClass:"my-2"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","clearable":"","label":"Filtrar por Comisionista","type":"search","items":_vm.comisionistas},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.search)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","clearable":"","label":"Forma de Pago","type":"search","items":_vm.formas},model:{value:(_vm.forma),callback:function ($$v) {_vm.forma=$$v},expression:"forma"}})],1):_vm._e(),(_vm.forma)?_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.imprimir}},[_c('v-icon',[_vm._v(" mdi-printer ")])],1)],1):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1 mt-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"search":_vm.search,"items-per-page":_vm.items.length,"hide-default-footer":""},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.lote",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("folio")(item.lote))+" ")]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fecha))+" ")]}},{key:"item.kilos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numero")(item.kilos))+" ")]}},{key:"item.precio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.precio))+" ")]}},{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe))+" ")]}}],null,true)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }