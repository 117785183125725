<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header dark color="green lighten-4" @click="expandir">
      {{ title }}
    </v-expansion-panel-header>
    <mantto-form
      v-if="creating"
      :resource="resource"
      @cancelar="cancelar"
      @actualizar="getData"
    />
    <v-expansion-panel-content v-else>
      <v-form :disabled="loader" @submit.prevent="getData">
        <v-row class="mt-2">
          <v-col cols="12" md="4">
            <v-text-field
              dense
              outlined
              clearable
              label="Buscar"
              v-model="paginado.search"
            />
          </v-col>
          <v-col>
            <v-btn color="info" :disabled="loader" type="submit">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              color="success"
              class="ml-2"
              @click="crear"
              :disabled="loader"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-data-table
        dense
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        class="elevation-1 mt-2"
        :loading="loader"
        hide-default-footer
      >
        <template v-slot:[`item.date`]="{ item }">
          {{ item.date | date }}
        </template>
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | folio }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="success" @click="editar(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon color="error" @click="eliminar(item)" :disabled="loader">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <paginado :paginado="paginado" :loader="loader" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ManttoForm from "./ManntoForm.vue";
export default {
  name: "Mantto",
  components: { ManttoForm },
  data: () => ({
    headers: [
      { text: "Id", value: "id", width: "5%" },
      { text: "Fecha", value: "date", width: "7%" },
      { text: "Kilometraje", value: "odometer", width: "10%", align: "end" },
      { text: "Descripción", value: "description" },
      { text: "Acciones", value: "actions", align: "end", width: "5%" }
    ],
    items: undefined,
    paginado: { currentPage: 1, search: null, registros: undefined },
    creating: false,
    resource: { id: undefined }
  }),
  computed: {
    ...mapState({
      loader: state => state.loader,
      errors: state => state.errors
    }),
    title() {
      if (this.resource.id)
        return `Editando Manteminiento ${this.$options.filters.folio(
          this.resource.id
        )}`;
      return this.creating
        ? "Nuevo Mantenimiento"
        : "Bitacora de Mantenimiento";
    },
    page() {
      return this.paginado.currentPage;
    },
    search() {
      return this.paginado.search;
    }
  },
  methods: {
    ...mapMutations(["setLoader", "setErrors"]),
    expandir(event) {
      if (
        !event.currentTarget.classList.contains(
          "v-expansion-panel-header--active"
        )
      ) {
        if (!this.items) {
          this.getData();
        }
      }
    },
    getData() {
      this.resource = { id: undefined };
      let url = `Gerencia/vehicles/maintenances/${this.$route.params.id}`;
      this.paginado.registros = this.creating = undefined;
      this.items = [];
      this.setLoader();
      window.axios
        .get(url, {
          params: {
            page: this.paginado.currentPage,
            search: this.paginado.search
          }
        })
        .then(resp => {
          this.items = resp.data.items;
          this.paginado = resp.data.paginado;
        })
        .catch(e => this.$store.dispatch("errores", e))
        .finally(() => this.setLoader(false));
    },
    crear() {
      this.resource = { id: undefined };
      this.creating = true;
    },
    editar(item) {
      this.resource = Object.assign({}, item);
      this.creating = true;
    },
    cancelar() {
      this.resource = { id: undefined };
      this.creating = false;
    },
    eliminar(item) {
      this.$swal({
        title: `¿ Eliminar el mantenimiento ${this.$options.filters.folio(
          item.id
        )} ?`,
        icon: "question",
        showCancelButton: true
      }).then(result => {
        if (result.isConfirmed) {
          let url = `Gerencia/vehicles/maintenances/${item.id}`;
          this.setLoader();
          window.axios
            .delete(url)
            .then(resp => {
              this.$store.dispatch("message", resp.data.message);
              this.getData();
            })
            .finally(() => this.setLoader(false));
        }
      });
    }
  },
  watch: {
    page() {
      this.getData();
    },
    search(val) {
      if (!val) this.getData();
    }
  }
};
</script>
