var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"blue lighten-4"}},[_vm._v(" Seleccionados ")]),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',{staticClass:"mt-3"},[_vm._v("Pallets que se darán de baja")]),_c('v-data-table',{staticClass:"elevation-1 mt-3",attrs:{"dense":"","mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.items,"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.eliminar(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"item.cantidad",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("decimal")(item.cantidad))+" ")]}},{key:"item.kilos_vendidos",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"righted-input",attrs:{"dense":"","type":"number","step":"0.01","min":"0"},model:{value:(item.kilos_vendidos),callback:function ($$v) {_vm.$set(item, "kilos_vendidos", _vm._n($$v))},expression:"item.kilos_vendidos"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',{staticClass:"mt-3"},[_vm._v("Concentrado")]),_c('v-data-table',{staticClass:"elevation-1 mt-3",attrs:{"dense":"","mobile-breakpoint":"0","headers":_vm.headers2,"items":_vm.items2},scopedSlots:_vm._u([{key:"item.kilos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("decimal")(item.kilos))+" ")]}},{key:"item.precio_kilo",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"righted-input text-right",attrs:{"dense":"","type":"number","step":"0.01","min":"0"},model:{value:(item.precio_kilo),callback:function ($$v) {_vm.$set(item, "precio_kilo", _vm._n($$v))},expression:"item.precio_kilo"}})]}},{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(_vm.importe(item)))+" ")]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }