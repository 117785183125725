import router from "../../router";
const initialState = () => ({
  login: false,
  user: {},
  menu: [
    { title: "Inicio", link: "inicio", icon: "mdi-home" },
    {
      title: "Mis Datos",
      link: "editMe",
      icon: "mdi-account-box",
      class: "d-xl-none"
    },
    { title: "Precios", link: "prices", icon: "mdi-cash-usd" },
    { title: "Inventario", link: "inventario", icon: "mdi-view-module" },
    { title: "Ventas Nacionales", link: "ventas", icon: "mdi-point-of-sale" },
    { title: "Embarques", link: "embarques", icon: "mdi-truck" },
    { title: "Pallets", link: "pallets", icon: "mdi-alpha-p-box" },
    { title: "Calculadora", link: "calculadora", icon: "mdi-calculator" },
    { title: "Lotes", link: "lotes", icon: "mdi-apps" },
    { title: "Pagos", link: "pagos", icon: "mdi-currency-usd-circle" },
    { title: "Egresos", link: "egresos", icon: "mdi-currency-usd-circle" },
    { title: "Gráficas", link: "charts", icon: "mdi-chart-box" },
    { title: "Salir", link: "logout", icon: "mdi-power", class: "d-xl-none" }
  ],
  pwdChecked: false,
  loader: false,
  resource: undefined
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_LOGIN(state, payload) {
      state.login = payload;
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    setPwdChecked(state, payload = false) {
      state.pwdChecked = payload;
    },
    setLoader(state, payload = true) {
      state.loader = payload;
    },
    setResource(state, payload) {
      state.resource = payload;
    }
  },
  actions: {
    async login({ commit, state, dispatch }) {
      commit("setErrors", [], { root: true });
      let user = state.user;
      user.tipo = "Gerencia";
      await window.axios
        .post("auth/login", user)
        .then(resp => {
          let payload = resp.data;
          window.axios.defaults.headers.common.Authorization =
            "Bearer " + payload.access_token; // Very important to have "Bearer "
          localStorage.setItem("token", payload.access_token);
          commit("SET_LOGIN", true);
          dispatch("checkUser");
          commit("setErrors", [], { root: true });
          router.push({ name: "inicio" }).catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }));
    },
    async checkUser({ commit, dispatch }) {
      commit("setErrors", [], { root: true });
      if (!localStorage.token) {
        commit("SET_LOGIN", false);
        router.push("/").catch(() => {});
      } else {
        await window.axios
          .post("auth/me")
          .then(resp => {
            commit("SET_USER", resp.data);
            commit("SET_LOGIN", true);
          })
          .catch(e => dispatch("errores", e, { root: true }));
      }
    },
    async logout({ commit, dispatch }) {
      window.axios
        .post("auth/logout")
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1500 });
          commit("SET_LOGIN", false);
          commit("SET_USER", {});
          localStorage.clear();
          router.push("/").catch(() => {});
          commit("setErrors", [], { root: true });
          dispatch("reset", null, { root: true });
        })
        .catch(e => dispatch("errores", e, { root: true }));
    },
    async revisarPwd({ commit, dispatch }, data) {
      commit("setErrors", [], { root: true });
      commit("setLoader");
      await window.axios
        .post("auth/checkPass2", data)
        .then(resp => {
          commit("setPwdChecked", true);
          commit("setResource", resp.data.resource);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async updateMe({ commit, dispatch, state }) {
      commit("setLoader");
      commit("setErrors", {}, { root: true });
      await window.axios
        .post("auth/updateMe2", state.resource)
        .then(resp => {
          dispatch("message", resp.data.message, { root: true });
          if (resp.data.reiniciar) {
            dispatch("logout");
          } else {
            dispatch("checkUser");
            router.push({ name: "inicio" }).catch(() => {});
          }
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    }
  }
};
