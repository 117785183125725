const routes = [
  {
    path: "/Proveedores",
    name: "providers",
    component: require("@/views/providers/Index.vue").default,
    meta: {
      name: "Catálogo de Proveedores"
    }
  },
  {
    path: "/Proveedores/detalles/:id",
    name: "providers.show",
    component: require("@/views/providers/Show.vue").default,
    meta: {
      name: "Detalles del Proveedor"
    }
  }
];
export default routes;
