<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-form @submit.prevent="getAll()" :disabled="loader">
        <v-row>
          <v-col cols="12" md="4" xl="2">
            <v-text-field
              dense
              outlined
              clearable
              label="Fecha Inicial"
              v-model="paginado.inicio"
              type="date"
            />
          </v-col>
          <v-col cols="12" md="4" xl="2">
            <v-text-field
              dense
              outlined
              clearable
              label="Fecha Final"
              v-model="paginado.fin"
              type="date"
            />
          </v-col>
          <v-col cols="12" md="6" xl="4">
            <v-autocomplete
              dense
              outlined
              clearable
              label="Filtrar por Cliente"
              :items="paginado.customers"
              v-model="paginado.customer_id"
            />
          </v-col>
          <v-col>
            <v-btn color="info" type="submit" :disabled="loader">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              color="success"
              :disabled="loader"
              class="ml-2"
              @click="newResource"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-data-table
        dense
        mobile-breakpoint="0"
        hide-default-footer
        :headers="headers"
        :items="items"
        class="elevation-1"
        :item-class="rowClasses"
        :loading="loader"
        :items-per-page="paginado.perPage"
      >
        <template v-slot:[`item.FechaPago`]="{ item }">
          {{ item.FechaPago | date }}
        </template>
        <template v-slot:[`item.Monto`]="{ item }">
          {{ item.Monto | moneda }}
        </template>
        <template v-slot:[`item.TipoCambioP`]="{ item }">
          {{ item.TipoCambioP | monedaSat }}
        </template>
        <template v-slot:[`item.Importe`]="{ item }">
          {{ item.Importe | moneda }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="primary" :disabled="loader" @click="mostrar(item)">
            <v-icon>mdi-information</v-icon>
          </v-btn>
         <v-btn icon color="secondary" @click="print(item)">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <Paginado :loader="loader" :paginado="paginado" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Printd } from "printd";
export default {
  name: "Index",
  data: () => ({
    headers: [
      { text: "Fecha", value: "FechaPago" },
      { text: "Folio", value: "Folio" },
      { text: "Serie", value: "Serie" },
      { text: "Cliente", value: "Customer" },
      { text: "Moneda", value: "MonedaP" },
      { text: "Importe", value: "Monto", align: "end" },
      { text: "Tipo de Cambio", value: "TipoCambioP", align: "end" },
      { text: "Importe en Pesos", value: "Importe", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
     d: undefined
  }),
  computed: {
    ...mapState({
      loader: state => state.Payments.loader,
      paginado: state => state.Payments.paginado,
      items: state => state.Payments.collection
    }),
    actual() {
      return this.paginado.currentPage;
    },
    cliente() {
      return this.paginado.customer_id;
    }
  },
  methods: {
    ...mapActions("Payments", ["getAll", "getResource", "newResource"]),
    mostrar(item) {
      this.getResource(item.id);
    },
    print(item) {
      const el = document.createElement("p");
      el.innerHTML = item.html;
      this.d.print(el);
    }
  },
  mounted() {
    this.getAll();
    this.d = new Printd();
  },
  watch: {
    actual() {
      this.getAll();
    },
    cliente() {
      this.getAll();
    }
  }
};
</script>
