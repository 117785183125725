<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Datos de la huerta
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="mt-3">
        <v-col> Huerta: {{ huerta.nombre }} </v-col>
        <v-col> Productor: {{ huerta.productor }} </v-col>
      </v-row>
      <v-row>
        <v-col> N° Sagarpa: {{ huerta.sagarpa }} </v-col>
        <v-col> Municipio: {{ huerta.municipio }} </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Huerta",
  computed: {
    ...mapState({
      huerta: state => state.Lotes.resource.huerta
    })
  }
};
</script>
