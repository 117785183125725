<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header dark color="blue lighten-4" @click="expandir">
      {{ title }}
    </v-expansion-panel-header>
    <v-expansion-panel-content v-if="upload">
      <v-form :disabled="loader" @submit.prevent="enviarArchivos">
        <v-card-text>
          <v-col>
            <v-file-input
              multiple
              v-model="files"
              outlined
              dense
              label="Seleccione los Archivos"
              small-chips
              clearable
              counter
              show-size
              truncate-length="50"
              :error-messages="errors.files"
              accept=".pdf,image/png, image/jpeg, image/bmp"
              deletable-chips
            />
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" type="submit" :disabled="loader">
            enviar
          </v-btn>
          <v-btn color="error" @click="upload = false" :disabled="loader">
            cancelar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-expansion-panel-content>
    <v-expansion-panel-content v-else>
      <v-form :disabled="loader" @submit.prevent="getData">
        <v-row class="mt-2">
          <v-col cols="12" md="4">
            <v-text-field
              dense
              outlined
              clearable
              label="Buscar"
              v-model="paginado.search"
            />
          </v-col>
          <v-col>
            <v-btn color="info" :disabled="loader" type="submit">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              color="success"
              class="ml-2"
              @click="agregar"
              :disabled="loader"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-data-table
        dense
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        class="elevation-1 mt-2"
        :loading="loader"
        hide-default-footer
      >
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at | dateTime }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="success"
            v-if="item.url"
            :href="item.url"
            target="_blank"
            :disabled="loader"
          >
            <v-icon>mdi-cloud-download</v-icon>
          </v-btn>
          <v-btn icon color="error" @click="eliminar(item)" :disabled="loader">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <paginado :paginado="paginado" :loader="loader" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Archivos",
  data: () => ({
    headers: [
      { text: "Nombre del archivo", value: "name" },
      { text: "Fecha de Subida", value: "created_at" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    items: undefined,
    paginado: { currentPage: 1, search: undefined, registros: undefined },
    upload: false,
    files: []
  }),
  computed: {
    ...mapState({
      loader: state => state.loader,
      errors: state => state.errors
    }),
    url() {
      return `/Gerencia/vehicles/archivos/${this.$route.params.id}`;
    },
    title() {
      return this.upload ? "Seleccionar archivos" : "Archivos Relacionados";
    }
  },
  methods: {
    ...mapMutations(["setLoader", "setErrors"]),
    ...mapActions(["errores", "message"]),
    agregar() {
      this.upload = true;
      this.files = [];
    },
    getData() {
      this.setLoader();
      this.setErrors();
      this.items = [];
      this.upload = false;
      this.paginado.registros = undefined;
      window.axios
        .get(this.url, {
          params: { page: this.paginado.currentPage }
        })
        .then(resp => {
          this.items = resp.data.collection;
          this.paginado = resp.data.paginado;
        })
        .catch(e => this.errores(e))
        .finally(() => this.setLoader(false));
    },
    expandir(event) {
      if (
        !event.currentTarget.classList.contains(
          "v-expansion-panel-header--active"
        )
      ) {
        this.getData();
      }
    },
    enviarArchivos() {
      this.setLoader();
      this.setErrors();
      let formData = new FormData();
      for (var i = 0; i < this.files.length; i++) {
        formData.append("files[]", this.files[i]);
      }
      window.axios
        .post(this.url, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(resp => {
          this.message(resp.data.message);
          this.upload = false;
          this.getData();
          this.files = [];
        })
        .catch(e => this.errores(e))
        .finally(() => this.setLoader(false));
    },
    eliminar(item) {
      this.$swal({
        title: `¿Eliminar el archivo ${item.name}?`,
        text: "Esta acción no puede deshacerse",
        icon: "warning",
        showCancelButton: true
      }).then(result => {
        if (result.isConfirmed) {
          this.setLoader;
          window.axios
            .delete(`deleteArchivo/${item.id}`)
            .then(resp => {
              this.message(resp.data.message);
              this.getData();
            })
            .catch(e => this.errores(e))
            .finally(() => this.setLoader(false));
        }
      });
    }
  }
};
</script>
