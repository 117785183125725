const routes = [
  {
    path: "/Vehiculos",
    name: "vehicles",
    component: require("@/views/vehicles/Index.vue").default,
    meta: {
      name: "Control de Vehículos"
    }
  },
  {
    path: "/Vehiculos/nuevo",
    name: "vehicles.create",
    component: require("@/views/vehicles/Show.vue").default,
    meta: {
      name: "Nuevo Vehículo"
    }
  },
  {
    path: "/Vehiculos/editar/:id",
    name: "vehicles.edit",
    component: require("@/views/vehicles/Show.vue").default,
    meta: {
      name: "Editar Vehículo"
    }
  }
];

export default routes;
