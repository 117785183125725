<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-expansion-panels multiple v-if="resource">
        <Invoice />        
        <Complemento v-if="complemento" />
        <Concepts v-else/>
        <Pagos />
      </v-expansion-panels>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="$router.go(-1)">
        regresar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Invoice from "./PanelFactura.vue";
import Concepts from "./PanelConceptos.vue";
import Pagos from "./PanelPagos.vue";
import Complemento from "./PanelComplemento.vue";
export default {
  name: "Show",
  components: { Invoice, Concepts, Pagos, Complemento },
  computed: {
    ...mapState({
      resource: state => state.Egresos.resource,
      complemento: state => state.Egresos.xml.Complemento.Pagos
    })
  },
  methods: {
    ...mapActions("Egresos", ["getResource"])
  },
  beforeMount() {
    if (!this.resource) {
      this.getResource(this.$route.params.id);
    }
  }
};
</script>
