import Ventas from "./../views/ventas/index.vue";
import NuevaVenta from "./../views/ventas/new.vue";
import DetalleVenta from "./../views/ventas/show.vue";

const routes = [
  {
    path: "/Ventas/detalles/:id",
    name: "venta.show",
    component: DetalleVenta,
    meta: {
      name: "Detalles Venta"
    }
  },
  {
    path: "/Ventas/nueva",
    name: "venta.create",
    component: NuevaVenta,
    meta: {
      name: "Nueva Venta"
    }
  },
  {
    path: "/Ventas",
    name: "ventas",
    component: Ventas,
    meta: {
      name: "Control de Ventas"
    }
  },
  {
    path: "/Ventas/editar/:id",
    name: "venta.edit",
    component: require("@/views/ventas/Edit.vue").default,
    meta: {
      name: "Editar Venta"
    }
  }
];
export default routes;
