const routes = [
  {
    path: "/Pagos-Facturas",
    name: "payments",
    component: require("@/views/payments/Index.vue").default,
    meta: {
      name: "Control de Complementos de Pagos"
    }
  },
  {
    path: "/Pagos-Facturas/detalles/:id",
    name: "payments.show",
    component: require("@/views/payments/Show.vue").default,
    meta: {
      name: "Detalle Complemento de Pago"
    }
  },
  {
    path: "/Pagos-Facturas/nuevo",
    name: "payments.create",
    component: require("@/views/payments/Nuevo.vue").default,
    meta: {
      name: "Nuevo Complemento de Pago"
    }
  }
];
export default routes;
