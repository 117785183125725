<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-form @submit.prevent="getAll()" :disabled="loader">
        <v-row>
          <v-col cols="12" md="4" xl="2">
            <v-text-field
              dense
              outlined
              clearable
              label="Fecha Inicial"
              v-model="paginado.inicio"
              type="date"
            />
          </v-col>
          <v-col cols="12" md="4" xl="2">
            <v-text-field
              dense
              outlined
              clearable
              label="Fecha Final"
              v-model="paginado.fin"
              type="date"
            />
          </v-col>
          <v-col>
            <v-autocomplete
              dense
              outlined
              clearable
              label="Filtrar por Cliente"
              :items="paginado.customers"
              v-model="paginado.customer_id"
            />
          </v-col>
          <v-col>
            <v-btn color="info" type="submit" :disabled="loader">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        class="elevation-1 mt-2"
        :items-per-page="paginado.perPage"
        hide-default-footer
        mobile-breakpoint="0"
        :loading="loader"
      >
        <template v-slot:[`item.TipoCambio`]="{ item }">
          {{ item.TipoCambio | monedaSat }}
        </template>
        <template v-slot:[`item.Importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.Total`]="{ item }">
          {{ item.Total | moneda }}
        </template>
        <template v-slot:[`item.Fecha`]="{ item }">
          {{ item.Fecha | date }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="success"
            :disabled="loader"
            v-if="!item.CfdiRelacionado"
            @click="modificar(item)"
          >
            <v-icon>mdi-check-box-multiple-outline</v-icon>
          </v-btn>
          <v-btn icon color="secondary" @click="print(item)">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.CfdiRelacionado`]="{ item }">
          {{ item.CfdiRelacionado }}
          <v-btn
            class="ml-4"
            icon
            color="error"
            :disabled="loader"
            @click="eliminarRelacion(item)"
            v-if="item.CfdiRelacionado && !item.Folio.includes('NOTAS')"
          >
            <v-icon>mdi-file-remove-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <Paginado :loader="loader" :paginado="paginado" />
    </v-card-text>
    <v-dialog v-model="dialog" max-width="600">
      <v-card :loading="loader">
        <v-card-title class="text-h5 grey lighten-2">
          Relacionar con Factura
        </v-card-title>
        <v-card-text class="mt-3">
          <v-row class="mb-3">
            <v-col> Importe de la Nota : {{ nota.Total | moneda }} </v-col>
          </v-row>
          <v-autocomplete
            dense
            outlined
            clearable
            label="Seleccione Factura"
            v-model="nota.CfdiRelacionado"
            :items="nota.posibles"
            :disabled="loader"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="guardar" :disabled="loader">
            guardar
          </v-btn>
          <v-btn color="error" @click="dialog = false" :disabled="loader">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { Printd } from "printd";
export default {
  name: "Index",
  data: () => ({
    d: undefined,
    headers: [
      { text: "Fecha", value: "Fecha" },
      { text: "Folio", value: "Folio" },
      { text: "Cliente", value: "customer" },
      { text: "Moneda", value: "Moneda" },
      { text: "Importe", value: "Total", align: "end" },
      { text: "Tipo de Cambio", value: "TipoCambio", align: "end" },
      { text: "Importe en Pesos", value: "Importe", align: "end" },
      { text: "Factura Relacionada", value: "CfdiRelacionado", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    dialog: false,
    nota: {}
  }),
  computed: {
    ...mapState({
      loader: state => state.Notas.loader,
      items: state => state.Notas.collection,
      paginado: state => state.Notas.paginado
    }),
    actual() {
      return this.paginado.currentPage;
    },
    cliente() {
      return this.paginado.customer_id;
    }
  },
  methods: {
    ...mapActions("Notas", ["getAll", "updateNota"]),
    modificar(item) {
      this.dialog = true;
      this.nota = Object.assign({}, item);
    },
    guardar() {
      if (this.nota.CfdiRelacionado) {
        let seleccionada = this.nota.posibles.find(
          x => x.value == this.nota.CfdiRelacionado
        );
        this.$swal({
          title: `¿Asignar a la factura ${seleccionada.text}?`,
          showCancelButton: true
        }).then(result => {
          if (result.isConfirmed) {
            this.$store.commit("Notas/setLoader");
            window.axios
              .put(`Gerencia/updateNota/${this.nota.id}`, this.nota)
              .then(resp => {
                this.$store.dispatch("message", resp.data.message);
                this.getAll();
                this.dialog = false;
              })
              .catch(e => this.$store.dispatch("errores", e))
              .finally(() => this.$store.commit("Notas/setLoader", false));
          } else {
            this.dialog = false;
          }
        });
      }
    },
    eliminarRelacion(item) {
      let title = `¿Eliminar la relación con la factura ${item.CfdiRelacionado}?`;
      this.$swal({
        title,
        showCancelButton: true
      }).then(result => {
        if (result.isConfirmed) {
          this.nota = Object.assign({}, item);
          this.nota.CfdiRelacionado = null;
          this.$store.commit("Notas/setLoader");
          window.axios
            .put(`Gerencia/updateNota/${this.nota.id}`, this.nota)
            .then(resp => {
              this.$store.dispatch("message", resp.data.message);
              this.getAll();
            })
            .catch(e => this.$store.dispatch("errores", e))
            .finally(() => this.$store.commit("Notas/setLoader", false));
        }
      });
    },
    print(item) {
      const el = document.createElement("p");
      el.innerHTML = item.html;
      this.d.print(el);
    }
  },
  mounted() {
    this.getAll();
    this.d = new Printd();
  },
  watch: {
    actual() {
      this.getAll();
    },
    cliente() {
      this.getAll();
    }
  }
};
</script>
