<!-- eslint-disable -->
<template>
  <v-card height="100%" v-if="resource">
    <v-card-text>
      <v-expansion-panels multiple v-model="panel">
        <Huerta />
        <Lote @actualizar="getData" />
        <Pagos />
      </v-expansion-panels>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="$router.go(-1)">regresar</v-btn>
      <v-btn color="primary" @click="print()">
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Pagos from "./PannelPagos";
import Huerta from "./PanelHuerta";
import Lote from "./PanelLote";
import { Printd } from "printd";
import { mapActions, mapState } from "vuex";
export default {
  name: "Details",
  components: { Pagos, Huerta, Lote },
  data: () => ({
    d: new Printd(),
    panel: []
  }),
  methods: {
    print() {
      let url = `lotes/printLote/${this.$route.params.id}`;
      window.axios.get(url).then(resp => {
        const el = document.createElement("p");
        el.innerHTML = resp.data;
        this.d.print(el);
      });
    },
    ...mapActions("Lotes", ["getLote"]),
    getData() {
      this.panel = [];
      this.getLote(this.$route.params.id);
    }
  },
  computed: {
    ...mapState({
      resource: state => state.Lotes.resource
    })
  },
  beforeMount() {
    if (!this.resource) {
      this.getLote(this.$route.params.id);
    }
  }
};
</script>
