<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="blue lighten-4">
      Productos de la Venta
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" md="6">
          <h4>Producto por Pallet</h4>
          <v-data-table
            dense
            :headers="headers"
            :items="items"
            class="elevation-1 mt-3"
            :items-per-page="items.length"
            hide-default-footer
          >
            <template v-slot:[`item.importe`]="{ item }">
              {{ item.importe | moneda }}
            </template>
            <template v-slot:[`item.precio`]="{ item }">
              {{ item.precio | moneda }}
            </template>
            <template v-slot:[`item.kilos`]="{ item }">
              {{ item.kilos | decimal }}
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" md="6">
          <h4>Concentrado</h4>
          <v-data-table
            dense
            :headers="headers2"
            :items="items2"
            class="elevation-1 mt-3"
            :items-per-page="items2.length"
            hide-default-footer
          >
            <template v-slot:[`item.importe`]="{ item }">
              {{ item.importe | moneda }}
            </template>
            <template v-slot:[`item.precio`]="{ item }">
              {{ item.precio | moneda }}
            </template>
            <template v-slot:[`item.kilos`]="{ item }">
              {{ item.kilos | decimal }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "PanelDetalles",
  data: () => ({
    headers: [
      { text: "Producto", value: "producto" },
      { text: "Pallet", value: "pallet" },
      { text: "Kilos", value: "kilos", align: "end" },
      { text: "Precio U", value: "precio", align: "end" },
      { text: "Importe", value: "importe", align: "end" }
    ],
    headers2: [
      { text: "Producto", value: "producto" },
      { text: "Kilos", value: "kilos", align: "end" },
      { text: "Precio U", value: "precio", align: "end" },
      { text: "Importe", value: "importe", align: "end" }
    ]
  }),
  computed: {
    ...mapState({
      items: state => state.Ventas.details
    }),
    items2() {
      let productos = [...new Set(this.items.map(x => x.producto))].sort(),
        data = [];
      productos.forEach(producto => {
        let items = this.items.filter(x => x.producto == producto),
          item = {
            producto,
            kilos: window._.sumBy(items, "kilos"),
            total: 0,
            precio: items[0].precio,
            importe: window._.sumBy(items, "importe")
          };
        data.push(item);
      });
      return data;
    }
  }
};
</script>
