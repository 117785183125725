import router from "../../router";
const url = "/Gerencia/pagos";
const initialState = () => ({
  collection: [],
  resource: {},
  loader: false,
  fechas: {},
  egresos: undefined
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    setLoader(state, payload = false) {
      state.loader = payload;
    },
    setCollection(state, payload = []) {
      state.collection = payload;
    },
    setFechas(state, payload) {
      state.fechas = payload;
    },
    setEgresos(state, payload = undefined) {
      state.egresos = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch, state }) {
      commit("setCollection");
      commit("setLoader", true);
      commit("setEgresos");
      window.axios
        .get(url, {
          params: {
            inicio: state.fechas.inicio,
            fin: state.fechas.fin
          }
        })
        .then(resp => {
          commit("setFechas", resp.data.fechas);
          commit("setCollection", resp.data.collection);
          commit("setEgresos", resp.data.egresos);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader"));
    }
  }
};
