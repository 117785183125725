<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Notas de Crédito
    </v-expansion-panel-header>
    <v-expansion-panel-content class="mt-3" v-if="items">
      <v-btn
        small
        color="success"
        @click="nueva"
        v-if="resource.pendiente > 0"
        :disabled="loader"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        class="elevation-1 mt-2"
        :items-per-page="items.length"
        hide-default-footer
        mobile-breakpoint="0"
        :loading="loader"
      >
        <template v-slot:[`item.TipoCambio`]="{ item }">
          {{ item.TipoCambio | monedaSat }}
        </template>
        <template v-slot:[`item.Importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.Total`]="{ item }">
          {{ item.Total | moneda }}
        </template>
        <template v-slot:[`item.Fecha`]="{ item }">
          {{ item.Fecha | date }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <!-- <v-btn
            icon
            color="error"
            @click="deleteItem(item)"
            :disabled="loader"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn> -->
        </template>
      </v-data-table>
    </v-expansion-panel-content>
    <v-expansion-panel-content class="mt-3" v-else>
      <v-btn
        small
        color="success"
        @click="nueva"
        v-if="resource.pendiente > 0"
        :disabled="loader"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-expansion-panel-content>
    <v-dialog v-model="dialog" max-width="600">
      <v-card :loading="loader">
        <v-card-title class="text-h5 grey lighten-2">
          Nueva Nota de Crédito Factura {{ resource.Serie }}
          {{ resource.Folio }}
        </v-card-title>
        <v-form @submit.prevent="guardar" :disabled="loader">
          <v-card-text class="mt-1">
            Importe Pendiente: {{ resource.pendiente | moneda }}
            <v-row>
              <v-col>
                <v-text-field
                  dense
                  outlined
                  clearable
                  type="number"
                  label="Importe"
                  v-model="importe"
                  :error-messages="errors.importe"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" :disabled="loader" type="submit">
              guardar
            </v-btn>
            <v-btn color="error" @click="dialog = false" :disabled="loader">
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Notas",
  computed: {
    ...mapState({
      items: state => state.Invoices.notas,
      loader: state => state.Invoices.loader,
      resource: state => state.Invoices.resource,
      errors: state => state.errors
    })
  },
  data: () => ({
    headers: [
      { text: "Fecha", value: "Fecha" },
      { text: "Folio", value: "Folio" },
      { text: "Folio Sat", value: "UUID" },
      { text: "Moneda", value: "Moneda" },
      { text: "Importe", value: "Total", align: "end" },
      { text: "Tipo de Cambio", value: "TipoCambio", align: "end" },
      { text: "Importe en Pesos", value: "Importe", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    dialog: false,
    importe: null
  }),
  methods: {
    ...mapActions("Invoices", ["getResource"]),
    nueva() {
      this.$store.commit("setErrors", {});
      this.importe = null;
      this.dialog = true;
    },
    guardar() {
      this.$store.commit("setErrors", {});
      this.$store.commit("Invoices/setLoader");
      window.axios
        .post(`Gerencia/createNota/${this.resource.id}`, {
          importe: this.importe
        })
        .then(resp => {
          this.importe = null;
          this.dialog = false;
          this.$store.dispatch("message", resp.data.message);
          this.getResource(this.$route.params.id);
        })
        .catch(e => this.$store.dispatch("errores", e))
        .finally(() => this.$store.commit("Invoices/setLoader", false));
    },
    deleteItem(item) {
      let title = item.Folio.includes("FAKE")
          ? `¿Eliminar la nota ${item.Folio}?`
          : `¿Cancelar la nota ${item.Folio}?`,
        text = "Esta accion no puede deshacerse";
      this.$swal({
        title,
        text,
        icon: "warning",
        showCancelButton: true
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.commit("setErrors", {});
          this.$store.commit("Invoices/setLoader");
          window.axios
            .delete(`Gerencia/deleteNota/${item.id}`)
            .then(resp => {
              this.$store.dispatch("message", resp.data.message);
              this.getResource(this.$route.params.id);
            })
            .catch(e => this.$store.dispatch("errores", e))
            .finally(() => this.$store.commit("Invoices/setLoader", false));
        }
      });
    }
  }
};
</script>
