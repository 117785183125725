<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-form @submit.prevent="getAll('na')" :disabled="loader">
        <v-row>
          <v-col cols="12" md="4" xl="2">
            <v-text-field
              dense
              outlined
              clearable
              label="Fecha Inicial"
              v-model="paginado.inicio"
              type="date"
            />
          </v-col>
          <v-col cols="12" md="4" xl="2">
            <v-text-field
              dense
              outlined
              clearable
              label="Fecha Final"
              v-model="paginado.fin"
              type="date"
            />
          </v-col>
          <v-col>
            <v-autocomplete
              dense
              outlined
              clearable
              label="Filtrar por Cliente"
              :items="paginado.customers"
              v-model="paginado.customer_id"
            />
          </v-col>
          <v-col>
            <v-btn color="info" type="submit" :disabled="loader">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              color="success"
              class="ml-3"
              @click="nueva"
              :disabled="loader"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        class="elevation-1 mt-2"
        :items-per-page="paginado.perPage"
        hide-default-footer
        mobile-breakpoint="0"
      >
        <template v-slot:[`item.TipoCambio`]="{ item }">
          {{ item.TipoCambio | monedaSat }}
        </template>
        <template v-slot:[`item.Importe`]="{ item }">
          {{ item.Importe | moneda }}
        </template>
        <template v-slot:[`item.Total`]="{ item }">
          {{ item.Total | moneda }}
        </template>
        <template v-slot:[`item.Fecha`]="{ item }">
          {{ item.Fecha | date }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="primary"
            @click="getResource(item.id)"
            :disabled="loader"
          >
            <v-icon>mdi-information</v-icon>
          </v-btn>
          <v-btn icon color="secondary" @click="print(item)">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
          <v-btn icon color="success" @click="email(item)">
            <v-icon>mdi-email</v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <Paginado :loader="loader" :paginado="paginado" />
      <v-row class="mt-2" v-if="items && items.length">
        <v-col>
          Importe del Periodo en Pesos: {{ paginado.pesos | moneda }}
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Enviar Factura {{ seleccionado.Folio }} por Correo
        </v-card-title>
        <v-card-text class="mt-2">
          <v-row>
            <v-col>
              <v-text-field
                dense
                outlined
                clearable
                label="Destinatarios"
                v-model="seleccionado.email"
                hint="Ingresa los correos de destino separados por punto y coma ( ; )"
                :error-messages="errors.emails"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="enviar">
            enviar
          </v-btn>
          <v-btn color="error" @click="cancelar">cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { Printd } from "printd";
export default {
  name: "Nacionales",
  data: () => ({
    d: undefined,
    headers: [
      { text: "Fecha", value: "Fecha" },
      { text: "Folio", value: "Folio" },
      { text: "Forma de Pago", value: "FormaPago" },
      { text: "Método de Pago", value: "MetodoPago" },
      { text: "Cliente", value: "customer" },
      { text: "Moneda", value: "Moneda" },
      { text: "Importe", value: "Total", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    seleccionado: {},
    dialog: false
  }),
  computed: {
    ...mapState({
      items: state => state.Invoices.collection,
      loader: state => state.Invoices.loader,
      paginado: state => state.Invoices.paginado,
      errors: state => state.errors
    }),
    actual() {
      return this.paginado.currentPage;
    },
    cliente() {
      return this.paginado.customer_id;
    }
  },
  methods: {
    ...mapActions("Invoices", ["getAll", "getResource", "createNacional"]),
    print(item) {
      const el = document.createElement("p");
      el.innerHTML = item.html;
      this.d.print(el);
    },
    nueva() {
      this.createNacional();
    },
    email(item) {
      this.$store.commit("setErrors");
      this.seleccionado = Object.assign({}, item);
      this.dialog = true;
    },
    cancelar() {
      this.seleccionado = {};
      this.dialog = false;
    },
    enviar() {
      let url = `Gerencia/sendFactura/${this.seleccionado.id}`,
        data = { emails: this.seleccionado.email };
      this.$store.commit("setErrors");
      window.axios
        .post(url, data)
        .then(resp => {
          this.$store.dispatch("message", resp.data.message);
          this.cancelar();
        })
        .catch(e => this.$store.dispatch("errores", e));
    }
  },
  mounted() {
    this.getAll("na");
    this.d = new Printd();
  },
  watch: {
    actual() {
      this.getAll("na");
    },
    cliente() {
      this.getAll("na");
    }
  }
};
</script>
