var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","clearable":"","label":"Seleccione al Cliente","items":_vm.clientes},model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","show-select":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("folio")(item.id))+" ")]}},{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe))+" ")]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fecha))+" ")]}},{key:"item.kilos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("decimal")(item.kilos))+" ")]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.selected.length)?[_c('v-row',{staticClass:"mt-3"},[_c('v-col',{staticClass:"text-h6"},[_vm._v(" Importe de la factura "+_vm._s(_vm._f("moneda")(_vm.importe))+" ")])],1)]:_vm._e(),(_vm.selected.length)?_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","clearable":"","label":"Uso CFDI","items":_vm.UsosCFDI,"error-messages":_vm.errors.UsoCFDI},model:{value:(_vm.UsoCFDI),callback:function ($$v) {_vm.UsoCFDI=$$v},expression:"UsoCFDI"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","clearable":"","label":"Forma de Pago","items":_vm.formasPago,"error-messages":_vm.errors.FormaPago},model:{value:(_vm.formaPago),callback:function ($$v) {_vm.formaPago=$$v},expression:"formaPago"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","clearable":"","label":"Método de Pago","items":_vm.MetodosPago,"error-messages":_vm.errors.MetodoPago},model:{value:(_vm.MetodoPago),callback:function ($$v) {_vm.MetodoPago=$$v},expression:"MetodoPago"}})],1)],1):_vm._e()],2),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.selected.length},on:{"click":_vm.facturar}},[_vm._v(" facturar ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.$router.push({ name: 'nacionales' })}}},[_vm._v(" cancelar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }