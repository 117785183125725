<!-- eslint-disable -->
<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          dense
          outlined
          clearable
          label="Buscar"
          v-model="search"
          hide-details="auto"
          append-icon="mdi-magnify"
        />
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      class="elevation-1 mt-3"
      hide-default-footer
      mobile-breakpoint="0"
      :items-per-page="items.length"
      :loading="loader"
      :search.sync="search"
    >
      <template v-slot:[`item.organico`]="{ item }">
        {{ item.organico | moneda }}
      </template>
      <template v-slot:[`item.convencional`]="{ item }">
        {{ item.convencional | moneda }}
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        {{ item.updated_at | date }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon color="success">
          <v-icon @click="editar(item)">mdi-pencil</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog persistent v-model="dialog" max-width="600">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Cambiar Precio {{ resource.tipo_corte }}
        </v-card-title>
        <v-form @submit.prevent="updatePrice">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  outlined
                  clearable
                  label="Orgánico"
                  v-model="resource.organico"
                  :error-messages="errors.organico"
                  hide-details="auto"
                  type="number"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  outlined
                  clearable
                  label="Convencional"
                  v-model="resource.convencional"
                  :error-messages="errors.convencional"
                  hide-details="auto"
                  type="number"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" type="submit">actualizar</v-btn>
            <v-btn color="error" @click="dialog = false">
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "TablaFijos",
  props: {
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    tiposCorte: {
      type: Array,
      default() {
        return [];
      }
    },
    tipo: {
      type: String,
      default() {
        return "ORG";
      }
    }
  },
  computed: {
    ...mapState({
      loader: state => state.loader,
      errors: state => state.errors
    })
  },
  data: () => ({
    headers: [
      { text: "Tipo de Corte", value: "tipo_corte", width: "25%" },

      { text: "Actualizado", value: "updated_at", width: "25%" },
      { text: "Usuario", value: "user.nombre" },
      { text: "Orgánico", value: "organico", align: "end", width: "10%" },
      {
        text: "Convencional",
        value: "convencional",
        align: "end",
        width: "10%"
      },
      { text: "Acciones", value: "actions", align: "end", width: "10%" }
    ],
    dialog: false,
    resource: {},
    search: undefined
  }),
  mounted() {
    this.setErrors();
  },
  methods: {
    ...mapMutations(["setLoader", "setErrors"]),
    ...mapActions(["errores", "message"]),
    editar(item) {
      this.resource = Object.assign({}, item);
      this.dialog = true;
    },
    updatePrice() {
      this.setErrors();
      this.setLoader();
      window.axios
        .post(
          `Gerencia/prices/updatePrecioFijo/${this.resource.id}`,
          this.resource
        )
        .then(resp => {
          this.message(resp.data.message);
          this.dialog = false;
          this.$emit("actualizar");
        })
        .catch(e => this.errores(e))
        .finally(() => this.setLoader(false));
    }
  }
};
</script>
