<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-row>
        <v-col>
          <v-autocomplete
            dense
            outlined
            clearable
            label="Seleccione al Cliente"
            :items="clientes"
            v-model="customer"
          />
        </v-col>
      </v-row>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        hide-default-footer
        class="elevation-1"
        v-model="selected"
        show-select
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | folio }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
        <template v-slot:[`item.kilos`]="{ item }">
          {{ item.kilos | decimal }}
        </template>
      </v-data-table>
      <template v-if="selected.length">
        <v-row class="mt-3">
          <v-col class="text-h6">
            Importe de la factura {{ importe | moneda }}
          </v-col>
        </v-row>
      </template>
      <v-row class="mt-3" v-if="selected.length">
        <v-col cols="12" md="6" xl="4">
          <v-autocomplete
            dense
            outlined
            clearable
            label="Uso CFDI"
            :items="UsosCFDI"
            v-model="UsoCFDI"
            :error-messages="errors.UsoCFDI"
          />
        </v-col>
        <v-col cols="12" md="6" xl="4">
          <v-autocomplete
            dense
            outlined
            clearable
            label="Forma de Pago"
            :items="formasPago"
            v-model="formaPago"
            :error-messages="errors.FormaPago"
          />
        </v-col>
        <v-col cols="12" md="6" xl="4">
          <v-autocomplete
            dense
            outlined
            clearable
            label="Método de Pago"
            :items="MetodosPago"
            v-model="MetodoPago"
            :error-messages="errors.MetodoPago"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" :disabled="!selected.length" @click="facturar">
        facturar
      </v-btn>
      <v-btn color="error" @click="$router.push({ name: 'nacionales' })">
        cancelar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Create",
  data: () => ({
    customer: undefined,
    formaPago: undefined,
    selected: [],
    headers: [
      { text: "Folio", value: "id" },
      { text: "Nota ", value: "nota" },
      { text: "Fecha ", value: "fecha" },
      { text: "Observaciones", value: "observaciones" },
      { text: "Toneladas", value: "toneladas", align: "end" },
      { text: "Importe", value: "importe", align: "end" }
    ],
    UsoCFDI: undefined,
    MetodoPago: undefined
  }),
  computed: {
    ...mapState({
      ventas: state => state.Invoices.ventas,
      formasPago: state => state.Invoices.formasPago,
      UsosCFDI: state => state.Invoices.usoCfdi,
      MetodosPago: state => state.Invoices.MetodosPago,
      errors: state => state.errors
    }),
    clientes() {
      return [...new Set(this.ventas.map(x => x.customer))].sort();
    },
    items() {
      if (!this.customer) return undefined;
      let seleccionado = this.ventas.find(x => x.customer == this.customer);
      return seleccionado.details;
    },
    importe() {
      return window._.sumBy(this.selected, "importe");
    }
  },
  methods: {
    ...mapActions("Invoices", ["createNacional", "makeNacional"]),
    facturar() {
      let cantidad = this.selected.length >= 2 ? "las ventas" : "la venta";
      let pregunta = `¿ Deseas facturar ${cantidad} `;
      this.selected.forEach(x => {
        pregunta += x.folio + ", ";
      });
      pregunta = pregunta.slice(0, -2);
      pregunta += " ?";
      let importe = this.$options.filters.moneda(this.importe);
      window
        .swal({
          title: pregunta,
          text: `Se hará una factura por ${importe}`,
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true
        })
        .then(confirmado => {
          if (confirmado) {
            let seleccionados = [...new Set(this.selected.map(x => x.id))];
            this.makeNacional({
              seleccionados,
              UsoCFDI: this.UsoCFDI,
              MetodoPago: this.MetodoPago,
              FormaPago: this.formaPago
            });
          }
        });
    }
  },
  mounted() {
    this.$store.commit("setErrors");
    if (!this.ventas.length) this.createNacional();
  },
  watch: {
    customer() {
      this.selected = [];
    }
  }
};
</script>
