<!-- eslint-disable -->
<template>
  <v-expansion-panel-content class="mt-3">
    <v-row>
      <v-col>
        <v-btn
          small
          color="success"
          @click="$emit('agregar')"
          v-if="resource.status != 'PAGADA'"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      dense
      mobile-breakpoint="0"
      hide-default-footer
      :headers="headers"
      :items="items"
      class="elevation-1 mt-3"
      :item-class="rowClasses"
      :loading="loader"
      :items-per-page="items.length"
    >
      <template v-slot:[`item.date`]="{ item }">
        {{ item.date | date }}
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        {{ item.amount | moneda }}
      </template>
      <template v-slot:[`item.tipo_cambio`]="{ item }">
        {{ item.tipo_cambio | monedaSat }}
      </template>
      <template v-slot:[`item.importe`]="{ item }">
        {{ item.importe | moneda }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon color="error" :disabled="loader" @click="eliminar(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-expansion-panel-content>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Tabla",
  data: () => ({
    headers: [
      { text: "Fecha", value: "date" },
      { text: "Cuenta", value: "cuenta" },
      { text: "Referencia", value: "referencia" },
      { text: "Importe del Pago", value: "amount", align: "end" },
      { text: "Moneda", value: "moneda" },
      { text: "Tipo de Cambio", value: "tipo_cambio", align: "end" },
      { text: "Importe en Pesos", value: "importe", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ]
  }),
  computed: {
    ...mapState({
      items: state => state.Egresos.resource.pagos,
      loader: state => state.Egresos.loader,
      resource: state => state.Egresos.resource
    })
  },
  methods: {
    eliminar(item) {
      this.$swal({
        title: `¿Eliminar el pago por ${item.amount} ${item.moneda}?`,
        text: "Esta acción no puede deshacerse",
        icon: "warning",
        showCancelButton: true
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('Egresos/eliminarPago',item.id);
        } 
      });
    }
  }
};
</script>
