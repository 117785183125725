var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items)?_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"orange lighten-4"}},[_vm._v(" Productores ")]),_c('v-expansion-panel-content',[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"outlined":"","dense":"","clearable":"","label":"Filtrar por productor","hide-details":"","items":_vm.productores,"type":"search"},model:{value:(_vm.productor),callback:function ($$v) {_vm.productor=$$v},expression:"productor"}})],1),(_vm.productor)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","clearable":"","label":"Forma de Pago","type":"search","items":_vm.formas},model:{value:(_vm.forma),callback:function ($$v) {_vm.forma=$$v},expression:"forma"}})],1):_vm._e(),_c('v-col',[(_vm.forma)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":_vm.print}},[_c('v-icon',[_vm._v("mdi-printer")])],1):_vm._e(),(_vm.productor)?_c('v-btn',{attrs:{"color":"info"},on:{"click":function($event){_vm.cesion = true}}},[_vm._v(" cesion ")]):_vm._e()],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-3",attrs:{"dense":"","search":_vm.search,"headers":_vm.headers,"items":_vm.computedItems,"hide-default-footer":"","items-per-page":_vm.computedItems.length},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.lote",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("folio")(item.lote))+" ")]}},{key:"item.orden_corte",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("folio")(item.orden_corte))+" ")]}},{key:"item.kilos_procesados",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("decimal")(item.kilos_procesados))+" ")]}},{key:"item.fecha_corte",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fecha_corte))+" ")]}},{key:"item.precio_kilo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.precio_kilo))+" ")]}},{key:"item.importe_productor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe_productor))+" ")]}},{key:"item.total_cuadrilla",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.total_cuadrilla))+" ")]}}],null,true)}),(_vm.cesion)?_c('v-dialog',{attrs:{"persistent":"","max-width":"800"},model:{value:(_vm.cesion),callback:function ($$v) {_vm.cesion=$$v},expression:"cesion"}},[_c('card-cesion',{attrs:{"items":_vm.computedItems},on:{"cerrar":function($event){_vm.cesion = false}}})],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }