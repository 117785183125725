<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-form @submit.prevent="sendResource" :disabled="loader" v-if="resource">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              outlined
              clearable
              label="Descripción"
              v-model="resource.Descripcion"
              :error-messages="errors.Descripcion"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              dense
              outlined
              clearable
              label="N° Identificacion"
              v-model="resource.NoIdentificacion"
              :error-messages="errors.NoIdentificacion"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              dense
              outlined
              clearable
              label="Clave de Producto o Servicio"
              v-model="resource.ClaveProdServ"
              :error-messages="errors.ClaveProdServ"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-autocomplete
              dense
              outlined
              clearable
              label="Unidad de Medida"
              v-model="resource.ClaveUnidad"
              :error-messages="errors.ClaveUnidad"
              :items="ClavesUnidad"
              type="search"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              outlined
              clearable
              label="Fracción Arancelaria"
              v-model="resource.FraccionArancelaria"
              :error-messages="errors.FraccionArancelaria"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
              dense
              outlined
              clearable
              label="Unidad de Medida Aduana"
              v-model="resource.UnidadAduana"
              :error-messages="errors.UnidadAduana"
              :items="UnidadesAduana"
              type="search"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-autocomplete
              dense
              outlined
              clearable
              label="Impuesto"
              v-model="resource.Impuesto"
              :error-messages="errors.Impuesto"
              :items="Impuestos"
              type="search"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-autocomplete
              dense
              outlined
              clearable
              label="Factor"
              v-model="resource.TipoFactor"
              :error-messages="errors.TipoFactor"
              :items="TiposFactor"
              type="search"
            />
          </v-col>
          <v-col>
            <v-text-field
              dense
              outlined
              clearable
              label="Tasa o Cuota"
              v-model.number="resource.TasaOCuota"
              :error-messages="errors.TasaOCuota"
              type="number"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" type="submit" :disabled="loader">
          guardar
        </v-btn>
        <v-btn
          color="error"
          @click="$router.push({ name: 'supplies' })"
          :disabled="loader"
        >
          cancelar
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Formulario",
  methods: {
    ...mapActions("Supplies", ["sendResource", "getResource", "nuevo"])
  },
  computed: {
    ...mapState({
      loader: state => state.Supplies.loader,
      resource: state => state.Supplies.resource,
      ClavesUnidad: state => state.Supplies.ClavesUnidad,
      errors: state => state.errors,
      UnidadesAduana: state => state.Supplies.UnidadesAduana,
      Impuestos: state => state.Supplies.Impuestos,
      TiposFactor: state => state.Supplies.TiposFactor
    })
  },
  beforeMount() {
      this.$store.commit('setErrors')
    if (!this.resource) {
      if (this.$route.params.id) {
        this.getResource(this.$route.params.id);
      } else {
        this.nuevo();
      }
    }
  }
};
</script>
