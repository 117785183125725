var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","clearable":"","label":"Filtrar por Cliente","type":"search","items":_vm.paginado.customers},model:{value:(_vm.paginado.customer),callback:function ($$v) {_vm.$set(_vm.paginado, "customer", $$v)},expression:"paginado.customer"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","label":"Filtrar por Estatus","type":"search","items":_vm.paginado.statusses},model:{value:(_vm.paginado.status),callback:function ($$v) {_vm.$set(_vm.paginado, "status", $$v)},expression:"paginado.status"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{attrs:{"color":"success","disabled":_vm.loader},on:{"click":_vm.nueva}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-btn',{staticClass:" ml-3",attrs:{"color":"info","disabled":_vm.loader},on:{"click":_vm.getAll}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"search":_vm.search,"hide-default-footer":""},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("folio")(item.id))+" ")]}},{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe))+" ")]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fecha))+" ")]}},{key:"item.kilos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("decimal")(item.kilos))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.factura)?_c('v-btn',{attrs:{"icon":"","color":"success","disabled":_vm.loader},on:{"click":function($event){return _vm.editVenta(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(!item.factura)?_c('v-btn',{attrs:{"icon":"","color":"red","disabled":_vm.loader},on:{"click":function($event){return _vm.eliminar(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_c('v-btn',{attrs:{"icon":"","color":"primary","disabled":_vm.loader},on:{"click":function($event){return _vm.detalleVenta(item.id)}}},[_c('v-icon',[_vm._v("mdi-information")])],1)]}}],null,true)}),_c('Paginado',{attrs:{"loader":_vm.loader,"paginado":_vm.paginado}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }