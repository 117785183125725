<!-- eslint-disable -->
<template>
  <div>
    <v-form @submit.prevent="revisarPwd({ pwd })" :disabled="loader">
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              dense
              outlined
              clearable
              type="password"
              label="Ingrese su contraseña para continuar"
              hide-details="auto"
              v-model="pwd"
              :error-messages="errors.pwd"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" type="submit" :disabled="loader">
          comprobar
        </v-btn>
        <v-btn color="error" @click="$router.go(-1)" :disabled="loader">
          cancelar
        </v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "CheckPassword",
  data: () => ({
    pwd: undefined
  }),
  computed: {
    ...mapState({
      loader: state => state.Auth.loader,
      errors: state => state.errors
    })
  },
  methods: {
    ...mapActions("Auth", ["revisarPwd"])
  },
  mounted() {
    this.$store.commit("setErrors");
  }
};
</script>
