<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text v-if="items">
      <v-form @submit.prevent="actualizar" v-if="productos.length">
        <v-row>
          <v-col>
            <v-autocomplete
              dense
              outlined
              clearable
              label="Producto"
              v-model="producto"
              :items="productos"
            />
          </v-col>
          <v-col>
            <v-text-field
              dense
              outlined
              clearable
              label="Precio Unitario"
              type="number"
              step="0.01"
              v-model.number="ValorUnitario"
            />
          </v-col>
          <v-col>
            <v-btn color="success" :disabled="listo" type="submit">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-data-table
        dense
        mobile-breakpoint="0"
        :items-per-page="items.length"
        :headers="headers"
        :items="items"
        class="elevation-1 mt-2"
        hide-default-footer
        :loading="loader"
      >
        <template v-slot:[`item.Cantidad`]="{ item }">
          {{ item.Cantidad | numero }}
        </template>
        <template v-slot:[`item.ValorUnitario`]="{ item }">
          {{ item.ValorUnitario | moneda }}
          <v-btn
            icon
            color="error"
            v-if="item.ValorUnitario"
            @click="limpiar(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.Importe`]="{ item }">
          {{ item.Importe | moneda }}
        </template>
      </v-data-table>
      <v-data-table
        dense
        mobile-breakpoint="0"
        :items-per-page="articulos.length"
        :headers="headers2"
        :items="articulos"
        class="elevation-1 mt-5"
        hide-default-footer
        :loading="loader"
      >
        <template v-slot:[`item.Cantidad`]="{ item }">
          {{ item.Cantidad | numero }}
        </template>
        <template v-slot:[`item.ValorUnitario`]="{ item }">
          {{ item.ValorUnitario | moneda }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.iva`]="{ item }">
          {{ item.iva | moneda }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="error" @click="deleteArticulo(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-row class="mt-2">
        <v-col cols="12" md="auto">
          SubTotal: {{ (totalAguacates + totalArticulos) | moneda }}
        </v-col>
        <v-col cols="12" md="auto"> Iva: {{ iva | moneda }} </v-col>
        <v-col cols="12" md="auto">
          Importe de la Factura:
          {{ (totalAguacates + totalArticulos + iva) | moneda }}
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn color="success" :disabled="preparado" @click="mandarDatosFactura"
        >Facturar</v-btn
      >
      <template v-if="supplies">
        <v-btn
          color="primary"
          @click="dialog = true"
          v-if="supplies.length > articulos.length"
        >
          agregar articulos
        </v-btn>
      </template>
      <v-btn color="error" @click="$router.go(-1)">cancelar</v-btn>
    </v-card-actions>
    <ArticuloForm v-if="dialog" :dialog="dialog" @cancelar="dialog = false" />
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ArticuloForm from "./ArticuloForm.vue";
export default {
  name: "Facturar",
  components: { ArticuloForm },
  data: () => ({
    producto: undefined,
    ValorUnitario: undefined,
    headers: [
      { text: "Producto", value: "Descripcion" },
      { text: "Cantidad", value: "Cantidad", align: "end", width: "15" },
      {
        text: "Precio Unitario",
        value: "ValorUnitario",
        align: "end",
        width: "15"
      },
      { text: "Importe", value: "Importe", align: "end", width: "15" }
    ],
    original: undefined,
    dialog: false,
    headers2: [
      { text: "Artículo", value: "articulo.Descripcion" },
      { text: "Cantidad", value: "Cantidad", align: "end", width: "15" },
      {
        text: "Precio Unitario",
        value: "ValorUnitario",
        align: "end",
        width: "15"
      },
      { text: "Importe", value: "importe", align: "end", width: "15" },
      { text: "IVA", value: "iva", align: "end", width: "15" },
      { text: "Acciones", value: "actions", align: "end", width: "15" }
    ]
  }),
  computed: {
    ...mapState({
      items: state => state.Embarques.conceptos,
      loader: state => state.Embarques.loader,
      articulos: state => state.Embarques.articulos,
      supplies: state => state.Embarques.supplies
    }),
    productos() {
      if (this.items) {
        let filtrados = this.items.filter(x => !x.ValorUnitario);
        return [...new Set(filtrados.map(x => x.Descripcion))];
      } else {
        return [];
      }
    },
    listo() {
      return this.producto && this.ValorUnitario ? false : true;
    },
    preparado() {
      return this.productos.length ? true : false;
    },
    preparados() {
      return this.items.filter(x => x.ValorUnitario);
    },
    totalAguacates() {
      return window._.sumBy(this.preparados, "Importe");
    },
    totalArticulos() {
      return window._.sumBy(this.articulos, "importe");
    },
    iva() {
      return window._.sumBy(this.articulos, "iva");
    }
  },
  methods: {
    ...mapActions("Embarques", [
      "facturarEmbarque",
      "mandarDatosFactura",
      "eliminarArticulo"
    ]),
    ...mapMutations("Embarques", ["setConceptos"]),
    actualizar() {
      let seleccionado = this.items.filter(x => x.Descripcion == this.producto);
      seleccionado.forEach(x=>{
        x.ValorUnitario = this.ValorUnitario;
      x.Importe = x.Cantidad * this.ValorUnitario;
      })
      this.ValorUnitario = this.producto = undefined;
    },
    limpiar(item) {
      item.ValorUnitario = item.Importe = null;
    },
    deleteArticulo(item) {
      this.$swal({
        title: `¿Eliminar de la Factura ${item.articulo.Descripcion}?`,
        text: "Esta acción no puede deshacerse",
        icon: "warning",
        showCancelButton: true
      }).then(result => {
        if (result.isConfirmed) {
          this.eliminarArticulo(item.id);
        }
      });
    }
  },
  beforeMount() {
    if (!this.items) {
      this.facturarEmbarque(this.$route.params.id);
    }
  },
  mounted() {
    this.original = this.items;
  },
  watch: {
    producto(val) {
      this.ValorUnitario = null;
      if (val) {
        let seleccionado = this.items.find(x => x.Descripcion == val);
        this.ValorUnitario = seleccionado.ValorUnitario;
      }
    },
    items(val) {
      this.original = val;
    }
  }
};
</script>
