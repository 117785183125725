<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text v-if="venta">
      <div class="mt-3">
        <v-row>
          <v-col cols="12" md="4">
            Folio: {{ venta.id | folio }} <br />
            Nota: {{ venta.nota }} <br />
            Fecha de Elaboración: {{ venta.fecha | date }} <br />
            Cliente: {{ venta.cliente }} <br />
          </v-col>
          <v-col cols="12" md="4">
            Total de Kilos: {{ venta.kilos | decimal }} <br />
            Importe: {{ venta.importe | moneda }}
          </v-col>
          <v-col cols="12" md="4" v-if="venta.observaciones">
            <span class="negrita">Observaciones</span>: <br />
            {{ venta.observaciones }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <h4>Producto por Pallet</h4>
            <v-data-table
              dense
              :headers="headers"
              :items="items"
              class="elevation-1 mt-3"
              :items-per-page="items.length"
              hide-default-footer
            >
              <template v-slot:[`item.importe`]="{ item }">
                {{ item.importe | moneda }}
              </template>
              <template v-slot:[`item.precio`]="{ item }">
                {{ item.precio | moneda }}
              </template>
              <template v-slot:[`item.kilos`]="{ item }">
                {{ item.kilos | decimal }}
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" md="6">
            <h4>Concentrado</h4>
            <v-data-table
              dense
              :headers="headers2"
              :items="items2"
              class="elevation-1 mt-3"
              :items-per-page="items2.length"
            hide-default-footer
            >
              <template v-slot:[`item.importe`]="{ item }">
                {{ item.importe | moneda }}
              </template>
              <template v-slot:[`item.precio`]="{ item }">
                {{ item.precio | moneda }}
              </template>
              <template v-slot:[`item.kilos`]="{ item }">
                {{ item.kilos | decimal }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="regresar">Regresar a ventas</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  name: "Show",
  data: () => ({
    headers: [
      { text: "Producto", value: "producto" },
      { text: "Pallet", value: "pallet" },
      { text: "Kilos", value: "kilos", align: "end" },
      { text: "Precio U", value: "precio", align: "end" },
      { text: "Importe", value: "importe", align: "end" }
    ],
    headers2: [
      { text: "Producto", value: "producto" },
      { text: "Kilos", value: "kilos", align: "end" },
      { text: "Precio U", value: "precio", align: "end" },
      { text: "Importe", value: "importe", align: "end" }
    ]
  }),
  computed: {
    ...mapState({
      venta: state => state.Ventas.resource,
      items: state => state.Ventas.details
    }),
    items2() {
      let productos = [...new Set(this.items.map(x => x.producto))].sort(),
        data = [];
      productos.forEach(producto => {
        let items = this.items.filter(x => x.producto == producto),
          item = {
            producto,
            kilos: window._.sumBy(items, "kilos"),
            total: 0,
            precio: items[0].precio,
            importe: window._.sumBy(items, "importe")
          };
        data.push(item);
      });
      return data;
    }
  },
  mounted() {
    if (!this.venta) {
      const id = this.$route.params.id;
      this.detalleVenta(id);
    }
  },
  methods: {
    ...mapActions("Ventas", ["detalleVenta"]),
    regresar() {
      this.$router.push({ name: "ventas" });
    }
  }
};
</script>
