import router from "../../router";
const url = "Gerencia/ventas";
const initialState = () => ({
  headers: [
    { text: "Folio", value: "id" },
    { text: "Nota ", value: "nota" },
    { text: "Fecha ", value: "fecha" },
    { text: "Cliente", value: "cliente" },
    { text: "Observaciones", value: "observaciones" },
    { text: "Toneladas", value: "toneladas", align: "end" },
    { text: "Importe", value: "importe", align: "end" },
    { text: "Factura", value: "factura" },
    { text: "Acciones", value: "actions", align: "end" }
  ],
  collection: undefined,
  resource: undefined,
  loader: false,
  clientes: undefined,
  seleccionados: [],
  details: undefined,
  paginado: { currentPage: 1 },
  nuevo: undefined
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    setCollection(state, payload) {
      state.collection = payload;
    },
    setResource(state, payload) {
      state.resource = payload;
    },
    setLoader(state, payload) {
      state.loader = payload;
    },
    setSeleccionados(state, payload) {
      state.seleccionados = payload;
    },
    setClientes(state, payload) {
      state.clientes = payload;
    },
    setDetails(state, payload) {
      state.details = payload;
    },
    setPaginado(state, payload) {
      state.paginado = payload;
    },
    setNuevo(state, payload) {
      state.nuevo = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch, state }) {
      commit("setCollection", []);
      commit("setClientes", []);
      commit("setLoader", true);
      window.axios
        .get(url, {
          params: {
            page: state.paginado.currentPage,
            customer: state.paginado.customer,
            status: state.paginado.status
          }
        })
        .then(resp => {
          commit("setCollection", resp.data.collection);
          commit("setClientes", resp.data.clientes);
          commit("setPaginado", resp.data.paginado);
          commit("setNuevo", resp.data.nueva);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async nuevaVenta({ commit, dispatch, state }, data) {
      commit("setErrors", [], { root: true });
      commit("setLoader", true);
      let listos = state.seleccionados.filter(x => x.precio_kilo > 0);
      if (!listos.length) {
        window.swal("Hay productos seleccionados que no tienen precio", {
          icon: "error",
          timer: 1500
        });
        commit("setLoader", false);
        return;
      }
      data.productos = state.seleccionados;
      await window.axios
        .post(url, data)
        .then(resp => {
          dispatch("message", "Venta creada", { root: true });
          router.push({ name: "ventas" }).catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async detalleVenta({ commit, dispatch }, id) {
      commit("setErrors", [], { root: true });
      commit("setLoader", true);
      await window.axios
        .get(`${url}/${id}`)
        .then(resp => {
          commit("setResource", resp.data.resource);
          commit("setClientes", resp.data.clientes);
          commit("setDetails", resp.data.details);
          router.push({ name: "venta.show", params: { id } }).catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    agregarItem({ state }, item) {
      state.seleccionados.push(item);
      let restaurar = state.seleccionados.filter(x => x.precio_kilo);
      restaurar.forEach(x => delete x.precio_kilo);
    },
    removeItem({ state }, item) {
      let index = state.seleccionados.findIndex(x => x.id == item.id);
      state.seleccionados.splice(index, 1);
      let restaurar = state.seleccionados.filter(x => x.precio_kilo);
      restaurar.forEach(x => delete x.precio_kilo);
    },
    async deleteVenta({ commit, dispatch }, id) {
      commit("setErrors", [], { root: true });
      commit("setLoader", true);
      await window.axios
        .delete(`${url}/${id}`)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1500 });
          dispatch("getAll");
        })

        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async editVenta({ commit, dispatch }, id) {
      commit("setLoader");
      await window.axios
        .get(`${url}/editar/${id}`)
        .then(resp => {
          commit("setResource", resp.data.resource);
          commit("setClientes", resp.data.customers);
          commit("setDetails", resp.data.details);
          router.push({ name: "venta.edit", params: { id } }).catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async updateVenta({ commit, dispatch, state }) {
      let resource = state.resource,
        id = resource.id;
      commit("setLoader");
      commit("setErrors", {}, { root: true });
      await window.axios
        .put(`${url}/editar/${id}`, resource)
        .then(resp => {
          dispatch("message", resp.data.message, { root: true });
          commit("setResource", resp.data.resource);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    }
  }
};
