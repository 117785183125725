<!-- eslint-disable -->
<template>
  <v-dialog persistent v-model="dialog" max-width="800">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Agregar Artículos
      </v-card-title>
      <v-form @submit.prevent="send" :disabled="loader">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                dense
                outlined
                clearable
                label="Artículo"
                v-model="resource.articulo_id"
                :error-messages="errors.articulo_id"
                :items="supplies"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                dense
                outlined
                clearable
                label="Cantidad"
                v-model.number="resource.Cantidad"
                :error-messages="errors.Cantidad"
                type="number"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                dense
                outlined
                clearable
                label="Precio Unitario"
                v-model.number="resource.ValorUnitario"
                :error-messages="errors.ValorUnitario"
                type="number"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn color="success" :disabled="loader" type="submit">
            agregar
          </v-btn>
          <v-btn color="error" @click="$emit('cancelar')" :disabled="loader">
            cancelar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "ArticuloForm",
  props: {
    dialog: {
      type: Boolean
    }
  },
  emits: ["cancelar"],
  data: () => ({
    resource: {
      articulo_id: undefined,
      ValorUnitario: undefined,
      Cantidad: undefined
    }
  }),
  computed: {
    ...mapState({
      errors: state => state.errors,
      loader: state => state.loader,
      supplies: state => state.Embarques.supplies
    })
  },
  methods: {
    ...mapMutations(["setErrors", "setLoader"]),
    ...mapMutations("Embarques", ["setArticulos"]),
    ...mapActions(["errores", "message"]),
    send() {
      this.setErrors();
      this.setLoader();
      let url = `Gerencia/embarques/addArticulo/${this.$route.params.id}`;
      window.axios
        .post(url, this.resource)
        .then(resp => {
          this.message(resp.data.message);
          this.setArticulos(resp.data.articulos);
          this.$emit('cancelar');
        })
        .catch(e => this.errores(e))
        .finally(() => this.setLoader(false));
    }
  },
  beforeMount() {
    this.setErrors();
  }
};
</script>
