<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <!-- switches -->
      <v-row>
        <v-col xl="2">
          <v-switch v-model="lotes" label="Lotes por mes" />
        </v-col>
        <v-col xl="2">
          <v-switch v-model="organico" label="Aguacate Orgánico" />
        </v-col>
        <v-col xl="2">
          <v-switch v-model="convencional" label="Aguacate Convencional" />
        </v-col>
        <v-col xl="2">
          <v-switch v-model="nacional" label="Aguacate Nacional" />
        </v-col>
        <v-col xl="2">
          <v-switch v-model="rendimiento" label="Rendimiento" />
        </v-col>
        <v-col xl="2">
          <v-switch v-model="calibres" label="Calibres" />
        </v-col>
      </v-row>
      <!-- graficos -->
      <v-row>
        <v-col cols="12" class="mb-3" v-if="lotes">
          <Lotes />
        </v-col>
        <v-col cols="12" class="mb-3" v-if="organico">
          <Organic />
        </v-col>
        <v-col cols="12" class="mb-3" v-if="convencional">
          <Conventional />
        </v-col>
        <v-col cols="12" class="mb-3" v-if="nacional">
          <national />
        </v-col>
        <v-col cols="12" class="mb-3" v-if="rendimiento">
          <rendimiento />
        </v-col>
        <v-col cols="12" class="mb-3" v-if="calibres">
          <calibres />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Organic from "./Organic.vue";
import National from "./National.vue";
import Conventional from "./Conventional.vue";
import Lotes from "./Lotes.vue";
import Rendimiento from "./Rendimiento.vue";
import Calibres from './Calibres.vue'
export default {
  name: "Graficos",
  components: { Organic, Conventional, National, Lotes, Rendimiento,Calibres },
  data: () => ({
    organico: false,
    convencional: false,
    nacional: false,
    lotes: false,
    rendimiento: false,
    calibres: undefined
  }),
};
</script>
