<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Datos de la Venta
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-autocomplete
              dense
              outlined
              small-chips
              clearable
              label="Cliente"
              :items="clientes"
              v-model="resource.cliente_id"
              :error-messages="errors.cliente_id"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              outlined
              dense
              type="date"
              label="Fecha"
              v-model="resource.fecha"
              :error-messages="errors.fecha"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              outlined
              dense
              type="text"
              label="Nota"
              v-model="resource.nota"
              :error-messages="errors.nota"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              outlined
              dense
              type="text"
              label="Chofer"
              v-model="resource.chofer"
              :error-messages="errors.chofer"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              outlined
              dense
              type="text"
              label="Marca"
              v-model="resource.marca"
              :error-messages="errors.marca"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              outlined
              dense
              type="text"
              label="Color"
              v-model="resource.color"
              :error-messages="errors.color"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              outlined
              dense
              type="text"
              label="Placas"
              v-model="resource.placas"
              :error-messages="errors.placas"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              outlined
              label="Observaciones"
              v-model="resource.observaciones"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="modificado">
        <v-btn color="primary" @click="updateVenta" :disabled="loader">
          actualizar
        </v-btn>
        <v-btn color="error" @click="cancelar" :disabled="loader">
          cancelar
        </v-btn>
      </v-card-actions>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "PanelVenta",
  data: () => ({
    original: undefined
  }),
  computed: {
    ...mapState({
      errors: state => state.errors,
      resource: state => state.Ventas.resource,
      clientes: state => state.Ventas.clientes,
      loader: state => state.Ventas.loader
    }),
    modificado() {
      return JSON.stringify(this.original) !== JSON.stringify(this.resource);
    }
  },
  methods: {
    ...mapMutations("Ventas", ["setResource"]),
    ...mapActions("Ventas", ["updateVenta"]),
    cancelar() {
      this.setResource(this.original);
      this.$store.commit("setErrors");
    }
  },
  mounted() {
    this.$store.commit("setErrors");
    this.original = Object.assign({}, this.resource);
  },
  watch: {
    resource(val) {
      this.original = Object.assign({}, val);
    }
  }
};
</script>
