var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.getAll($event)}}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":"","type":"date","label":"Fecha Inicial"},model:{value:(_vm.fechas.inicio),callback:function ($$v) {_vm.$set(_vm.fechas, "inicio", $$v)},expression:"fechas.inicio"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":"","type":"date","label":"Fecha Final"},model:{value:(_vm.fechas.fin),callback:function ($$v) {_vm.$set(_vm.fechas, "fin", $$v)},expression:"fechas.fin"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Lote"},model:{value:(_vm.paginado.lote),callback:function ($$v) {_vm.$set(_vm.paginado, "lote", $$v)},expression:"paginado.lote"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Huerta"},model:{value:(_vm.paginado.huerta),callback:function ($$v) {_vm.$set(_vm.paginado, "huerta", $$v)},expression:"paginado.huerta"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":_vm.loader}},[_vm._v(" buscar ")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","mobile-breakpoint":"0","hide-default-footer":"","headers":_vm.headers,"search":_vm.search,"items":_vm.items,"items-per-page":_vm.perPage,"loading":_vm.loader},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.lote",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("folio")(item.lote))+" ")]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fecha))+" ")]}},{key:"item.fecha_corte",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fecha_corte))+" ")]}},{key:"item.kilos_procesados",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("decimal")(item.kilos_procesados))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.getLote(item.id)}}},[_c('v-icon',[_vm._v("mdi-information")])],1)]}}],null,true)}),_c('Paginado',{attrs:{"loader":_vm.loader,"paginado":_vm.paginado}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }