var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"orange lighten-4"}},[_vm._v(" Egresos ")]),_c('v-expansion-panel-content',[_c('v-data-table',{staticClass:"elevation-1 mt-3",attrs:{"dense":"","mobile-breakpoint":"0","hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"item-class":_vm.rowClasses,"loading":_vm.loader,"items-per-page":_vm.items.length},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.date))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.amount))+" ")]}},{key:"item.tipo_cambio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("monedaSat")(item.tipo_cambio))+" ")]}},{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"error","disabled":_vm.loader},on:{"click":function($event){return _vm.eliminar(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)}),_c('v-row',{staticClass:"mt-3"},[_c('v-col',[_vm._v(" Importe del Periodo: "+_vm._s(_vm._f("moneda")(_vm.importe))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }