import Pallets from './../views/pallets/index.vue';
const routes = [
  {
    path: "/Pallets",
    name: "pallets",
    component: Pallets,
    meta: {
      name: "Búsqueda de Pallets"
    }
  }
];
export default routes;
