var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.getAll($event)}}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":"","type":"date","label":"Fecha Inicial"},model:{value:(_vm.paginado.inicio),callback:function ($$v) {_vm.$set(_vm.paginado, "inicio", $$v)},expression:"paginado.inicio"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":"","type":"date","label":"Fecha Final"},model:{value:(_vm.paginado.fin),callback:function ($$v) {_vm.$set(_vm.paginado, "fin", $$v)},expression:"paginado.fin"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"info","type":"submit","disabled":_vm.loader}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loader,"items-per-page":_vm.paginado.perPage},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("folio")(item.id))+" ")]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fecha))+" ")]}},{key:"item.cajas",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numero")(item.cajas))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.facturar)?_c('v-btn',{attrs:{"icon":"","color":"primary","disabled":_vm.loader},on:{"click":function($event){return _vm.facturarEmbarque(item.id)}}},[_c('v-icon',[_vm._v("mdi-cash")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":"","color":"success","disabled":_vm.loader},on:{"click":function($event){return _vm.getEmbarque(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)}),_c('Paginado',{attrs:{"loader":_vm.loader,"paginado":_vm.paginado}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }