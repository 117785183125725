<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="orange lighten-4">
      Egresos
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-data-table
        dense
        mobile-breakpoint="0"
        hide-default-footer
        :headers="headers"
        :items="items"
        class="elevation-1 mt-3"
        :item-class="rowClasses"
        :loading="loader"
        :items-per-page="items.length"
      >
        <template v-slot:[`item.date`]="{ item }">
          {{ item.date | date }}
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          {{ item.amount | moneda }}
        </template>
        <template v-slot:[`item.tipo_cambio`]="{ item }">
          {{ item.tipo_cambio | monedaSat }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="error" :disabled="loader" @click="eliminar(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-row class="mt-3">
        <v-col> Importe del Periodo: {{ importe | moneda }} </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Egresos",
  data: () => ({
    headers: [
      { text: "Fecha", value: "date" },
      { text: "Factura", value: "factura" },
      { text: "Proveedor", value: "provider" },
      { text: "Cuenta", value: "cuenta" },
      { text: "Referencia", value: "referencia" },
      { text: "Importe del Pago", value: "amount", align: "end" },
      { text: "Moneda", value: "moneda" },
      { text: "Tipo de Cambio", value: "tipo_cambio", align: "end" },
      { text: "Importe en Pesos", value: "importe", align: "end" }
    ],
    loader: false,
    search: undefined
  }),
  computed: {
    ...mapState({
      egresos: state => state.Pagos.egresos
    }),
    items() {
      return this.egresos ? this.egresos : [];
    },
    importe() {
      return this.egresos ? window._.sumBy(this.egresos, "importe") : 0;
    }
  }
};
</script>
