<!-- eslint-disable -->
<template>
  <v-card height="100%" v-if="embarque">
    <v-card-text>
      <v-expansion-panels multiple>
        <Datos />
        <Pallets />
        <Archivos />
        <GastosExportacion />
      </v-expansion-panels>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="regresar">Regresar a embarques</v-btn>
      <v-btn color="success" @click="imprimir">imprimir cierre</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Datos from "./PanelDatos";
import Pallets from "./panelPallets";
import Archivos from "./panelArchivos";
import GastosExportacion from "./PanelGastosExportacion.vue";
export default {
  name: "Detalles",
  components: { Archivos, Datos, Pallets, GastosExportacion },
  computed: {
    ...mapState({
      embarque: state => state.Embarques.resource
    })
  },
  mounted() {
    if (!this.embarque) {
      const id = this.$route.params.id;
      this.getEmbarque(id);
    }
  },
  methods: {
    ...mapActions("Embarques", ["getEmbarque"]),
    regresar() {
      this.$router.push({ name: "embarques" });
    },
    imprimir() {
      window.open(this.embarque.cierre);
    }
  }
};
</script>
