<!-- eslint-disable -->
<template>
  <v-card>
    <v-card-title primary-title>
      Cesión de Derechos
    </v-card-title>
    <v-card-text>
      <v-data-table
        dense
        :headers="headers"
        :items="itemsNew"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:[`item.lote`]="{ item }">
          {{ item.lote | folio }}
        </template>
        <template v-slot:[`item.importe_productor`]="{ item }">
          {{ item.importe_productor | moneda }}
        </template>
        <template v-slot:[`item.fecha_corte`]="{ item }">
          {{ item.fecha_corte | date }}
        </template>
        <template v-slot:[`item.importe_ceder`]="{ item }">
          <v-text-field
            dense
            hide-details="auto"
            type="number"
            v-model.number="item.importe_ceder"
          />
        </template>
      </v-data-table>
      <template v-if="selected.length">
        <v-row class="my-2">
          <v-col cols="12">
            Importe a Ceder: <strong>{{ total | moneda }}</strong>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="auto">
            <v-text-field
              dense
              outlined
              clearable
              label="Fecha"
              type="date"
              hide-details="auto"
              v-model="date"
            />
          </v-col>
          <v-col>
            <v-text-field
              dense
              outlined
              clearable
              label="Beneficiario"
              hide-details="auto"
              v-model="beneficiario"
            />
          </v-col>
        </v-row>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="primary"
        v-if="selected.length && beneficiario && date"
        @click="imprimir"
      >
        imprimir
      </v-btn>
      <v-spacer />
      <v-btn color="error" @click="$emit('cerrar')">
        cerrar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { Printd } from "printd";
export default {
  name: "Cesion",
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  emits: ["cerrar"],
  data: () => ({
    itemsNew: [],
    headers: [
      { text: "Lote", value: "lote" },
      { text: "Fecha", value: "fecha_corte" },
      { text: "Huerta", value: "huerta" },
      { text: "Importe Total", value: "importe_productor", align: "end" },
      { text: "Importe a Ceder", value: "importe_ceder", align: "end" }
    ],
    d: new Printd(),
    date: undefined,
    beneficiario: undefined
  }),
  computed: {
    selected() {
      return this.itemsNew.filter(
        x =>
          x.importe_ceder &&
          x.importe_ceder > 0 &&
          x.importe_ceder <= x.importe_productor
      );
    },
    total() {
      return window._.sumBy(this.selected, "importe_ceder");
    }
  },
  beforeMount() {
    this.items.forEach(element => {
      let item = Object.assign({}, element);
      this.itemsNew.push(item);
    });
  },
  methods: {
    imprimir() {
      let payload = {
        date: this.date,
        beneficiario: this.beneficiario,
        items: this.selected
      };
      let url = "pagos/printCesion";
      window.axios.post(url, payload).then(resp => {
        const el = document.createElement("p");
        el.innerHTML = resp.data;
        this.d.print(el);
      });
    }
  }
};
</script>