const routes = [
  {
    path: "/Empleados",
    name: "employes",
    component: require("@/views/employes/Index.vue").default,
    meta: {
      name: "Catálogo de Empleados"
    }
  },
  {
    path: "/Empleados/detalles/:id",
    name: "employes.show",
    component: require("@/views/employes/Show.vue").default,
    meta: {
      name: "Detalles de Empleado"
    }
  }
];
export default routes;
