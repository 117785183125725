<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-form :disabled="loader" @submit.prevent="enviar">
      <v-card-text>
        <v-row>
          <v-col>
            <v-file-input
              v-model="file"
              outlined
              dense
              label="Seleccione Archivo XML"
              small-chips
              clearable
              counter
              show-size
              truncate-length="50"
              accept=".xml"
              :error-messages="errors.file"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" type="submit" :disabled="loader">
          subir
        </v-btn>
        <v-btn
          color="error"
          :disabled="loader"
          @click="$router.push({ name: 'egresos' })"
        >
          cancelar
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Upload",
  data: () => ({
    file: undefined
  }),
  computed: {
    ...mapState({
      loader: state => state.Egresos.loader,
      errors: state => state.errors
    })
  },
  methods: {
    ...mapMutations("Egresos", ["setLoader"]),
    ...mapMutations(["setErrors"]),
    ...mapActions(["errores", "message"]),
    enviar() {
      if (this.file) {
        let formData = new FormData();
        formData.append("file", this.file);
        this.setLoader();
        this.setErrors();
        window.axios
          .post("Gerencia/egresos/upload", formData)
          .then(resp => {
            this.message(resp.data.message);
            this.$swal({
              title: "¿Deseas meter mas archivos?",
              text: resp.data.message,
              icon: "warning",
              showCancelButton: true
            }).then(resp => {
              if (resp.isConfirmed) {
                this.file = undefined;
              } else {
                this.$router.push({ name: "egresos" });
              }
            });
          })
          .catch(e => this.errores(e))
          .finally(() => this.setLoader(false));
      }
    }
  }
};
</script>
