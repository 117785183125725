<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Busqueda de pallets
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-form @submit.prevent="buscar">
        <v-row class="mt-3">
          <v-col>
            <v-autocomplete
              dense
              outlined
              clearable
              label="Filtrar por Producto"
              :items="productos"
              v-model="producto"
              type="search"
            />
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              type="text"
              label="Pallet"
              v-model="pallet"
            />
          </v-col>
          <v-col>
            <v-btn color="primary" type="submit">buscar</v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-data-table
        dense
        hide-default-footer
        :headers="headers"
        :items="items"
        class="elevation-1"
        mobile-breakpoint="0"
      >
        <template v-slot:[`item.fecha_corte`]="{ item }">
          {{ item.fecha_corte | date }}
        </template>
        <template v-slot:[`item.fecha_proceso`]="{ item }">
          {{ item.fecha_proceso | date }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="success"
            v-if="puedeVenderse(item)"
            @click="agregar(item)"
          >
            <v-icon>mdi-check-box-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Busqueda",
  data: () => ({
    headers: [
      { text: "Producto", value: "producto" },
      { text: "Fecha de Corte", value: "fecha_corte" },
      { text: "Fecha de Proceso", value: "fecha_proceso" },
      { text: "Lote", value: "lote" },
      { text: "Huerta", value: "huerta" },
      { text: "Presentación", value: "presentacion" },
      { text: "Cantidad", value: "cantidad", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    pallet: undefined,
    items: [],
    producto: undefined
  }),
  computed: {
    ...mapState({
      seleccionados: state => state.Ventas.seleccionados,
      disponibles: state => state.Ventas.nuevo.nacional
    }),
    productos() {
      return [...new Set(this.disponibles.map(x => x.producto))].sort();
    }
  },
  methods: {
    ...mapActions("Ventas", ["agregarItem"]),
    buscar() {
      this.items = [];
      if (this.pallet) {
        window.axios
          .post("/Gerencia/pallets", { pallet: this.pallet })
          .then(resp => {
            this.items = resp.data;
          });
      }
    },
    puedeVenderse(item) {
      let agregado = this.seleccionados.find(x => x.id == item.id);
      if (agregado) return false;
      return item.inventario && item.presentacion == "KGS";
    },
    agregar(item) {
      this.agregarItem(item);
    }
  },
  watch: {
    producto(val) {
      this.items = this.disponibles.filter(x => x.producto == val);
    }
  }
};
</script>
