<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-form @submit.prevent="getAll" :disabled="loader">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              outlined
              clearable
              append-icon="mdi-magnify"
              label="Buscar"
              v-model="paginado.search"
            />
          </v-col>
          <v-col>
            <v-btn color="info" type="submit" :disabled="loader">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              color="success"
              class="ml-2"
              :disabled="loader"
              @click="nuevo"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-data-table
        dense
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        :items-per-page="paginado.perPage"
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="success" @click="getResource(item.id)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <paginado :paginado="paginado" :loader="loader" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Index",
  computed: {
    ...mapState({
      loader: state => state.Supplies.loader,
      headers: state => state.Supplies.headers,
      items: state => state.Supplies.collection,
      paginado: state => state.Supplies.paginado
    })
  },
  methods: {
    ...mapActions("Supplies", ["getAll", "getResource", "nuevo"])
  },
  beforeMount() {
    this.getAll();
  }
};
</script>
