<!-- eslint-disable -->
<template>
  <v-expansion-panel v-if="items">
    <v-expansion-panel-header color="blue lighten-4">
      Cuadrillas
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="mt-2">
        <v-col cols="12" xl="4">
          <v-text-field
            outlined
            dense
            v-model="search"
            append-icon="mdi-magnify"
            label="Busqueda"
            single-line
            hide-details
          />
        </v-col>
        <v-col>
          <v-btn color="primary" @click="print">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        dense
        class="elevation-1 mt-3"
        :search.sync="search"
        :headers="headers"
        :items="items"
        hide-default-footer
        :items-per-page="items.length"
      >
        <template v-slot:[`item.lote`]="{ item }">
          {{ item.lote | folio }}
        </template>
        <template v-slot:[`item.orden_corte`]="{ item }">
          {{ item.orden_corte | folio }}
        </template>
        <template v-slot:[`item.kilos`]="{ item }">
          {{ item.kilos | decimal }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
        <template v-slot:[`item.importe_cuadrilla`]="{ item }">
          {{ item.importe_cuadrilla | moneda }}
        </template>
        <template v-slot:[`item.iva_cuadrilla`]="{ item }">
          {{ item.iva_cuadrilla | moneda }}
        </template>
        <template v-slot:[`item.total_cuadrilla`]="{ item }">
          {{ item.total_cuadrilla | moneda }}
        </template>
        <template v-slot:[`item.CuadrillaApoyo`]="{ item }">
          {{ item.CuadrillaApoyo ? "Sí" : null }}
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
import { Printd } from "printd";
export default {
  name: "Cuadrillas",
  data: () => ({
    headers: [
      { text: "Lote", value: "lote" },
      { text: "Fecha", value: "fecha" },
      { text: "Cuadrilla", value: "cuadrilla" },
      {
        text: "C. Apoyo",
        value: "CuadrillaApoyo",
        width: "5%",
        align: "center"
      },
      { text: "Orden de Corte", value: "orden_corte" },
      { text: "Huerta", value: "huerta" },
      { text: "Kilos", value: "kilos", alig: "end" },
      { text: "Importe", value: "importe_cuadrilla", align: "end" },
      { text: "IVA", value: "iva_cuadrilla", align: "end" },
      { text: "TOTAL", value: "total_cuadrilla", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    search: undefined,
    perPage: 15,
    d: new Printd()
  }),
  computed: {
    ...mapState({
      items: state => state.Calculadora.cortes
    })
  },
  methods: {
    print() {
      let url = "pagos/printCuadrillas";
      window.axios.post(url, { items: this.items }).then(resp => {
        const el = document.createElement("p");
        el.innerHTML = resp.data;
        this.d.print(el);
      });
    }
  }
};
</script>
