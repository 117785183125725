<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-expansion-panels multiple v-if="cliente.id">
        <Datos />
      </v-expansion-panels>
      <Formulario v-else />
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="regresar">Regresar a clientes</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
import Datos from "./panelDatos";
import Formulario from "./formulario";
export default {
  name: "Cliente",
  components: { Datos, Formulario },
  data: () => ({
    //
  }),
  computed: {
    ...mapState({
      cliente: state => state.Clientes.resource
    })
  },
  mounted() {
    const id = this.$route.params.id;
    if (!this.cliente) {
      this.editar(id);
    }
  },
  methods: {
    ...mapActions("Clientes", ["editar"]),
    regresar() {
      this.$router.push({ name: "clientes" });
    }
  }
};
</script>
