var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"green lighten-4"}},[_vm._v(" Notas de Crédito ")]),(_vm.items)?_c('v-expansion-panel-content',{staticClass:"mt-3"},[(_vm.resource.pendiente > 0)?_c('v-btn',{attrs:{"small":"","color":"success","disabled":_vm.loader},on:{"click":_vm.nueva}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 mt-2",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.items.length,"hide-default-footer":"","mobile-breakpoint":"0","loading":_vm.loader},scopedSlots:_vm._u([{key:"item.TipoCambio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("monedaSat")(item.TipoCambio))+" ")]}},{key:"item.Importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe))+" ")]}},{key:"item.Total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.Total))+" ")]}},{key:"item.Fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.Fecha))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return undefined}}],null,true)})],1):_c('v-expansion-panel-content',{staticClass:"mt-3"},[(_vm.resource.pendiente > 0)?_c('v-btn',{attrs:{"small":"","color":"success","disabled":_vm.loader},on:{"click":_vm.nueva}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"loading":_vm.loader}},[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Nueva Nota de Crédito Factura "+_vm._s(_vm.resource.Serie)+" "+_vm._s(_vm.resource.Folio)+" ")]),_c('v-form',{attrs:{"disabled":_vm.loader},on:{"submit":function($event){$event.preventDefault();return _vm.guardar($event)}}},[_c('v-card-text',{staticClass:"mt-1"},[_vm._v(" Importe Pendiente: "+_vm._s(_vm._f("moneda")(_vm.resource.pendiente))+" "),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","type":"number","label":"Importe","error-messages":_vm.errors.importe,"hide-details":"auto"},model:{value:(_vm.importe),callback:function ($$v) {_vm.importe=$$v},expression:"importe"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","disabled":_vm.loader,"type":"submit"}},[_vm._v(" guardar ")]),_c('v-btn',{attrs:{"color":"error","disabled":_vm.loader},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }