var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","clearable":"","label":"Seleccionar Cliente","items":_vm.customers},model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-card-title',[_vm._v(" Facturas Pendientes ")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 mt-2",attrs:{"dense":"","mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","items-per-page":_vm.items.length},scopedSlots:_vm._u([{key:"item.Fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.Fecha))+" ")]}},{key:"item.Total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.Total))+" "+_vm._s(item.Moneda)+" ")]}},{key:"item.pendiente",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.pendiente))+" ")]}}],null,true)})],1),_c('v-card-actions',[_vm._v(" Importe Pendiente: "),_c('strong',[_vm._v(_vm._s(_vm._f("moneda")(_vm.importePendiente)))])])],1)],1),_c('v-col',[_c('v-card',{attrs:{"height":"100%"}},[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Abonar ")]),_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.agregar($event)}}},[_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","clearable":"","label":"Seleccione Factura","items":_vm.posibles,"item-value":"id","item-text":"factura","hide-details":"auto"},model:{value:(_vm.factura_id),callback:function ($$v) {_vm.factura_id=$$v},expression:"factura_id"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Importe a Abonar","type":"number","step":"0.01","hide-details":"auto","error-messages":_vm.errors.importe},model:{value:(_vm.abonar),callback:function ($$v) {_vm.abonar=_vm._n($$v)},expression:"abonar"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"success","type":"submit"}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)],1),(_vm.listos.length)?_c('v-data-table',{staticClass:"elevation-1 mt-2",attrs:{"dense":"","mobile-breakpoint":"0","headers":_vm.headers2,"items":_vm.listos,"hide-default-footer":"","items-per-page":_vm.listos.length},scopedSlots:_vm._u([{key:"item.abonar",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.abonar))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.eliminar(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"item.saldo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")((item.pendiente - item.abonar)))+" ")]}}],null,true)}):_vm._e(),(_vm.listos.length)?_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Fecha del Pago","hide-details":"auto","type":"date","error-messages":_vm.errors.date},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Total a Abonar","hide-details":"auto","disabled":""},model:{value:(_vm.importeAbonar),callback:function ($$v) {_vm.importeAbonar=$$v},expression:"importeAbonar"}})],1)],1):_vm._e()],1),(_vm.listos.length)?_c('v-card-actions',[_c('v-btn',{attrs:{"color":"success","disabled":_vm.loader},on:{"click":_vm.enviar}},[_vm._v(" Guardar ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.$router.push({ name: 'payments' })}}},[_vm._v(" cancelar ")])],1):_vm._e()],1)],1)],1)],1),_c('v-card-actions',[(!_vm.listos.length)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'payments' })}}},[_vm._v(" regresar ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }