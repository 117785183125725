<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="blue lighten-4" @click="verificar">
      {{ title }}
    </v-expansion-panel-header>
    <AddGasto
      v-if="dialog"
      :posibles="posibles"
      @cerrar="dialog = false"
      @actualizar="getData"
    />
    <v-expansion-panel-content v-else>
      <v-card-text>
        <v-row>
          <v-col>
            <v-btn
              small
              color="info"
              :loading="loader"
              :disabled="loader"
              @click="getData"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn
              v-if="posibles && posibles.length"
              color="success"
              class="ml-2"
              small
              :loading="loader"
              :disabled="loader"
              @click="agregar"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          dense
          mobile-breakpoint="0"
          :headers="headers"
          :items="items"
          hide-default-footer
          class="elevation-1 mt-2"
          :loading="loader"
        >
          <template v-slot:[`item.date`]="{ item }">
            {{ item.date | dateTime }}
          </template>
          <template v-slot:[`item.importe`]="{ item }">
            {{ item.importe | moneda }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import AddGasto from "./AddGasto.vue";
export default {
  name: "GastosExportacion",
  components: { AddGasto },
  data: () => ({
    items: undefined,
    headers: undefined,
    posibles: undefined,
    dialog: false
  }),
  computed: {
    ...mapState({
      loader: state => state.loader
    }),
    title() {
      return this.dialog ? "Agregar Factura" : "Gastos Exportación";
    }
  },
  methods: {
    ...mapActions(["errores", "message"]),
    ...mapMutations(["setErrors", "setLoader"]),
    getData() {
      this.setLoader();
      this.items = this.dialog = this.posibles = undefined;
      let url = `/Gerencia/embarques/egresos/${this.$route.params.id}`;
      window.axios
        .get(url)
        .then(resp => {
          this.items = resp.data.collection;
          this.headers = resp.data.headers;
          this.posibles = resp.data.posibles;
        })
        .catch(e => this.errores(e))
        .finally(() => this.setLoader(false));
    },
    verificar() {
      if (!this.items) this.getData();
    },
    agregar() {
      this.dialog = true;
    }
  }
};
</script>
