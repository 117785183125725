<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Conceptos de la Factura
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-data-table
        dense
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        class="elevation-1 mt-2"
        :items-per-page="items.length"
        hide-default-footer
      >
        <template v-slot:[`item.Cantidad`]="{ item }">
          {{ item.Cantidad | numero }}
        </template>
        <template v-slot:[`item.ValorUnitario`]="{ item }">
          {{ item.ValorUnitario | moneda }}
        </template>
        <template v-slot:[`item.Importe`]="{ item }">
          {{ item.Importe | moneda }}
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Concepts",
  computed: {
    ...mapState({
      items: state => state.Egresos.xml.Conceptos,
      traslados: state => state.Egresos.xml.Traslados
    })
  },
  data: () => ({
    headers: [
      { text: "Descripción", value: "Descripcion" },
      { text: "Cantidad", value: "Cantidad", align: "end" },
      { text: "Valor Unitario", value: "ValorUnitario", align: "end" },
      { text: "Importe", value: "Importe", align: "end" }
    ]
  })
};
</script>
