<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <Editar v-if="pwdChecked" />
    <CheckPassword v-else />
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import CheckPassword from "./../views/auth/CheckPassword.vue";
import Editar from "./../views/auth/Editar.vue";
export default {
  name: "EditMe",
  components: { CheckPassword, Editar },
  computed: {
    ...mapState({
      pwdChecked: state => state.Auth.pwdChecked
    })
  },
  methods: {
    ...mapMutations("Auth", ["setPwdChecked"]),
    
  },
  beforeMount() {
    this.setPwdChecked();
  }
};
</script>
