<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-form @submit.prevent="getAll" :disabled="loader">
        <v-row class="mb-3">
          <v-col cols="12" md="6">
            <v-text-field
              dense
              outlined
              clearable
              label="Buscar"
              hide-details="auto"
              v-model="paginado.search"
            />
          </v-col>
          <v-col>
            <v-btn color="info" type="submit" :disabled="loader">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              color="success"
              class="ml-2"
              :disabled="loader"
              @click="nuevo"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-data-table
        dense
        mobile-breakpoint="0"
        hide-default-footer
        :headers="headers"
        :items="items"
        class="elevation-1"
        :loading="loader"
        :item-class="rowClasses"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="success"
            v-if="item.deleted_at"
            @click="habilitar(item)"
            :disabled="loader"
          >
            <v-icon>mdi-delete-restore</v-icon>
          </v-btn>
          <template v-else>
            <v-btn
              icon
              color="primary"
              @click="getResource(item.id)"
              :disabled="loader"
            >
              <v-icon>mdi-information</v-icon>
            </v-btn>
            <v-btn
              icon
              color="error"
              @click="deshabilitar(item)"
              :disabled="loader"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </template>
        <template v-slot:[`item.telefono`]="{ item }">{{
          item.telefono | phone
        }}</template>
        <template v-slot:[`item.comision`]="{ item }">{{
          item.comision | moneda
        }}</template>
      </v-data-table>
      <paginado :paginado="paginado" :loader="loader" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Index",
  computed: {
    ...mapState({
      items: state => state.Comisionistas.collection,
      headers: state => state.Comisionistas.headers,
      paginado: state => state.Comisionistas.paginado,
      loader: state => state.Comisionistas.loader
    }),
    page() {
      return this.paginado.currentPage;
    },
    search() {
      return this.paginado.search;
    }
  },
  methods: {
    ...mapActions("Comisionistas", [
      "getAll",
      "nuevo",
      "getResource",
      "disableResource",
      "enableResource"
    ]),
    deshabilitar(item) {
      this.$swal({
        title: `¿ Desabilitar a ${item.nombre} ?`,
        icon: "question",
        showCancelButton: true
      }).then(result => {
        if (result.isConfirmed) {
          this.disableResource(item.id);
        }
      });
    },
    habilitar(item) {
      this.$swal({
        title: `¿ Habilitar a ${item.nombre} ?`,
        icon: "question",
        showCancelButton: true
      }).then(result => {
        if (result.isConfirmed) {
          this.enableResource(item.id);
        }
      });
    }
  },
  beforeMount() {
    this.getAll();
  },
  watch: {
    page() {
      this.getAll();
    },
    search(val) {
      if (!val) this.getAll();
    }
  }
};
</script>
