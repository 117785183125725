import Embarques from "./../views/embarques/index.vue";
import DetalleEmbarque from "./../views/embarques/detalles.vue";
const routes = [
  {
    path: "/Embarques",
    name: "embarques",
    component: Embarques,
    meta: {
      name: "Control de Embarques"
    }
  },
  {
    path: "/Embarques/detalles/:id",
    name: "embarque.details",
    component: DetalleEmbarque,
    meta: {
      name: "Detalle del Embarque"
    }
  },
  {
    path: "/Embarques/facturar/:id",
    name: "embarque.facturar",
    component: require("@/views/embarques/Facturar.vue").default,
    meta: {
      name: "Facturar el Embarque"
    }
  }
];

export default routes;
