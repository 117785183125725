var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Toneladas Aguacate Orgánico ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('bar-chart',{attrs:{"chart-data":_vm.datacollection,"options":_vm.options,"height":250}})],1),_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("decimal")(_vm.Total(item)))+" ")]}},{key:"item.year",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.label)+" ")]}},{key:"item.0",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data[0])+" ")]}},{key:"item.1",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data[1])+" ")]}},{key:"item.2",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data[2])+" ")]}},{key:"item.3",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data[3])+" ")]}},{key:"item.4",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data[4])+" ")]}},{key:"item.5",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data[5])+" ")]}},{key:"item.6",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data[6])+" ")]}},{key:"item.7",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data[7])+" ")]}},{key:"item.8",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data[8])+" ")]}},{key:"item.9",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data[9])+" ")]}},{key:"item.10",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data[10])+" ")]}},{key:"item.11",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data[11])+" ")]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.actualizar}},[_vm._v("actualizar")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }