import router from "../../router";
const url = "/Pagos/calculadora";
const initialState = () => ({
  loader: false,
  fechas: {
    inicio: undefined,
    fin: undefined
  },
  cortes: undefined,
  lotes: undefined,
  comisionistas: undefined
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setComisionistas(state, payload) {
      state.comisionistas = payload;
    },
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    setLoader(state, payload) {
      state.loader = payload;
    },
    setFechas(state, payload) {
      state.fechas = payload;
    },
    setCortes(state, payload) {
      state.cortes = payload;
    },
    setLotes(state, payload) {
      state.lotes = payload;
    }
  },
  actions: {
    async buscar({ commit, dispatch, state }) {
      commit("setLoader", true);
      commit("setCortes", undefined);
      commit("setLotes", undefined);
      commit("setComisionistas", undefined);
      await window.axios
        .get(url, {
          params: {
            inicio: state.fechas.inicio,
            fin: state.fechas.fin
          }
        })
        .then(resp => {
          commit("setFechas", resp.data.fechas);
          commit("setCortes", resp.data.cortes);
          commit("setLotes", resp.data.lotes);
          commit("setComisionistas", resp.data.comisionistas);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    }
  }
};
