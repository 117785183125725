const name = 'comisionistas'
const path = '/Comisionistas'
const routes = [
  {
    path: `${path}`,
    name: `${name}`,
    component: require("@/views/comisionistas/Index.vue").default,
    meta: {
      name: "Control de Comisionistas"
    }
  },
  {
    path: `${path}/nuevo`,
    name: `${name}.create`,
    component: require("@/views/comisionistas/Show.vue").default,
    meta: {
      name: "Nuevo Comisionista"
    }
  },
  {
    path: `${path}/editar/:id`,
    name: `${name}.edit`,
    component: require("@/views/comisionistas/Show.vue").default,
    meta: {
      name: "Editar Comisionista"
    }
  }
];
export default routes;
