<!-- eslint-disable -->
<template>
  <v-card height="100%" v-if="resource">
    <v-expansion-panels multiple v-model="panel">
      <panel-resource />
    </v-expansion-panels>
    <v-card-actions v-if="$route.params.id">
      <v-btn color="primary" @click="$router.push({ name: 'comisionistas' })">
        regresar a Comisionistas
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PanelResource from "./PanelResource.vue";
export default {
  name: "Show",
  components: { PanelResource },
  computed: {
    ...mapState({
      resource: state => state.Comisionistas.resource
    }),
    panel: {
      get: function() {
        return this.$store.state.Comisionistas.panel;
      },
      set: function(val) {
        this.$store.commit("Comisionistas/setPanel", val);
      }
    }
  },
  methods: {
    ...mapActions("Comisionistas", ["nuevo", "getResource"])
  },
  beforeMount() {
    if (!this.resource) {
      if (this.$route.params.id) {
        this.getResource(this.$route.params.id);
      } else {
        this.nuevo();
      }
    }
  }
};
</script>
