<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Facturas Relacionadas
    </v-expansion-panel-header>
    <v-expansion-panel-content class="mt-3">
      <v-data-table
        dense
        mobile-breakpoint="0"
        hide-default-footer
        :headers="headers"
        :items="items"
        class="elevation-1 mt-3"
        :item-class="rowClasses"
        :loading="loader"
        :items-per-page="items.length"
      >
        <template v-slot:[`item.Fecha`]="{ item }">
        {{ item.Fecha | date }}
      </template>
      <template v-slot:[`item.ImpSaldoAnt`]="{ item }">
        {{ item.ImpSaldoAnt | moneda }}
      </template>
      <template v-slot:[`item.ImpPagado`]="{ item }">
        {{ item.ImpPagado | moneda }}
      </template>
      <template v-slot:[`item.ImpSaldoInsoluto`]="{ item }">
        {{ item.ImpSaldoInsoluto | moneda }}
      </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Complemento",
  data: () => ({
    headers: [
      { text: "Fecha", value: "Fecha" },
      { text: "Folio", value: "Folio" },
      { text: "UUID", value: "IdDocumento" },
      { text: "Moneda", value: "MonedaDR" },
      { text: "Parcialidad", value: "NumParcialidad", align: "end" },
      { text: "Saldo Anterior", value: "ImpSaldoAnt", align: "end" },
      { text: "Importe Pagado", value: "ImpPagado", align: "end" },
      { text: "Saldo ", value: "ImpSaldoInsoluto", align: "end" }
    ]
  }),
  computed: {
    ...mapState({
      items: state =>
        state.Egresos.xml.Complemento.Pagos.Pago.DoctosRelacionados
    })
  }
};
</script>
