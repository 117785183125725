import router from "../../router";
const url = "Gerencia/egresos";
const initialState = () => ({
  headers: [],
  collection: undefined,
  resource: undefined,
  loader: false,
  paginado: { currentPage: 1 },
  xml: undefined,
  categories: undefined,
  accounts: undefined
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    SET_HEADERS(state, payload) {
      state.headers = payload;
    },
    setCollection(state, payload = undefined) {
      state.collection = payload;
    },
    setResource(state, payload = undefined) {
      state.resource = payload;
    },
    setLoader(state, payload = true) {
      state.loader = payload;
    },
    setPaginado(state, payload) {
      state.paginado = payload;
    },
    setXml(state, payload = undefined) {
      state.xml = payload;
    },
    setCategories(state, payload) {
      state.categories = payload;
    },
    setAccounts(state, payload) {
      state.accounts = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch, state }) {
      commit("setCollection");
      commit("setLoader");
      state.paginado.importe = 0;
      window.axios
        .get(url, {
          params: {
            page: state.paginado.currentPage,
            inicio: state.paginado.inicio,
            fin: state.paginado.fin,
            status: state.paginado.status,
            provider_id: state.paginado.provider_id,
            category: state.paginado.category,
            search: state.paginado.search
          }
        })
        .then(resp => {
          commit("setCollection", resp.data.collection);
          commit("SET_HEADERS", resp.data.headers);
          commit("setPaginado", resp.data.paginado);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async getResource({ commit, dispatch }, id) {
      commit("setLoader");
      commit("setResource");
      commit("setXml");
      window.axios
        .get(`${url}/${id}`)
        .then(resp => {
          commit("setResource", resp.data.resource);
          commit("setXml", resp.data.xml);
          commit("setCategories", resp.data.categories);
          commit("setAccounts", resp.data.accounts);
          router.push({ name: "egresos.show", params: { id } }).catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async updateResource({ commit, dispatch, state }) {
      commit("setLoader");
      let resource = state.resource;
      await window.axios
        .put(`${url}/${resource.id}`, resource)
        .then(resp => {
          dispatch("message", resp.data.message, { root: true });
          dispatch("getResource", resource.id);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async pago({ commit, dispatch, state }, pago) {
      commit("setLoader");
      commit("setErrors", [], { root: true });
      await window.axios
        .post(`${url}/pago/${state.resource.id}`, pago)
        .then(resp => {
          dispatch("message", resp.data.message, { root: true });
          dispatch("getResource", state.resource.id);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async eliminarPago({ commit, dispatch, state }, id) {
      commit("setLoader");
      await window.axios
        .post(`${url}/eliminarPago/${id}`)
        .then(resp => {
          dispatch("message", resp.data.message, { root: true });
          dispatch("getResource", state.resource.id);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async deleteResource({ commit, dispatch }, id) {
      commit("setLoader");
      await window.axios
        .delete(`${url}/${id}`)
        .then(resp => {
          dispatch("message", resp.data.message, { root: true });
          dispatch("getAll");
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async solicitar({ commit, dispatch }) {
      commit("setLoader");
      await window.axios
        .get(`${url}/solicitar`)
        .then(resp => {
          dispatch("message", resp.data.message, { root: true });
          dispatch("getAll");
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    }
  }
};
