require("./bootstrap");
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import { loadProgressBar } from "axios-progress-bar";
import "axios-progress-bar/dist/nprogress.css";
loadProgressBar();
import "./sass/style.scss";
import "./registerServiceWorker";

Vue.config.productionTip = false;
Vue.filter("date", function(value) {
  if (value) {
    return window
      .moment(value)
      .locale("es-MX")
      .format("DD/MMM/YYYY");
  }
  return "";
});
Vue.filter("dateTime", function(value) {
  if (value) {
    return window
      .moment(value)
      .locale("es-MX")
      .format("DD/MMM/YYYY hh:mm A");
  }

  return "";
});
Vue.filter("moneda", function(value) {
  if (!value) return "";
  if (isNaN(value)) value = parseFloat(value);
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });
  if (isNaN(value)) return "";
  var numberString = formatter
    .formatToParts(value)
    .map(({ type, value }) => {
      switch (type) {
        case "currency":
          return `${value} `;
        default:
          return value;
      }
    })
    .reduce((string, part) => string + part);
  return numberString;
});
Vue.filter("monedaSat", function(value) {
  if (!value) return "";
  if (isNaN(value)) value = parseFloat(value);
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 4
  });
  if (isNaN(value)) return "";
  var numberString = formatter
    .formatToParts(value)
    .map(({ type, value }) => {
      switch (type) {
        case "currency":
          return `${value} `;
        default:
          return value;
      }
    })
    .reduce((string, part) => string + part);
  return numberString;
});
Vue.filter("folio", function(value) {
  return String(value).padStart(5, "0");
});
Vue.filter("numero", function(value) {
  if (isNaN(value)) value = parseFloat(value);
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });
  if (isNaN(value)) return "";
  var numberString = formatter
    .formatToParts(value)
    .map(({ type, value }) => {
      switch (type) {
        case "currency":
          return ` `;
        case "fraction":
          return "";
        case "decimal":
          return "";
        default:
          return value;
      }
    })
    .reduce((string, part) => string + part);
  return numberString;
});
Vue.filter("decimal", function(value) {
  if (isNaN(value)) value = parseFloat(value);
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });
  if (isNaN(value)) return "";
  var numberString = formatter
    .formatToParts(value)
    .map(({ type, value }) => {
      switch (type) {
        case "currency":
          return ` `;
        default:
          return value;
      }
    })
    .reduce((string, part) => string + part);
  return numberString;
});
Vue.filter("phone", function(val) {
  if (!val) return "";
  let code = val.substr(0, 3),
    first = val.substr(3, 3),
    last = val.substr(6, 4);
  return `(${code}) ${first} - ${last}`;
});
Vue.component("Paginado", require("@/components/Paginado.vue").default);
Vue.component("BarChart", require("@/views/BarChart.js").default);
Vue.mixin({
  methods: {
    rowClasses(item) {
      if (item.deleted_at) {
        return "red lighten-5"; //can also return multiple classes e.g ["orange","disabled"]
      }
    },
    userCan(ability) {
      let abilities = store.state.Auth.abilities;
      if (!abilities) return false;
      return abilities.includes(ability) || abilities.includes("*");
    }
  }
});
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
const optSwa = {
  confirmButtonColor: "#4CAF50",
  cancelButtonColor: "#d33",
  confirmButtonText: "Si",
  cancelButtonText: "No"
};
Vue.use(VueSweetalert2, optSwa);
new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount("#app");
