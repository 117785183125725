var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"blue lighten-4"}},[_vm._v(" Productos de la Venta ")]),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',[_vm._v("Producto por Pallet")]),_c('v-data-table',{staticClass:"elevation-1 mt-3",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.items.length,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe))+" ")]}},{key:"item.precio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.precio))+" ")]}},{key:"item.kilos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("decimal")(item.kilos))+" ")]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',[_vm._v("Concentrado")]),_c('v-data-table',{staticClass:"elevation-1 mt-3",attrs:{"dense":"","headers":_vm.headers2,"items":_vm.items2,"items-per-page":_vm.items2.length,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe))+" ")]}},{key:"item.precio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.precio))+" ")]}},{key:"item.kilos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("decimal")(item.kilos))+" ")]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }