<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4">
          <v-btn small color="success" class="mt-5" @click="nuevo">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn small color="info" class="mt-5 ml-3" @click="getAll">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :items="items"
        :headers="headers"
        class="elevation-1"
        :search.sync="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="success" @click="editar(item.id)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon color="red">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  name: "Clientes",
  data: () => ({
    search: undefined
  }),
  computed: {
    ...mapState({
      items: state => state.Clientes.collection,
      headers: state => state.Clientes.headers
    })
  },
  mounted() {
    if (!this.items.length) this.getAll();
  },
  methods: {
    ...mapMutations("Clientes", ["setResource"]),
    ...mapActions("Clientes", ["getAll", "editar"]),
    nuevo() {
      this.setResource({});
      this.$router.push({ name: "cliente.create" });
    }
  }
};
</script>
