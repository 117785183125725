<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Datos de la Factura {{ resource.folio }}
    </v-expansion-panel-header>
    <v-expansion-panel-content v-if="resource">
      <v-row class="mt-2">
        <v-col>
          Proveedor: <strong>{{ resource.provider.name }}</strong>
        </v-col>
        <v-col>
          RFC: <strong>{{ resource.provider.rfc }}</strong>
        </v-col>
        <v-col>
          Fecha: <strong>{{ resource.date | dateTime }}</strong>
        </v-col>
        <v-col>
          Folio de la Factura: <strong>{{ resource.folio }}</strong>
        </v-col>
        <v-col>
          Folio SAT: <strong>{{ resource.UUID }}</strong>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          Importe: <strong>{{ resource.total | moneda }}</strong>
        </v-col>
        <v-col>
          Moneda: <strong>{{ resource.moneda }}</strong>
        </v-col>
        <template v-if="resource.moneda == 'USD'">
          <v-col>
            Tipo de Cambio:
            <strong>{{ resource.tipo_cambio | monedaSat }}</strong>
          </v-col>
          <v-col>
            Importe en Pesos: <strong>{{ resource.importe | moneda }}</strong>
          </v-col>
        </template>
      </v-row>
      <v-row class="mt-3">
        <v-col>
          <v-combobox
            dense
            outlined
            label="Partida"
            v-model="category"
            :items="categories"
          />
        </v-col>
        <v-col>
          <v-btn
            color="success"
            class="mr-2"
            v-if="category !== categoryOriginal"
            @click="$store.dispatch('Egresos/updateResource')"
          >
            Actualizar
          </v-btn>
          <v-btn color="primary" :href="link" target="_blank">
            Validar en SAT
          </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Invoice",
  data: () => ({
    category: undefined,
    categoryOriginal: undefined
  }),
  computed: {
    ...mapState({
      resource: state => state.Egresos.resource,
      link: state => state.Egresos.xml.qr,
      categories: state => state.Egresos.categories
    })
  },
  watch: {
    category: function(val) {
      this.resource.category = val;
    },
    resource: function(val) {
      this.categoryOriginal = this.category = val.category;
    }
  },
  mounted() {
    this.category = this.categoryOriginal = this.resource.category;
  }
};
</script>
