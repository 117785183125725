<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="blue lighten-4">
      Cuadrillas
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" xl="4" offset-xl="8">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        class="elevation-1 mt-3"
        :headers="headers"
        :items="items"
        :search.sync="search"
      >
        <template v-slot:[`item.lote`]="{ item }">
          {{ item.lote | folio }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
      </v-data-table>
      <v-row class="mt-3">
        <v-col> Importe del Periodo: {{ importe | moneda }} </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Productores",
  computed: {
    ...mapState({
      pagos: state => state.Pagos.collection
    }),
    items() {
      return this.pagos.filter(x => x.tipo == "CORTE");
    },
    importe() {
      return window._.sumBy(this.items, "importe");
    }
  },
  data: () => ({
    headers: [
      { text: "Fecha", value: "fecha" },
      { text: "Lote", value: "lote" },
      { text: "Huerta", value: "huerta" },
      { text: "Factura", value: "factura" },
      { text: "Beneficiario", value: "beneficiario" },
      { text: "Importe", value: "importe", align: "end" }
    ],
    search: undefined
  })
};
</script>
