<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="orange lighten-4">
      Datos de la Nota
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-form @submit.prevent="nuevaVenta(nota)">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-autocomplete
                dense
                outlined
                small-chips
                clearable
                label="Cliente"
                :items="clientes"
                v-model="nota.cliente_id"
                :error-messages="errors.cliente_id"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                outlined
                dense
                type="date"
                label="Fecha"
                v-model="nota.fecha"
                :error-messages="errors.fecha"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                outlined
                dense
                type="text"
                label="Nota"
                v-model="nota.nota"
                :error-messages="errors.nota"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-combobox
                outlined
                dense
                type="search"
                label="Chofer"
                v-model="nota.chofer"
                :error-messages="errors.chofer"
                :items="choferes"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-combobox
                outlined
                dense
                label="Marca"
                v-model="nota.marca"
                :error-messages="errors.marca"
                :items="marcas"
                type="search"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-combobox
                outlined
                dense
                type="search"
                label="Color"
                v-model="nota.color"
                :error-messages="errors.color"
                :items="colores"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-combobox
                outlined
                dense
                type="search"
                label="Placas"
                v-model="nota.placas"
                :error-messages="errors.placas"
                :items="placas"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                outlined
                label="Observaciones"
                v-model="nota.observaciones"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" type="submit" :disabled="loading">
            guardar
          </v-btn>
          <v-btn
            color="error"
            :disabled="loading"
            @click="$router.push({ name: 'ventas' })"
          >
            cancelar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Nota",
  data: () => ({
    nota: {}
  }),
  computed: {
    ...mapState({
      seleccionados: state => state.Ventas.seleccionados,
      clientes: state => state.Ventas.clientes,
      loading: state => state.Ventas.loader,
      errors: state => state.errors,
      datos: state => state.Ventas.nuevo.datos
    }),
    listos() {
      return this.seleccionados.filter(x => x.precio_kilo > 0);
    },
    filtrar() {
      return this.datos.filter(x => x.cliente_id == this.nota.cliente_id);
    },
    marcas() {
      let filtrados = this.filtrar.filter(x => x.marca);
      return [...new Set(filtrados.map(x => x.marca))].sort();
    },
    colores() {
      let filtrados = this.filtrar.filter(x => x.color);
      return [...new Set(filtrados.map(x => x.color))].sort();
    },
    choferes() {
      let filtrados = this.filtrar.filter(x => x.chofer);
      return [...new Set(filtrados.map(x => x.chofer))].sort();
    },
    placas() {
      let filtrados = this.filtrar.filter(x => x.placas);
      return [...new Set(filtrados.map(x => x.placas))].sort();
    }
  },
  mounted() {
    this.setErrors([]);
  },
  methods: {
    ...mapActions("Ventas", ["nuevaVenta"]),
    ...mapMutations(["setErrors"])
  }
};
</script>
