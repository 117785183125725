<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-row>
        <v-col class="mb-2">
          <v-btn color="info" @click="getAll"
            ><v-icon>mdi-refresh</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <v-data-table
        dense
        mobile-breakpoint="0"
        hide-default-footer
        :headers="headers"
        :items="items"
        class="elevation-1"
        :item-class="rowClasses"
        :loading="loader"
      >
        <template v-slot:[`item.FechaInicioRelLaboral`]="{ item }">
          {{ item.FechaInicioRelLaboral | date }}
        </template>
        <template v-slot:[`item.nacimiento`]="{ item }">
          {{ item.nacimiento | date }}
        </template>
        <template v-slot:[`item.SalarioDiarioIntegrado`]="{ item }">
          {{ item.SalarioDiarioIntegrado | moneda }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <template v-if="item.status == 'PENDIENTE'">
            <v-btn icon color="error" :disabled="loader">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <v-btn icon :disabled="loader" color="primary" @click="getResource(item.id)">
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <Paginado :loader="loader" :paginado="paginado" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Employes",
  computed: {
    ...mapState({
      loader: state => state.Employes.loader,
      items: state => state.Employes.collection,
      headers: state => state.Employes.headers,
      paginado: state => state.Employes.paginado
    })
  },
  methods: {
    ...mapActions("Employes", ["getAll", "getResource"])
  },
  mounted() {
    this.getAll();
  }
};
</script>
