<!-- eslint-disable -->
<template>
  <v-expansion-panel-content class="mt-3">
    <v-card>
      <v-form @submit.prevent="$store.dispatch('Egresos/pago', pago)">
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                dense
                outlined
                type="date"
                label="Fecha de Pago"
                v-model="pago.date"
                :error-messages="errors.date"
              />
            </v-col>
            <v-col>
              <v-text-field
                dense
                outlined
                type="number"
                step="0.01"
                label="Importe"
                v-model.number="pago.amount"
                :error-messages="errors.amount"
              />
            </v-col>
            <v-col>
              <v-text-field
                dense
                outlined
                label="Moneda"
                disabled
                v-model.number="pago.moneda"
                :error-messages="errors.moneda"
              />
            </v-col>
            <v-col>
              <v-combobox
                dense
                outlined
                label="Cuenta"
                :items="accounts"
                v-model.number="pago.cuenta"
                :error-messages="errors.cuenta"
              />
            </v-col>
            <v-col>
              <v-text-field
                dense
                outlined
                label="Referencia"
                v-model.number="pago.referencia"
                :error-messages="errors.referencia"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                outlined
                label="Observaciones"
                v-model="pago.observations"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" type="submit" :disabled="loader">
            Guardar
          </v-btn>
          <v-btn color="error" @click="$emit('cancelar')" :disabled="loader">
            cancelar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-expansion-panel-content>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Formulario",
  data: () => ({
    pago: {}
  }),
  computed: {
    ...mapState({
      loader: state => state.Egresos.loader,
      accounts: state => state.Egresos.accounts,
      errors: state => state.errors,
      resource: state => state.Egresos.resource
    })
  },
  mounted() {
    this.$store.commit("setErrors", []);
    this.pago.moneda = this.resource.moneda;
  }
};
</script>
