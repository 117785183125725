<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="orange lighten-4">
      Datos del Lote
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-form :disabled="loader" @submit.prevent="enviar">
        <v-row class="mt-3">
          <v-col cols="12" md="4" xl="auto">
            N° de Lote: {{ lote.lote | folio }}
          </v-col>
          <v-col cols="12" md="4" xl="auto">
            N° Seleccion: {{ lote.seleccion | folio }}
          </v-col>
          <v-col cols="12" md="4" xl="auto">
            Tipo de corte: {{ lote.tipo_corte }}
          </v-col>
          <v-col cols="12" md="4" xl="auto">
            Fecha de corte: {{ lote.fecha_corte | date }}
          </v-col>
          <v-col cols="12" md="4" xl="auto">
            Fecha de Corrida {{ lote.fecha | date }}
          </v-col>
          <v-col cols="12" md="4" xl="auto">
            Peso Neto: {{ lote.peso_neto | decimal }}
          </v-col>
        </v-row>
        <v-row class="mt-2">
          <v-col cols="12" md="4">
            <v-autocomplete
              dense
              outlined
              clearable
              label="Comisionista"
              v-model="lote.comisionista_id"
              :items="comisionistas"
              item-value="id"
              item-text="nombre"
            />
          </v-col>
          <v-col cols="12" md="auto">
            <v-text-field
              dense
              outlined
              clearable
              label="Tipo de Comisión"
              v-model="comisionista.type"
              disabled
            />
          </v-col>
          <v-col cols="12" md="auto">
            <v-text-field
              dense
              outlined
              clearable
              label=" Comisión"
              v-model="comisionista.comision"
              disabled
            />
          </v-col>
          <v-col cols="12" md="auto">
            <v-text-field
              dense
              outlined
              clearable
              label=" Importe Comisión"
              v-model="lote.importe_comisionista"
              disabled
            />
          </v-col>
        </v-row>
        <v-card-actions v-if="modificado">
          <v-btn color="success" type="submit" :disabled="loader">
            actualizar
          </v-btn>
          <v-btn color="error" @click="cancelar" :disabled="loader">
            cancelar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "Lote",
  computed: {
    ...mapState({
      lote: state => state.Lotes.resource.lote,
      comisionistas: state => state.Lotes.resource.comisionistas,
      resource: state => state.Lotes.resource,
      loader: state => state.loader
    }),
    modificado() {
      return JSON.stringify(this.original) !== JSON.stringify(this.lote);
    },
    comisionista() {
      if (!this.lote.comisionista_id) return {};
      return this.comisionistas.find(x => x.id == this.lote.comisionista_id);
    }
  },
  data: () => ({
    original: {}
  }),
  beforeMount() {
    this.original = Object.assign({}, this.lote);
  },
  watch: {
    lote(val) {
      this.original = Object.assign({}, val);
    }
  },
  methods: {
    ...mapMutations(["setLoader"]),
    cancelar() {
      this.resource.lote = this.original;
    },
    enviar() {
      this.setLoader();
      window.axios
        .put(`Gerencia/lotes/${this.$route.params.id}`, this.lote)
        .then(resp => {
          this.$store.dispatch("message", resp.data.message);
          this.$emit('actualizar')
        })
        .catch(e => this.$store.dispatch("errores", e))
        .finally(() => this.setLoader(false));
    }
  }
};
</script>
