<template>
  <v-expansion-panel-content>
    <v-card-text>
      <v-data-table
        show-select
        dense
        mobile-breakpoint="0"
        :headers="headers"
        :items="posibles"
        hide-default-footer
        class="elevation-1 mt-2"
        :loading="loader"
        v-model="selected"
      >
        <template v-slot:[`item.date`]="{ item }">
          {{ item.date | dateTime }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-btn
        :disabled="loader"
        :loading="loader"
        color="success"
        @click="enviar"
        >guardar</v-btn
      >
      <v-btn
        :disabled="loader"
        :loading="loader"
        color="error"
        @click="$emit('cerrar')"
        >cancelar</v-btn
      >
    </v-card-actions>
  </v-expansion-panel-content>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "AddGasto",
  props: ["posibles"],
  emits: ["actualizar", "cerrar"],
  data: () => ({
    selected: [],
    headers: [
      { text: "Categoría", value: "category" },
      { text: "Fecha", value: "date" },
      { text: "Factura", value: "folio" },
      { text: "Proveedor", value: "provider.name" },
      { text: "Importe", value: "importe", align: "end", width: "5" }
    ]
  }),
  computed: {
    ...mapState({
      loader: state => state.loader
    })
  },
  methods: {
    ...mapActions(["errores", "message"]),
    ...mapMutations(["setErrors", "setLoader"]),
    enviar() {
      let url = `/Gerencia/embarques/egresos/${this.$route.params.id}`,
        payload = { selected: this.selected };
      this.setLoader();
      window.axios
        .post(url, payload)
        .then(resp => {
          this.message(resp.data.message);
          this.$emit("actualizar");
        })
        .catch(e => this.errores(e))
        .finally(() => this.setLoader(false));
    }
  }
};
</script>
