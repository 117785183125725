<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="3" xl="2">
          <v-text-field
            dense
            outlined
            clearable
            label="Fecha Inicial"
            v-model="inicio"
            type="date"
            hide-details="auto"
            :error-messages="errors.inicio"
            :disabled="loader"
          />
        </v-col>
        <v-col cols="12" md="3" xl="2">
          <v-text-field
            dense
            outlined
            clearable
            label="Fecha Final"
            v-model="fin"
            type="date"
            hide-details="auto"
            :error-messages="errors.fin"
            :disabled="loader"
          />
        </v-col>
        <v-col cols="12" md="3" xl="2">
          <v-autocomplete
            dense
            outlined
            clearable
            label="Tipo de Aguacate"
            v-model="tipo"
            :items="tipos"
            type="search"
            hide-details="auto"
            :disabled="loader"
            :error-messages="errors.tipo"
          />
        </v-col>
        <v-col cols="12" md="4" xl="2">
          <v-btn color="info" @click="enviar" :disabled="loader">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <template v-if="resultados">
        <v-row class="mt-3">
          <v-col cols="12">
            <h3>Lotes: {{ response.lotes }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card height="100%">
              <v-card-text>
                <h3>Categoría 1</h3>
                <v-col cols="12">
                  <bar-chart
                    :chart-data="response.Cat1"
                    :options="options"
                    :height="250"
                  />
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card height="100%">
              <v-card-text>
                <h3>Categoría 2</h3>
                <v-col cols="12">
                  <bar-chart
                    :chart-data="response.Cat2"
                    :options="options"
                    :height="250"
                  />
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Calibres",
  data: () => ({
    options: {
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              //stepSize: 100
            }
          }
        ]
      }
    },
    inicio: undefined,
    fin: undefined,
    resultados: false,
    tipo: undefined,
    tipos: [
      { text: "Orgánico", value: "org" },
      { text: "Convencional", value: "conv" }
    ],
    category: undefined,
    categories: [
      { text: "Cat 1", value: "1" },
      { text: "Cat 2", value: "2" }
    ],
    response: undefined
  }),
  computed: {
    ...mapState({
      loader: state => state.loader,
      errors: state => state.errors
    })
  },
  methods: {
    ...mapMutations(["setLoader", "setErrors"]),
    ...mapActions(["errores"]),
    enviar() {
      this.setLoader();
      this.setErrors();
      this.resultados = false;
      let payload = {
          inicio: this.inicio,
          fin: this.fin,
          tipo: this.tipo,
          category: this.category
        },
        url = "Gerencia/dashboard/calibres";
      window.axios
        .post(url, payload)
        .then(resp => {
          this.response = resp.data;
          this.resultados = true;
        })
        .catch(e => this.errores(e))
        .finally(this.setLoader(false));
    }
  },
  mounted() {
    this.setErrors();
    this.setLoader(false);
  }
};
</script>
