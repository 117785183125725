<!-- eslint-disable -->
<template>
  <div class="mt-3">
    <v-row>
      <v-col cols="12" xl="4" offset-xl="8">
        <v-container>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Busqueda"
            single-line
            hide-details
          />
        </v-container>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      class="elevation-1"
      :search.sync="search"
    >
      <template v-slot:[`item.pallets`]="{ item }">
        <template v-for="pallet in item.pallets">
          {{ `${pallet.pallet} (${pallet.cantidad}) ` }}
        </template>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  data: () => ({
    headers: [
      { text: "Producto", value: "producto", width: "25%" },
      { text: "Pallets", value: "pallets", width: "65%" },
      { text: "Total Cajas", value: "cajas", align: "end" }
    ],
    items: [],
    search: undefined
  }),
  computed: {
    ...mapState({
      detalles: state => state.Inventario.detalles.pallets
    }),
    productos() {
      let validos = this.detalles.filter(x => x.presentacion !== "KGS");
      return [...new Set(validos.map(x => x.producto))].sort();
    }
  },
  methods: {
    armarPallets(producto) {
      let porProducto = this.detalles.filter(x => x.producto == producto),
        pallets = [...new Set(porProducto.map(x => x.pallet))].sort(),
        listos = [];
      pallets.forEach(pallet => {
        let porPallet = porProducto.filter(x => x.pallet == pallet);
        if (_.sumBy(porPallet, "cantidad") < 80) {
          listos.push({
            producto,
            pallet,
            cantidad: _.sumBy(porPallet, "cantidad")
          });
        }
      });
      let item = {
        producto,
        pallets: listos,
        cajas: _.sumBy(listos, "cantidad")
      };
      this.items.push(item);
    }
  },
  mounted() {
    this.productos.forEach(x => this.armarPallets(x));
  },
  beforeDestroy() {
    //
  }
};
</script>
