<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Datos del Cliente
    </v-expansion-panel-header>
    <v-expansion-panel-content class="mt-3">
      <v-row>
        <v-col>
          Nombre: <strong>{{ customer.nombre }}</strong>
        </v-col>
        <v-col>
          RFC: <strong>{{ customer.rfc }}</strong>
        </v-col>
        <v-col v-if="customer.NumRegIdTrib">
          Número de Registro Tributario:
          <strong>{{ customer.NumRegIdTrib }}</strong>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          Contacto: <strong>{{ customer.contacto }}</strong>
        </v-col>
        <v-col>
          Teléfono: <strong>{{ customer.telefono |phone}}</strong>
        </v-col>
        <v-col>
            Páis: <strong>{{customer.pais}}</strong>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Customer",
  computed: {
    ...mapState({
      customer: state => state.Invoices.customer
    })
  }
};
</script>
