<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-form @submit.prevent="getAll">
        <v-row>
          <v-col>
            <v-text-field
              dense
              outlined
              type="date"
              v-model="fechas.inicio"
              label="Fecha Inicial"
            />
          </v-col>
          <v-col>
            <v-text-field
              dense
              outlined
              type="date"
              v-model="fechas.fin"
              label="Fecha Final"
            />
          </v-col>
          <v-col>
            <v-text-field
              dense
              outlined
              clearable
              label="Lote"
              v-model="paginado.lote"
            />
          </v-col>
          <v-col>
            <v-text-field
              dense
              outlined
              clearable
              label="Huerta"
              v-model="paginado.huerta"
            />
          </v-col>
          <v-col>
            <v-btn color="primary" type="submit" :disabled="loader">
              buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <v-data-table
        dense
        mobile-breakpoint="0"
        hide-default-footer
        :headers="headers"
        class="elevation-1"
        :search.sync="search"
        :items="items"
        :items-per-page="perPage"
        :loading="loader"
      >
        <template v-slot:[`item.lote`]="{ item }">
          {{ item.lote | folio }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
        <template v-slot:[`item.fecha_corte`]="{ item }">
          {{ item.fecha_corte | date }}
        </template>
        <template v-slot:[`item.kilos_procesados`]="{ item }">
          {{ item.kilos_procesados | decimal }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="primary" @click="getLote(item.id)">
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <Paginado :loader="loader" :paginado="paginado" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Lotes",
  data: () => ({
    headers: [
      { text: "Lote", value: "lote" },
      { text: "Tipo de Aguacate", value: "tipo_aguacate" },
      { text: "Fecha Proceso", value: "fecha" },
      { text: "Fecha Corte", value: "fecha_corte" },
      { text: "Huerta", value: "huerta" },
      { text: "Productor", value: "productor" },
      { text: "Kilos Procesados", value: "kilos_procesados", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    search: undefined,
    perPage: 15
  }),
  computed: {
    ...mapState({
      items: state => state.Lotes.collection,
      fechas: state => state.Lotes.fechas,
      loader: state => state.Lotes.loader,
      paginado: state => state.Lotes.paginado
    }),
    actual() {
      return this.paginado.currentPage;
    }
  },
  mounted() {
    if (!this.items) this.getAll();
  },
  methods: {
    ...mapActions("Lotes", ["getAll", "getLote"])
  },
  watch: {
    actual() {
      this.getAll();
    }
  }
};
</script>
