<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Archivos relacionados al empleado
    </v-expansion-panel-header>
    <v-expansion-panel-content class="mt-3" v-if="upload">
      <v-card>
        <v-form @submit.prevent="enviar" :disabled="loader">
          <v-card-text>
            <v-row>
              <v-col>
                <v-file-input
                  multiple
                  v-model="files"
                  outlined
                  dense
                  label="Seleccione los Archivos"
                  small-chips
                  clearable
                  counter
                  show-size
                  truncate-length="50"
                  :error-messages="errors.files"
                  accept=".pdf,.jpg"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="success" type="submit" :disabled="loader"
              >enviar</v-btn
            >
            <v-btn color="error" @click="cancelar" :disabled="loader"
              >cancelar</v-btn
            >
          </v-card-actions>
        </v-form>
        <v-card-text>
          <v-row>
            <v-col v-if="procesados.length">
              <h3>Archivos Procesados</h3>
              <ul>
                <li v-for="item in procesados" :key="item">{{ item }}</li>
              </ul>
            </v-col>
            <v-col v-if="excluidos.length">
              <h3>Archivos Exlcuidos</h3>
              <ul>
                <li v-for="item in excluidos" :key="item">{{ item }}</li>
              </ul>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-expansion-panel-content>
    <v-expansion-panel-content class="mt-3" v-else>
      <v-row>
        <v-col>
          <v-btn small color="success" @click="subir" :disabled="loader">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        dense
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        class="elevation-1 mt-2"
        :search="search"
        :loading="loader"
      >
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at | dateTime }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="success"
            v-if="item.url"
            :href="item.url"
            target="_blank"
            :disabled="loader"
          >
            <v-icon>mdi-cloud-download</v-icon>
          </v-btn>
          <v-btn icon color="error" @click="eliminar(item)" :disabled="loader">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Files",
  computed: {
    ...mapState({
      items: state => state.Employes.archivos,
      errors: state => state.errors,
      employe_id: state => state.Employes.resource.id
    }),
    url() {
      return `Gerencia/employes/uploadFiles/${this.employe_id}`;
    }
  },
  data: () => ({
    upload: false,
    files: [],
    search: undefined,
    headers: [
      { text: "Nombre del archivo", value: "name" },
      { text: "Fecha de Subida", value: "created_at" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    excluidos: [],
    procesados: [],
    loader: false
  }),
  methods: {
    ...mapMutations(["setErrors"]),
    ...mapActions(["errores", "message"]),
    ...mapActions("Employes", ["getResource"]),
    ...mapMutations("Employes", ["setArchivos"]),
    enviar() {
      this.excluidos = this.procesados = [];
      this.setErrors([]);
      let formData = new FormData();
      for (var i = 0; i < this.files.length; i++) {
        formData.append("files[]", this.files[i]);
      }
      window.axios
        .post(this.url, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(resp => {
          this.message(resp.data.message);
          this.upload = false;
          this.setArchivos(resp.data.files);
          this.files = [];
        })
        .catch(e => this.errores(e));
    },
    subir() {
      this.upload = true;
      this.files = [];
    },
    cancelar() {
      this.upload = false;
    },
    eliminar(item) {
      this.$swal({
        title: `¿Eliminar el archivo ${item.name}?`,
        text: "Esta acción no puede deshacerse",
        icon: "warning",
        showCancelButton: true
      }).then(result => {
        if (result.isConfirmed) {
          this.loader = true;
          window.axios
            .delete(`deleteArchivo/${item.id}`)
            .then(resp => {
              this.message(resp.data.message);
              this.setArchivos(resp.data.files);
            })
            .catch(e => this.errores(e))
            .finally(() => (this.loader = false));
        }
      });
    }
  }
};
</script>
