<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Productos
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class=mt-3>
        <v-card-text>
          <span>Total de Pallets: {{items.length}}</span>
          <span class="separado">Total de Cajas: {{cajas|numero}}</span> 
          <span class="separado">Importe de la factura: {{importe|moneda}}</span>
        </v-card-text>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="12" md="7">
          <v-card height="100%">
            <v-card-title>
              Pallets
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" xl="6" offset-xl="6">
                  <v-container>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Busqueda"
                      single-line
                      hide-details
                    />
                  </v-container>
                </v-col>
              </v-row>
              <v-data-table
                dense
                :headers="headers"
                :items="items"
                class="elevation-1"
                :search.sync="search"
              >
                <template v-slot:[`item.precio`]="{ item }">
                  {{ item.precio | moneda }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="5">
          <v-card height="100%">
            <v-card-title>Productos</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" xl="6" offset-xl="6">
                  <v-container>
                    <v-text-field
                      v-model="search2"
                      append-icon="mdi-magnify"
                      label="Busqueda"
                      single-line
                      hide-details
                    />
                  </v-container>
                </v-col>
              </v-row>
              <v-data-table
                dense
                :headers="headers2"
                :items="items2"
                class="elevation-1"
                :search.sync="search2"
              >
              <template v-slot:[`item.precio`]="{ item }">
                  {{ item.precio | moneda }}
                </template>
                <template v-slot:[`item.importe`]="{ item }">
                  {{ item.importe | moneda }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Pallets",
  data: () => ({
    search: undefined,
    search2: undefined,
    headers: [
      { text: "Pallet", value: "pallet" },
      { text: "Producto(s)", value: "producto" },
      { text: "Precio", value: "precio", align: "end" },
      { text: "Cajas", value: "cajas", align: "end" }
    ],
    headers2: [
      { text: "Producto", value: "producto" },
      { text: "Cajas", value: "cajas", align: "end" },
      { text: "Precio U", value: "precio", align: "end" },
      { text: "Importe", value: "importe", align: "end" }
    ]
  }),
  computed: {
    ...mapState({
      detalles: state => state.Embarques.detalles
    }),
    items() {
      let data = [],
        pallets = [...new Set(this.detalles.map(x => x.pallet))].sort();
      pallets.forEach(pallet => {
        let filtrados = this.detalles.filter(x => x.pallet == pallet),
          obj = {
            pallet,
            producto: [...new Set(filtrados.map(x => x.producto))].sort(),
            cajas: _.sumBy(filtrados, "cantidad"),
            precio: _.sumBy(filtrados, "importe_mexico")
          };
        data.push(obj);
      });
      return data;
    },
    items2() {
      let data = [],
        productos = [...new Set(this.detalles.map(x => x.producto))].sort();
      productos.forEach(producto => {
        let filtrados = this.detalles.filter(x => x.producto == producto),
          obj = {
            producto,
            cajas: _.sumBy(filtrados, "cantidad"),
            precio: filtrados[0].precio_mexico,
            importe: _.sumBy(filtrados, "importe_mexico")
          };
        data.push(obj);
      });

      return data;
    },
    importe(){
      return _.sumBy(this.detalles,'importe_mexico')
    },
    cajas(){
      return _.sumBy(this.detalles,'cantidad')
    }
  }
};
</script>
<style scoped>
span.separado{
  margin-left: 25px;
}
  
</style>