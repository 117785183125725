<!-- eslint-disable -->
<template>
  <div>
    <v-app v-if="login" id="inspire">
      <Menu />

      <v-main>
        <router-view />
      </v-main>
      <Footer />
    </v-app>
    <Login v-else />
  </div>
</template>

<script>
import Menu from "./components/base/menu";
import Footer from "./components/base/footer";
import Login from "./components/base/login";
import { mapState, mapActions } from "vuex";
export default {
  components: { Menu, Footer, Login },
  data: () => ({
    //
  }),
  computed: {
    ...mapState({
      login: state => state.Auth.login
    })
  },
  beforeMount() {
    this.checkUser();
  },
  methods: {
    ...mapActions("Auth", ["checkUser"])
  }
};
</script>
