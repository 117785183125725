var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[(_vm.venta)?_c('v-card-text',[_c('div',{staticClass:"mt-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_vm._v(" Folio: "+_vm._s(_vm._f("folio")(_vm.venta.id))+" "),_c('br'),_vm._v(" Nota: "+_vm._s(_vm.venta.nota)+" "),_c('br'),_vm._v(" Fecha de Elaboración: "+_vm._s(_vm._f("date")(_vm.venta.fecha))+" "),_c('br'),_vm._v(" Cliente: "+_vm._s(_vm.venta.cliente)+" "),_c('br')]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_vm._v(" Total de Kilos: "+_vm._s(_vm._f("decimal")(_vm.venta.kilos))+" "),_c('br'),_vm._v(" Importe: "+_vm._s(_vm._f("moneda")(_vm.venta.importe))+" ")]),(_vm.venta.observaciones)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('span',{staticClass:"negrita"},[_vm._v("Observaciones")]),_vm._v(": "),_c('br'),_vm._v(" "+_vm._s(_vm.venta.observaciones)+" ")]):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',[_vm._v("Producto por Pallet")]),_c('v-data-table',{staticClass:"elevation-1 mt-3",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.items.length,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe))+" ")]}},{key:"item.precio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.precio))+" ")]}},{key:"item.kilos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("decimal")(item.kilos))+" ")]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',[_vm._v("Concentrado")]),_c('v-data-table',{staticClass:"elevation-1 mt-3",attrs:{"dense":"","headers":_vm.headers2,"items":_vm.items2,"items-per-page":_vm.items2.length,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe))+" ")]}},{key:"item.precio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.precio))+" ")]}},{key:"item.kilos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("decimal")(item.kilos))+" ")]}}],null,true)})],1)],1)],1)]):_vm._e(),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.regresar}},[_vm._v("Regresar a ventas")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }