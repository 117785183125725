<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6" class="mb-3">
          <v-card height="100%">
            <v-card-title>Tipo de Cambio Banxico</v-card-title>
            <v-card-text>
              <PrecioDolar />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" class="mb-3">
          <Egresos :mes="mes" :items="egresos" @actualizar="actualizar" :loader="loader" />
        </v-col>
        <v-col cols="12" md="6" class="mb-3">
           <Timbrado />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import PrecioDolar from "./dashboard/PrecioDolar.vue";
import Egresos from "./dashboard/Egresos.vue";
import Timbrado from "./dashboard/Timbrado.vue";
export default {
  name: "Dashboard",
  components: { PrecioDolar, Egresos,Timbrado },
  data: () => ({
    mes: "",
    egresos: [],
    loader: false
  }),
  computed: {
    //
  },
  mounted() {
    this.actualizar();
  },
  methods: {
    actualizar() {
      this.egresos = [];
      this.loader = true;
      window.axios
        .get("Gerencia/dashboard")
        .then(resp => {
          this.mes = resp.data.mes;
          this.egresos = resp.data.egresos;
        })
        .catch(e =>
          window.swal(e.response.data.message, { icon: "error", timer: 2500 })
        )
        .finally(() => (this.loader = false));
    }
  }
};
</script>
