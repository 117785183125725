var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"green lighten-4"}},[_vm._v(" "+_vm._s(_vm.items.length ? _vm.items.length : "")+" Factura"+_vm._s(_vm.items.length > 1 ? "s" : "")+" ")]),_c('v-expansion-panel-content',{staticClass:"mt-3"},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.items,"item-class":_vm.rowClasses,"loading":_vm.loader,"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.date))+" ")]}},{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.total))+" ")]}},{key:"item.tipo_cambio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("monedaSat")(item.tipo_cambio))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status == 'PENDIENTE')?[_c('v-btn',{attrs:{"icon":"","color":"error","disabled":_vm.loader}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]:_vm._e(),_c('v-btn',{attrs:{"icon":"","disabled":_vm.loader,"color":"primary"},on:{"click":function($event){return _vm.getResource(item.id)}}},[_c('v-icon',[_vm._v("mdi-information")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }