var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"mb-2"},[_c('v-btn',{attrs:{"color":"info"},on:{"click":_vm.getAll}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","mobile-breakpoint":"0","hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"item-class":_vm.rowClasses,"loading":_vm.loader},scopedSlots:_vm._u([{key:"item.FechaInicioRelLaboral",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.FechaInicioRelLaboral))+" ")]}},{key:"item.nacimiento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.nacimiento))+" ")]}},{key:"item.SalarioDiarioIntegrado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.SalarioDiarioIntegrado))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status == 'PENDIENTE')?[_c('v-btn',{attrs:{"icon":"","color":"error","disabled":_vm.loader}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]:_vm._e(),_c('v-btn',{attrs:{"icon":"","disabled":_vm.loader,"color":"primary"},on:{"click":function($event){return _vm.getResource(item.id)}}},[_c('v-icon',[_vm._v("mdi-information")])],1)]}}],null,true)}),_c('Paginado',{attrs:{"loader":_vm.loader,"paginado":_vm.paginado}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }