<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-expansion-panels multiple>
        <Busqueda />
        <Seleccionados />
        <Nota v-if="seleccionados && seleccionados.length" />
      </v-expansion-panels>
      <v-card-actions>
        <v-btn color="primary" @click="$router.push({ name: 'ventas' })">
          regresar a ventas
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import Busqueda from "./panelbusuqeda";
import Seleccionados from "./panelSelccionados";
import Nota from "./panelNota";
import { mapState } from "vuex";
export default {
  name: "Nueva",
  components: { Busqueda, Seleccionados, Nota },
  computed: {
    ...mapState({
      seleccionados: state => state.Ventas.seleccionados
    })
  }
};
</script>
