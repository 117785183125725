<!-- eslint-disable -->
<template>
  <v-expansion-panel v-if="resource">
    <v-expansion-panel-header dark color="green lighten-4">
      {{ title }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-form @submit.prevent="sendResource" :disabled="loader">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-combobox
                dense
                outlined
                clearable
                label="Marca"
                v-model="resource.brand"
                :error-messages="errors.brand"
                type="search"
                :items="brands"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                dense
                outlined
                clearable
                label="Modelo"
                v-model="resource.model"
                :error-messages="errors.model"
                type="search"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                dense
                outlined
                clearable
                label="Año"
                v-model="resource.year"
                :error-messages="errors.year"
                type="search"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                dense
                outlined
                clearable
                label="Serie"
                v-model="resource.serie"
                :error-messages="errors.serie"
                type="search"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                dense
                outlined
                clearable
                label="Placas"
                v-model="resource.plates"
                :error-messages="errors.plates"
                type="search"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                dense
                outlined
                clearable
                label="Observaciones"
                v-model="resource.observations"
                :error-messages="errors.observations"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-if="modificado || !$route.params.id">
          <v-btn color="primary" type="submit" :disabled="loader">
            {{ btnTitle }}
          </v-btn>
          <v-btn color="error" @click="cancelar" :disabled="loader">
            cancelar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Resource",
  data: () => ({
    original: {}
  }),
  computed: {
    ...mapState({
      errors: state => state.errors,
      resource: state => state.Vehicles.resource,
      loader: state => state.Vehicles.loader,
      brands: state => state.Vehicles.brands
    }),
    btnTitle() {
      return this.$route.params.id ? "actualizar" : "guardar";
    },
    modificado() {
      return JSON.stringify(this.resource) !== JSON.stringify(this.original);
    },
    title() {
      return this.$route.params.id
        ? this.original.description
        : "Llenar los siguientes datos";
    }
  },
  methods: {
    ...mapActions("Vehicles", ["sendResource", "nuevo", "getResource"]),
    ...mapMutations("Vehicles", ["setResource"]),
    cancelar() {
      this.$store.commit("setErrors");
      if (!this.$route.params.id) this.$router.push({ name: "vehicles" });
      this.setResource(this.original);
    }
  },
  beforeMount() {
    this.$store.commit("setErrors");
    if (!this.resource) {
      if (this.$route.params.id) {
        this.getResource(this.$route.params.id);
      } else {
        this.nuevo();
      }
    }
  },
  mounted() {
    this.original = Object.assign({}, this.resource);
  },
  watch: {
    resource(val) {
      this.original = Object.assign({}, val);
    }
  }
};
</script>
