<!-- eslint-disable -->
<template>
  <v-expansion-panel v-if="resource">
    <v-expansion-panel-header dark color="green lighten-4">
      {{ title }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-form @submit.prevent="sendResource" :disabled="loader">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                dense
                outlined
                clearable
                label="Nombre"
                v-model="resource.nombre"
                :error-messages="errors.nombre"
                type="search"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                dense
                outlined
                clearable
                label="Teléfono"
                v-model="resource.telefono"
                :error-messages="errors.telefono"
                type="search"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                dense
                outlined
                clearable
                label="Correo"
                v-model="resource.email"
                :error-messages="errors.email"
                type="search"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                dense
                outlined
                clearable
                label="Tipo de Comision"
                v-model="resource.type"
                :error-messages="errors.type"
                type="search"
                :items="types"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                dense
                outlined
                clearable
                label="Comisión"
                v-model="resource.comision"
                :error-messages="errors.comision"
                type="number"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                dense
                outlined
                clearable
                label="Domicilio"
                v-model="resource.domicilio"
                :error-messages="errors.domicilio"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-if="modificado || !$route.params.id">
          <v-btn color="primary" type="submit" :disabled="loader">
            {{ btnTitle }}
          </v-btn>
          <v-btn color="error" @click="cancelar" :disabled="loader">
            cancelar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Resource",
  data: () => ({
    original: { nombre: undefined }
  }),
  computed: {
    ...mapState({
      loader: state => state.Comisionistas.loader,
      resource: state => state.Comisionistas.resource,
      types: state => state.Comisionistas.types,
      errors: state => state.errors
    }),
    title() {
      return this.$route.params.id
        ? this.original.nombre
        : "Llenar los siguientes datos";
    },
    modificado() {
      return JSON.stringify(this.original) !== JSON.stringify(this.resource);
    },
    btnTitle() {
      return this.$route.params.id ? "actualizar" : "guardar";
    }
  },
  methods: {
    ...mapActions("Comisionistas", ["sendResource"]),
    ...mapMutations("Comisionistas", ["setResource"]),
    cancelar() {
      if (this.$route.params.id) {
        this.setResource(this.original);
      } else {
        this.$router.push({ name: "comisionistas" });
      }
    }
  },
  beforeMount() {
    this.$store.commit("setErrors");
    this.original = Object.assign({}, this.resource);
  },
  watch: {
    resource(val) {
      this.original = Object.assign({}, val);
    }
  }
};
</script>
