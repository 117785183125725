<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-form @submit.prevent="buscar">
        <v-row>
          <v-col>
            <v-text-field
              outlined
              dense
              type="text"
              label="Pallet"
              v-model="pallet"
            />
          </v-col>
          <v-col>
            <v-btn color="primary" type="submit">buscar</v-btn>
          </v-col>
        </v-row>
      </v-form>

      <v-data-table
        dense
        :headers="headers"
        :items="items"
        class="elevation-1"
        hide-default-footer
      >
      <template v-slot:[`item.fecha_corte`]="{ item }">
        {{ item.fecha_corte | date }}
      </template>
      <template v-slot:[`item.fecha_proceso`]="{ item }">
        {{ item.fecha_proceso | date }}
      </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    pallet: undefined,
    headers: [
      { text: "Producto", value: "producto" },
      { text: "Fecha de Corte", value: "fecha_corte" },
      { text: "Fecha de Proceso", value: "fecha_proceso" },
      { text: "Lote", value: "lote" },
      { text: "Huerta", value: "huerta" },
      { text: "Presentación", value: "presentacion" },
      { text: "Cantidad", value: "cantidad", align: "end" }
    ],
    items: []
  }),
  methods: {
    buscar() {
      this.items = [];
      if (this.pallet) {
        window.axios
          .post("/Gerencia/pallets", { pallet: this.pallet })
          .then(resp => {
            this.items = resp.data;
          });
      }
    }
  }
};
</script>
