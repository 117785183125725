<!-- eslint-disable -->
<template>
  <v-row>
    <v-col cols="12">
      <line-chart
        :chart-data="datacollection"
        :options="options"
        :height="250"
      />
    </v-col>
    <v-col>
      <v-btn color="primary" @click="actualizar">actualizar</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import LineChart from "../LineChart.js";

export default {
  name: "PrecioDolar",
  components: {
    LineChart
  },
  data() {
    return {
      datacollection: {},
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                stepSize: 0.5
              }
            }
          ]
        },
        maintainAspectRatio: false
      },
      minimo: 0
    };
  },
  mounted() {
    this.actualizar();
  },
  methods: {
    actualizar() {
      this.datacollection = {
        datasets: []
      };
      window.axios
        .get("Gerencia/tipoCambio")
        .then(resp => {
          this.datacollection = resp.data.precioDolar;
        })
        .catch(e =>
          window.swal(e.response.data.message, { icon: "error", timer: 2500 })
        );
    }
  }
};
</script>

<style>
.small {
  max-width: 600px;
  margin: 150px auto;
}
</style>
