<!-- eslint-disable -->
<template>
  <v-expansion-panel v-if="items">
    <v-expansion-panel-header color="purple lighten-4">
      Comisionistas
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="my-2">
        <v-col cols="12" md="4">
          <v-autocomplete
            dense
            outlined
            clearable
            label="Filtrar por Comisionista"
            type="search"
            v-model="search"
            :items="comisionistas"
          />
        </v-col>
        <v-col cols="12" md="4" v-if="search">
          <v-autocomplete
            dense
            outlined
            clearable
            label="Forma de Pago"
            type="search"
            v-model="forma"
            :items="formas"
          />
        </v-col>
        <v-col v-if="forma">
          <v-btn color="primary" @click="imprimir">
            <v-icon> mdi-printer </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        :search.sync="search"
        class="elevation-1 mt-1"
        :items-per-page="items.length"
        hide-default-footer
      >
        <template v-slot:[`item.lote`]="{ item }">
          {{ item.lote | folio }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
        <template v-slot:[`item.kilos`]="{ item }">
          {{ item.kilos | numero }}
        </template>
        <template v-slot:[`item.precio`]="{ item }">
          {{ item.precio | moneda }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
import { Printd } from "printd";
export default {
  name: "Comisionistas",
  data: () => ({
    headers: [
      { text: "Fecha", value: "fecha" },
      { text: "Comisionista", value: "nombre" },
      { text: "Tipo Comision", value: "tipo" },
      { text: "Huerta", value: "huerta" },
      { text: "Lote", value: "lote" },
      { text: "Kilos", value: "kilos", align: "end" },
      { text: "Precio por kilo", value: "precio", align: "end" },
      { text: "Importe", value: "importe", align: "end" }
    ],
    search: undefined,
    d: new Printd(),
    forma: undefined,
    formas: [
      { text: "Pago en una sola Exibición", value: "PUE" },
      { text: "Pago en Parcialidades o Diferido", value: "PPD" }
    ]
  }),
  computed: {
    ...mapState({
      items: state => state.Calculadora.comisionistas
    }),
    comisionistas() {
      return [...new Set(this.items.map(x => x.nombre))].sort();
    }
  },
  methods: {
    imprimir() {
      let payload = {
        items: this.items.filter(x => x.nombre == this.search),
        forma: this.forma
      };
      let url = "pagos/printComisionistas";
      window.axios.post(url, payload).then(resp => {
        const el = document.createElement("p");
        el.innerHTML = resp.data;
        this.d.print(el);
      });
    }
  }
};
</script>
