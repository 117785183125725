<!-- eslint-disable -->
<template>
  <v-form @submit.prevent="update">
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field
            outlined
            dense
            type="text"
            label="Nombre"
            v-model="cliente.nombre"
            :error-messages="errors.nombre"
          />
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field
            outlined
            dense
            type="text"
            label="Nombre del Contacto"
            v-model="cliente.contacto"
            :error-messages="errors.contacto"
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            outlined
            dense
            type="email"
            label="Correo"
            v-model="cliente.email"
            :error-messages="errors.email"
          />
        </v-col>
        <v-col cols="12" md="auto">
          <v-text-field
            outlined
            dense
            type="text"
            label="País"
            v-model="cliente.pais"
            :error-messages="errors.pais"
          />
        </v-col>
        <v-col cols="12" md="auto">
          <v-text-field
            outlined
            dense
            type="text"
            label="Teléfono"
            v-model="cliente.telefono"
            :error-messages="errors.telefono"
          />
        </v-col>
        <v-col cols="12" md="auto">
          <v-text-field
            outlined
            dense
            type="number"
            label="Distancia Carta Porte"
            v-model="cliente.distancia"
            :error-messages="errors.distancia"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            outlined
            dense
            type="text"
            label="RFC"
            v-model="cliente.rfc"
            :error-messages="errors.rfc"
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            outlined
            dense
            type="text"
            label="Código Postal"
            v-model="cliente.DomicilioFiscalReceptor"
            :error-messages="errors.DomicilioFiscalReceptor"
          />
        </v-col>
        <v-col cols="12" md="5">
          <v-autocomplete
            outlined
            dense
            type="search"
            label="Régimen Fiscal"
            v-model="cliente.RegimenFiscalReceptor"
            :error-messages="errors.RegimenFiscalReceptor"
            :items="regimenes"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn color="success" type="submit" :disabled="loading">
        {{ title }}
      </v-btn>
      <v-btn color="error" @click="cancelar" v-if="modificado">
        cancelar
      </v-btn>
    </v-card-actions>
  </v-form>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  data: () => ({
    original: {},
    regimenes: [
      { value: "601", text: "General de Ley Personas Morales" },
      {
        value: "610",
        text:
          "Residentes en el Extranjero sin Establecimiento Permanente en México"
      },
      {
        value: "612",
        text: "Personas Físicas con Actividades Empresariales y Profesionales"
      },
      { value: "616", text: "Sin obligaciones fiscales" },
      {
        value: "620",
        text:
          "Sociedades Cooperativas de Producción que optan por diferir sus ingresos"
      },
      { value: "621", text: "Incorporación Fiscal" },
      { value: "626", text: "Régimen Simplificado de Confianza" }
    ]
  }),
  computed: {
    ...mapState({
      cliente: state => state.Clientes.resource,
      errors: state => state.errors,
      loading: state => state.Clientes.loader
    }),
    modificado: function() {
      return JSON.stringify(this.cliente) !== JSON.stringify(this.original);
    },
    title() {
      return this.cliente.id ? "actualizar" : "guardar";
    }
  },
  mounted() {
    this.original = Object.assign({}, this.cliente);
  },
  watch: {
    cliente: function(val) {
      this.original = Object.assign({}, val);
    }
  },
  beforeDestroy() {
    this.setErrors([]);
  },
  methods: {
    ...mapMutations(["setErrors"]),
    ...mapMutations("Clientes", ["setResource"]),
    ...mapActions("Clientes", ["update"]),
    cancelar() {
      this.setResource(this.original);
      this.setErrors([]);
    }
  }
};
</script>
