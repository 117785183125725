<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-title>Módulo de Timbrado</v-card-title>
    <v-card-text v-if="resultado">
      <v-row>
        <v-col
          >Modo de Timbrado: <strong>{{ resultado.Modo }}</strong></v-col
        >
        <v-col
          >Rfc: <strong>{{ resultado.RFC }}</strong></v-col
        >
        <v-col>
          Certificado Válido hasta:
          <strong>{{ resultado.Valid_To | dateTime }}</strong>
        </v-col>
        <v-col
          >Paquete: <strong>{{ resultado.Timbres }} Timbres</strong></v-col
        >
        <v-col
          >Fecha de Compra:
          <strong>{{ resultado.Fecha_Activacion | date }}</strong></v-col
        >
        <v-col
          >Vigentes al :
          <strong>{{ resultado.Fecha_Vigencia | date }}</strong></v-col
        >
        <v-col
          >Timbres Disponibles:
          <strong>{{ resultado.Disponibles }}</strong></v-col
        >
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="actualizar">actualizar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Timbrado",
  data: () => ({
    resultado: undefined
  }),
  methods: {
    actualizar() {
      this.resultado = undefined;
      window.axios
        .get("Gerencia/xtimbra")
        .then(resp => {
          this.resultado = resp.data;
        })
        .catch(e =>
          window.swal(e.response.data.message, { icon: "error", timer: 2500 })
        );
    }
  },
  beforeMount() {
    this.actualizar();
  }
};
</script>
