<!-- eslint-disable -->
<template>
  <v-expansion-panel v-if="cortes">
    <v-expansion-panel-header color="green lighten-4">
      Transportistas
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="mt-2">
        <v-col cols="12" xl="4">
          <v-text-field
            outlined
            dense
            v-model="search"
            append-icon="mdi-magnify"
            label="Busqueda"
            single-line
            hide-details
          />
        </v-col>
        <v-col>
          <v-btn color="primary" @click="print">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        dense
        class="elevation-1 mt-3"
        :search.sync="search"
        :headers="headers"
        :items="items"
        hide-default-footer
        :items-per-page="items.length"
      >
        <template v-slot:[`item.lote`]="{ item }">
          {{ item.lote | folio }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
        <template v-slot:[`item.importe_transportista`]="{ item }">
          {{ item.importe_transportista | moneda }}
        </template>
        <template v-slot:[`item.iva_transportista`]="{ item }">
          {{ item.iva_transportista | moneda }}
        </template>
        <template v-slot:[`item.total_transportista`]="{ item }">
          {{ item.total_transportista | moneda }}
        </template>
        <template v-slot:[`item.retencion_iva_transportista`]="{ item }">
          {{ item.retencion_iva_transportista | moneda }}
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
import { Printd } from "printd";
export default {
  name: "Transportistas",
  data: () => ({
    headers: [
      { text: "Fecha", value: "fecha" },
      { text: "Lote", value: "lote" },
      { text: "Productor", value: "productor" },
      { text: "Huerta", value: "huerta" },
      { text: "Municipio", value: "municipio" },
      { text: "Importe", value: "importe_transportista", align: "end" },
      { text: "IVA", value: "iva_transportista", align: "end" },
      {
        text: "Retención IVA",
        value: "retencion_iva_transportista",
        align: "end"
      },
      { text: "Total", value: "total_transportista", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    search: undefined,
    d: new Printd()
  }),
  computed: {
    ...mapState({
      cortes: state => state.Calculadora.cortes
    }),
    items() {
      return this.cortes.filter(x => x.transportista);
    },
    arrayTransportistas() {
      return [...new Set(this.cortes.map(x => x.transportista))];
    }
  },
  methods: {
    print() {
      let url = "pagos/printTransportes";
      window.axios.post(url, { items: this.items }).then(resp => {
        const el = document.createElement("p");
        el.innerHTML = resp.data;
        this.d.print(el);
      });
    }
  }
};
</script>
