import router from "../../router";
const url = "Gerencia/invoices";
const initialState = () => ({
  headers: [],
  collection: undefined,
  resource: undefined,
  loader: false,
  paginado: { currentPage: 1 },
  xml: undefined,
  customer: undefined,
  pagos: undefined,
  notas: undefined,
  ventas: [],
  formasPago: [],
  usoCfdi: [],
  MetodosPago: []
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    setLoader(state, payload = true) {
      state.loader = payload;
    },
    setCollection(state, payload = undefined) {
      state.collection = payload;
    },
    setPaginado(state, payload) {
      state.paginado = payload;
    },
    setHeaders(state, payload) {
      state.headers = payload;
    },
    setResource(state, payload) {
      state.resource = payload;
    },
    setXml(state, payload) {
      state.xml = payload;
    },
    setCustomer(state, payload) {
      state.customer = payload;
    },
    setPagos(state, payload) {
      state.pagos = payload;
    },
    setNotas(state, payload) {
      state.notas = payload;
    },
    setVentas(state, payload = []) {
      state.ventas = payload;
    },
    setFormasPago(state, payload) {
      state.formasPago = payload;
    },
    setUsoCfdi(state, payload) {
      state.usoCfdi = payload;
    },
    setMetodosPago(state, payload) {
      state.MetodosPago = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch, state }, serie) {
      commit("setLoader");
      commit("setCollection");
      if (serie !== state.paginado.serie) {
        state.paginado.inicio = state.paginado.fin = state.paginado.pesos = state.paginado.customer_id = null;
        state.paginado.currentPage = 1;
      }
      window.axios
        .get(url, {
          params: {
            page: state.paginado.currentPage,
            search: state.paginado.search,
            serie: serie,
            inicio: state.paginado.inicio,
            fin: state.paginado.fin,
            customer_id: state.paginado.customer_id
          }
        })
        .then(resp => {
          commit("setPaginado", resp.data.paginado);
          commit("setCollection", resp.data.collection);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async getResource({ commit, dispatch }, id) {
      commit("setLoader");
      await window.axios
        .get(`${url}/show/${id}`)
        .then(resp => {
          commit("setResource", resp.data.resource);
          commit("setXml", resp.data.xml);
          commit("setCustomer", resp.data.customer);
          commit("setPagos", resp.data.pagos);
          commit("setNotas", resp.data.notas);
          if (resp.data.resource.Serie.includes("EX")) {
            router
              .push({ name: "exportacion.show", params: { id } })
              .catch(() => {});
          } else {
            router
              .push({ name: "nacionales.show", params: { id } })
              .catch(() => {});
          }
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async createNacional({ commit, dispatch, state }) {
      commit("setLoader");
      commit("setVentas");
      await window.axios
        .get(`${url}/createNacional`)
        .then(resp => {
          commit("setVentas", resp.data.ventas);
          commit("setFormasPago", resp.data.FormaPago);
          commit("setUsoCfdi", resp.data.UsoCFDI);
          commit("setMetodosPago", resp.data.MetodosPago);
          if (state.ventas.length) {
            router.push({ name: "nacionales.create" }).catch(() => {});
          } else {
            dispatch("message", "No hay ventas que facturar", { root: true });
          }
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async makeNacional({ commit, dispatch }, data) {
      commit("setLoader");
      commit("setErrors", {}, { root: true });
      await window.axios
        .post(`${url}/createNacional`, data)
        .then(resp => {
          dispatch("message", resp.data.message, { root: true });
          router.push({ name: "nacionales" }).catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    }
  }
};
