const url = "Gerencia/inventario";
const initialState = () => ({
  detalles: undefined
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    SET_DETALLES(state, payload) {
      state.detalles = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch }) {
      commit("SET_DETALLES", undefined);
      window.axios
        .get(url)
        .then(resp => {
          commit("SET_DETALLES", resp.data);
        })
        .catch(e => dispatch("errores", e, { root: true }));
    }
  }
};
