<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Conceptos
    </v-expansion-panel-header>
    <v-expansion-panel-content class="mt-3">
      <v-data-table
        dense
        mobile-breakpoint="0"
        :headers="headers"
        :items="conceptos"
        class="elevation-1"
      >
        <template v-slot:[`item.Importe`]="{ item }">
          {{ item.Importe | moneda }}
        </template>
        <template v-slot:[`item.ValorUnitario`]="{ item }">
          {{ item.ValorUnitario | moneda }}
        </template>
        <template v-slot:[`item.Cantidad`]="{ item }">
          {{ item.Cantidad | numero }}
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Conceptos",
  computed: {
    ...mapState({
      conceptos: state => state.Invoices.xml.Conceptos,
      traslados: state => state.Invoices.xml.Traslados
    })
  },
  data: () => ({
    headers: [
      { text: "Descripción", value: "Descripcion" },
      { text: "NoIdentificacion", value: "NoIdentificacion" },
      { text: "Clave del Sat", value: "ClaveProdServ" },
      { text: "Unidad", value: "Unidad" },
      { text: "Cantidad", value: "Cantidad", align: "end" },
      { text: "Valor Unitario", value: "ValorUnitario", align: "end" },
      { text: "Importe", value: "Importe", align: "end" }
    ]
  })
};
</script>
