import router from "../../router";
const url = "/Gerencia/embarques";
const initialState = () => ({
  headers: [
    { text: "N° Embarque", value: "id", width: "5%" },
    { text: "Fecha", value: "fecha", width: "5%" },
    { text: "Factura", value: "factura", width: "5%" },
    { text: "Cliente", value: "cliente", width: "50%" },
    { text: "Pallets", value: "pallets", align: "end" },
    { text: "Cajas", value: "cajas", align: "end" },
    { text: "Archivos", value: "archivos", align: "end" },
    { text: "Acciones", value: "actions", align: "end" }
  ],
  collection: undefined,
  resource: undefined,
  loader: false,
  files: undefined,
  detalles: undefined,
  paginado: { currentPage: 1 },
  conceptos: undefined,
  supplies: undefined,
  articulos: undefined
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setArticulos(state, payload) {
      state.articulos = payload;
    },
    setSupplies(state, payload) {
      state.supplies = payload;
    },
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    SET_HEADERS(state, payload) {
      state.headers = payload;
    },
    setCollection(state, payload) {
      state.collection = payload;
    },
    setResource(state, payload) {
      state.resource = payload;
    },
    setLoader(state, payload = true) {
      state.loader = payload;
    },
    SET_FILES(state, payload) {
      state.files = payload;
    },
    SET_DETALLES(state, payload) {
      state.detalles = payload;
    },
    setPaginado(state, payload) {
      state.paginado = payload;
    },
    setConceptos(state, payload = undefined) {
      state.conceptos = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch, state }) {
      commit("setCollection", []);
      commit("setLoader", true);
      window.axios
        .get(url, {
          params: {
            page: state.paginado.currentPage,
            inicio: state.paginado.inicio,
            fin: state.paginado.fin
          }
        })
        .then(resp => {
          commit("setCollection", resp.data.collection);
          commit("setPaginado", resp.data.paginado);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async getEmbarque({ commit, dispatch }, id) {
      commit("setErrors", [], { root: true });
      commit("setResource", undefined);
      commit("SET_FILES", undefined);
      commit("SET_DETALLES", undefined);
      window.axios
        .get(`${url}/${id}`)
        .then(resp => {
          commit("setResource", resp.data.resource);
          commit("SET_FILES", resp.data.files);
          commit("SET_DETALLES", resp.data.detalles);
          router
            .push({ name: "embarque.details", params: { id } })
            .catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }));
    },
    async facturarEmbarque({ commit, dispatch }, id) {
      commit("setLoader");
      commit("setConceptos");
      window.axios
        .get(`${url}/facturar/${id}`)
        .then(resp => {
          commit("setConceptos", resp.data.collection);
          commit("setResource", resp.data.resource);
          commit("setSupplies", resp.data.supplies);
          commit("setArticulos", resp.data.articulos);
          router
            .push({ name: "embarque.facturar", params: { id } })
            .catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async mandarDatosFactura({ commit, dispatch, state }) {
      let conceptos = state.conceptos;
      commit("setLoader");
      await window.axios
        .post(`${url}/makeFactura/${state.resource.id}`, { conceptos })
        .then(resp => {
          dispatch("message", resp.data.message, { root: true });
          router.push({ name: "exportacion" });
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async eliminarArticulo({ commit, dispatch }, id) {
      commit("setLoader");
      await window.axios
        .delete(`${url}/removeArticulo/${id}`)
        .then(resp => {
          commit("setArticulos", resp.data.articulos);
          dispatch("message", resp.data.message, { root: true });
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    }
  }
};
