import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import logout from "./../components/base/logout.vue";
import Users from "./../views/users/index.vue";
import UserForm from "./../views/users/form.vue";
import Clientes from "./../views/clientes/index.vue";
import Cliente from "./../views/clientes/detalles.vue";
import Inventario from "./../views/inventario.vue";

import Exportacion from "./../views/exportacion/index.vue";

import RoutesEmbarques from "./embarques";
import RoutesVentas from "./ventas";
import RoutesPallets from "./pallets";
import RoutesLotes from "./lotes";
import Calculadora from "./calculadora";
import Pagos from "./../views/Pagos.vue";
import Egresos from "./egresos";
import Employes from "./employes";
import Providers from "./providers";
import Payments from "./payments";
import Nacionales from "./nacionales";
import Supplies from "./supplies";
import Vehicles from "./vehicles";
import Comisionistas from "./comisionistas";

Vue.use(VueRouter);

const routes = [
  ...Payments,
  ...RoutesEmbarques,
  ...RoutesVentas,
  ...RoutesPallets,
  ...RoutesLotes,
  ...Calculadora,
  ...Egresos,
  ...Employes,
  ...Providers,
  ...Nacionales,
  ...Supplies,
  ...Vehicles,
  ...Comisionistas,
  {
    path: "/Precios",
    name: "prices",
    component: require("@/views/prices/Index.vue").default,
    meta: {
      name: "Lista de Precios"
    }
  },
  {
    path: "/Pagos",
    name: "pagos",
    component: Pagos,
    meta: {
      name: "Historial de Pagos"
    }
  },
  {
    path: "/Facturas-Exportacion",
    name: "exportacion",
    component: Exportacion,
    meta: {
      name: "Control de Facturas Exportacion"
    }
  },
  {
    path: "/Facturas-Exportacion/detalles/:id",
    name: "exportacion.show",
    component: require("@/views/invoices/Show.vue").default,
    meta: {
      name: "Detalles de Factura"
    }
  },

  {
    path: "/Facturas-Nacionales/detalles/:id",
    name: "nacionales.show",
    component: require("@/views/invoices/Show.vue").default,
    meta: {
      name: "Detalles de Factura"
    }
  },

  {
    path: "/Inventario",
    name: "inventario",
    component: Inventario,
    meta: {
      name: "Control de Inventario"
    }
  },
  {
    path: "/Clientes/editar/:id",
    name: "cliente.edit",
    component: Cliente,
    meta: {
      name: "Detalles Cliente"
    }
  },
  {
    path: "/Clientes/nuevo",
    name: "cliente.create",
    component: Cliente,
    meta: {
      name: "Nuevo Cliente"
    }
  },
  {
    path: "/Clientes",
    name: "clientes",
    component: Clientes,
    meta: {
      name: "Control de Clientes"
    }
  },
  {
    path: "/Usuarios",
    name: "users",
    component: Users,
    meta: {
      name: "Control de Usuarios"
    }
  },
  {
    path: "/Usuarios/editar/:id",
    name: "users.edit",
    component: UserForm,
    meta: {
      name: "Editando Usuario"
    }
  },
  {
    path: "/Salir",
    name: "logout",
    component: logout
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      name: "Dashboard"
    }
  },
  {
    path: "/Inicio",
    name: "inicio",
    component: Home,
    meta: {
      name: "Dashboard"
    }
  },
  {
    path: "/Upload-xml",
    name: "uploadXml",
    component: require("@/components/Pruebas.vue").default,
    meta: {
      name: "Procesar archivos xml"
    }
  },
  {
    path: "/Graficos",
    name: "charts",
    component: require("@/views/graficos/Index.vue").default,
    meta: {
      name: "Graficos comparativos por año"
    }
  },
  {
    path: "/editar-mis-datos",
    name: "editMe",
    component: require("@/views/EditMe.vue").default,
    meta: {
      name: "Editar mis datos"
    }
  }
];

const router = new VueRouter({
  routes,
  linkExactActiveClass: "nav-item active",
  mode: "history"
});
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});
export default router;
