<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Pagos
    </v-expansion-panel-header>
    <v-expansion-panel-content class="mt-3">
       <v-data-table
        dense
        mobile-breakpoint="0"
        hide-default-footer
        :headers="headers"
        :items="items"
        class="elevation-1 mt-3"
        :item-class="rowClasses"
        :items-per-page="items.length"
      >
        <template v-slot:[`item.date`]="{ item }">
          {{ item.date | date }}
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          {{ item.amount | moneda }}
        </template>
        <template v-slot:[`item.tipo_cambio`]="{ item }">
          {{ item.tipo_cambio | monedaSat }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="error" :disabled="loader" @click="eliminar(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
     
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "Pagos",
  data:()=>({
    headers: [
      { text: "Fecha", value: "date" },
      { text: "Factura", value: "factura" },
      { text: "Cuenta", value: "cuenta" },
      { text: "Referencia", value: "referencia" },
      { text: "Importe del Pago", value: "amount", align: "end" },
      { text: "Moneda", value: "moneda" },
      { text: "Tipo de Cambio", value: "tipo_cambio", align: "end" },
      { text: "Importe en Pesos", value: "importe", align: "end" }
    ],
  }),
  computed:{
    ...mapState({
      items: state=>state.Providers.pagos
    })
  }
};
</script>