<!-- eslint-disable -->
<template>
  <v-expansion-panel v-if="original.nombre">
    <v-expansion-panel-header color="green lighten-4">
      Datos del cliente {{ original.nombre }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card class="mt-3">
        <Formulario />
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
import Formulario from "./formulario";
export default {
  name: "Datos",
  components: {
    Formulario
  },
  computed: {
    ...mapState({
      original: state => state.Clientes.resource
    })
  },
  methods: {
    //
  }
};
</script>
