<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-form :disabled="loader" @submit.prevent="getAll">
        <v-row>
          <v-col>
            <v-text-field
              dense
              outlined
              clearable
              label="Buscar por Nombre o RFC"
              v-model="paginado.search"
            />
          </v-col>
          <v-col>
            <v-btn color="info" type="submit" :disabled="loader">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-data-table
        dense
        mobile-breakpoint="0"
        hide-default-footer
        :headers="headers"
        :items="items"
        :items-per-page="paginado.perPage"
        class="elevation-1"
        :item-class="rowClasses"
        :loading="loader"
      >
        <template v-slot:[`item.FechaInicioRelLaboral`]="{ item }">
          {{ item.FechaInicioRelLaboral | date }}
        </template>
        <template v-slot:[`item.nacimiento`]="{ item }">
          {{ item.nacimiento | date }}
        </template>
        <template v-slot:[`item.SalarioDiarioIntegrado`]="{ item }">
          {{ item.SalarioDiarioIntegrado | moneda }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <template v-if="item.status == 'PENDIENTE'">
            <v-btn icon color="error" :disabled="loader">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <v-btn
            icon
            :disabled="loader"
            color="primary"
            @click="getResource(item.id)"
          >
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <Paginado :loader="loader" :paginado="paginado" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Index",
  computed: {
    ...mapState({
      items: state => state.Providers.collection,
      loader: state => state.Providers.loader,
      headers: state => state.Providers.headers,
      paginado: state => state.Providers.paginado
    }),
    actual() {
      return this.paginado.currentPage;
    }
  },
  methods: {
    ...mapActions("Providers", ["getAll", "getResource"])
  },
  mounted() {
    this.getAll();
  },
  watch: {
    actual() {
      this.getAll();
    }
  }
};
</script>
