<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4">
          <v-autocomplete
            dense
            outlined
            clearable
            label="Seleccionar Cliente"
            :items="customers"
            v-model="customer"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="5">
          <v-card height="100%">
            <v-card-title>
              Facturas Pendientes
            </v-card-title>
            <v-card-text>
              <v-data-table
                dense
                mobile-breakpoint="0"
                :headers="headers"
                :items="items"
                class="elevation-1 mt-2"
                hide-default-footer
                :items-per-page="items.length"
              >
                <template v-slot:[`item.Fecha`]="{ item }">
                  {{ item.Fecha | date }}
                </template>
                <template v-slot:[`item.Total`]="{ item }">
                  {{ item.Total | moneda }} {{ item.Moneda }}
                </template>
                <template v-slot:[`item.pendiente`]="{ item }">
                  {{ item.pendiente | moneda }}
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>
              Importe Pendiente:
              <strong>{{ importePendiente | moneda }}</strong>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col>
          <v-card height="100%">
            <v-card-title primary-title>
              Abonar
            </v-card-title>
            <v-card-text>
              <v-form @submit.prevent="agregar">
                <v-row>
                  <v-col>
                    <v-autocomplete
                      dense
                      outlined
                      clearable
                      label="Seleccione Factura"
                      v-model="factura_id"
                      :items="posibles"
                      item-value="id"
                      item-text="factura"
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      dense
                      outlined
                      clearable
                      label="Importe a Abonar"
                      v-model.number="abonar"
                      type="number"
                      step="0.01"
                      hide-details="auto"
                      :error-messages="errors.importe"
                    />
                  </v-col>
                  <v-col>
                    <v-btn color="success" type="submit">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>

              <v-data-table
                dense
                mobile-breakpoint="0"
                :headers="headers2"
                :items="listos"
                class="elevation-1 mt-2"
                hide-default-footer
                :items-per-page="listos.length"
                v-if="listos.length"
              >
                <template v-slot:[`item.abonar`]="{ item }">
                  {{ item.abonar | moneda }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn icon color="error" @click="eliminar(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <template v-slot:[`item.saldo`]="{ item }">
                  {{ (item.pendiente - item.abonar) | moneda }}
                </template>
              </v-data-table>
              <v-row v-if="listos.length" class="mt-4">
                <v-col cols="auto">
                  <v-text-field
                    dense
                    outlined
                    clearable
                    label="Fecha del Pago"
                    v-model="date"
                    hide-details="auto"
                    type="date"
                    :error-messages="errors.date"
                  />
                </v-col>
                <v-col cols="auto">
                  <v-text-field
                    dense
                    outlined
                    clearable
                    label="Total a Abonar"
                    v-model="importeAbonar"
                    hide-details="auto"
                    disabled
                  />
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions v-if="listos.length">
              <v-btn color="success" :disabled="loader" @click="enviar">
                Guardar
              </v-btn>
              <v-btn color="error" @click="$router.push({ name: 'payments' })">
                cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="primary"
        @click="$router.push({ name: 'payments' })"
        v-if="!listos.length"
      >
        regresar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Crear",
  data: () => ({
    headers: [
      { text: "Fecha", value: "Fecha" },
      { text: "Factura", value: "factura" },
      { text: "Importe Pendiente", value: "pendiente", align: "end" }
    ],
    customer: undefined,
    factura_id: undefined,
    abonar: undefined,
    headers2: [
      { text: "Factura", value: "factura" },
      { text: "Importe ", value: "abonar", align: "end" },
      { text: "Saldo ", value: "saldo", align: "end" },
      { text: "Acciones ", value: "actions", align: "end" }
    ],

    date: undefined
  }),
  computed: {
    ...mapState({
      pendientes: state => state.Payments.pendientes,
      loader: state => state.Payments.loader,
      errors: state => state.errors
    }),
    customers() {
      return this.pendientes
        ? [...new Set(this.pendientes.map(x => x.customer))].sort()
        : [];
    },
    items() {
      return this.pendientes
        ? this.pendientes.filter(x => x.customer == this.customer)
        : [];
    },
    importePendiente() {
      return window._.sumBy(this.items, "pendiente");
    },
    listos() {
      return this.pendientes ? this.pendientes.filter(x => x.abonar) : [];
    },
    posibles() {
      return this.items.filter(x => !x.abonar);
    },
    importeAbonar() {
      return window._.sumBy(this.items, "abonar");
    }
  },
  methods: {
    ...mapActions("Payments", ["newResource"]),
    ...mapMutations(["setLoader", "setErrors"]),
    agregar() {
      this.errors.importe = undefined;
      let seleccionado = this.pendientes.find(x => x.id == this.factura_id);
      if (this.abonar <= seleccionado.pendiente) {
        seleccionado.abonar = this.abonar;
        this.factura_id = this.abonar = undefined;
      } else {
        this.errors.importe = [`Máximo ${seleccionado.pendiente}`];
      }
    },
    eliminar(item) {
      item.abonar = null;
    },
    enviar() {
      this.$swal({
        title: `¿Deseas hacer el complemento de pago?`,
        text: `Importe ${this.$options.filters.moneda(this.importeAbonar)}`,
        showCancelButton: true
      }).then(result => {
        if (result.isConfirmed) {
          this.setLoader();
          this.setErrors();
          window.axios
            .post("Gerencia/storePago", {
              listos: this.listos,
              customer_id: this.listos[0].customer_id,
              date: this.date
            })
            .then(resp => {
              this.$store.dispatch("message", resp.data.message);
              //this.$router.push({ name: "payments" });
            })
            .catch(e => this.$store.dispatch("errores", e))
            .finally(() => this.$store.commit("Payments/setLoader", false));
        }
      });
    }
  },
  beforeMount() {
    if (!this.pendientes) this.newResource();
    this.setErrors();
  },
  watch: {
    customer() {
      this.pendientes.forEach(x => (x.abonar = null));
    },
    factura_id() {
      this.errors.importe = this.abonar = undefined;
    }
  }
};
</script>
