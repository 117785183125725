<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Datos de la factura
    </v-expansion-panel-header>
    <v-expansion-panel-content class="mt-3">
      <v-row>
        <v-col>
          Fecha: <strong>{{ comprobante.Fecha | dateTime }}</strong>
        </v-col>
        <v-col>
          Serie: <strong>{{ comprobante.Serie }}</strong>
        </v-col>
        <v-col>
          Folio: <strong>{{ comprobante.Folio }}</strong>
        </v-col>
        <v-col>
          Método de Pago: <strong>{{ comprobante.MetodoPago }}</strong>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          Moneda: <strong>{{ comprobante.Moneda }}</strong>
        </v-col>
        <v-col v-if="comprobante.TipoCambio">
          Tipo de Cambio:
          <strong>{{ comprobante.TipoCambio | monedaSat }}</strong>
        </v-col>
        <v-col>
          Subtotal: <strong>{{ comprobante.SubTotal | moneda }}</strong>
        </v-col>
        <v-col v-if="comprobante.Descuento">
          Descuento:
          <strong>{{ comprobante.Descuento | moneda }}</strong>
        </v-col>
        <v-col>
          Total:
          <strong>{{ comprobante.Total | moneda }}</strong>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          Folio Sat: <strong>{{ UUID }}</strong>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Invoice",
  computed: {
    ...mapState({
      comprobante: state => state.Invoices.xml.Comprobante,
      UUID: state => state.Invoices.xml.Complemento.TimbreFiscalDigital.UUID
    })
  }
};
</script>
