var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"green lighten-4"}},[_vm._v(" "+_vm._s(_vm.paginado.registros)+" Pagos Registrados ")]),(_vm.detalle)?_c('v-expansion-panel-content',{staticClass:"mt-3"},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Detalle del Pago del día "+_vm._s(_vm._f("date")(_vm.fecha))+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('h3',[_vm._v("Percepciones")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","mobile-breakpoint":"0","headers":_vm.headers2,"items":_vm.percepciones,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.ImporteGravado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.ImporteGravado))+" ")]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('h3',{staticClass:"mt-2"},[_vm._v("Otros Pagos")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","mobile-breakpoint":"0","headers":_vm.headers3,"items":_vm.otros,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe))+" ")]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('h3',{staticClass:"mt-2"},[_vm._v("Deducciones")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","mobile-breakpoint":"0","headers":_vm.headers3,"items":_vm.deducciones,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe))+" ")]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.cancelar}},[_vm._v(" regresar a pagos ")])],1)],1)],1):_c('v-expansion-panel-content',{staticClass:"mt-3"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.items,"loading":_vm.loader,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.FechaPago",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.FechaPago))+" ")]}},{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe))+" ")]}},{key:"item.percepciones",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.percepciones))+" ")]}},{key:"item.TotalDeducciones",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.TotalDeducciones))+" ")]}},{key:"item.periodo",fn:function(ref){
var item = ref.item;
return [_vm._v(" del "+_vm._s(_vm._f("date")(item.FechaInicialPago))+" al "+_vm._s(_vm._f("date")(item.FechaFinalPago))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","disabled":_vm.loader,"color":"primary"},on:{"click":function($event){return _vm.mostrar(item)}}},[_c('v-icon',[_vm._v("mdi-information")])],1),_c('v-btn',{attrs:{"icon":"","disabled":_vm.loader,"color":"secondary"},on:{"click":function($event){return _vm.print(item)}}},[_c('v-icon',[_vm._v("mdi-printer")])],1)]}}],null,true)}),_c('Paginado',{attrs:{"loader":_vm.loader,"paginado":_vm.paginado}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }