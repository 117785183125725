var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"green lighten-4"}},[_vm._v(" Facturas Relacionadas ")]),_c('v-expansion-panel-content',{staticClass:"mt-3"},[_c('v-data-table',{staticClass:"elevation-1 mt-3",attrs:{"dense":"","mobile-breakpoint":"0","hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"item-class":_vm.rowClasses,"loading":_vm.loader,"items-per-page":_vm.items.length},scopedSlots:_vm._u([{key:"item.Fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.Fecha))+" ")]}},{key:"item.ImpSaldoAnt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.ImpSaldoAnt))+" ")]}},{key:"item.ImpPagado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.ImpPagado))+" ")]}},{key:"item.ImpSaldoInsoluto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.ImpSaldoInsoluto))+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }