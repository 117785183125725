<!-- eslint-disable -->
<template>
  <div>
    <v-form @submit.prevent="updateMe" :disabled="loader">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4" xl="auto">
            <v-text-field
              dense
              outlined
              clearable
              label="Nombre"
              v-model="resource.nombre"
              :error-messages="errors.nombre"
            />
          </v-col>
          <v-col cols="12" md="4" xl="auto">
            <v-text-field
              dense
              outlined
              clearable
              label="Apellidos"
              v-model="resource.apellidos"
              :error-messages="errors.apellidos"
            />
          </v-col>
          <v-col cols="12" md="4" xl="auto">
            <v-text-field
              dense
              outlined
              clearable
              label="Correo"
              v-model="resource.email"
              :error-messages="errors.email"
            />
          </v-col>
          <v-col cols="12" md="4" xl="auto">
            <v-text-field
              dense
              outlined
              clearable
              label="Teléfono"
              v-model="resource.telefono"
              :error-messages="errors.telefono"
            />
          </v-col>

          <v-col cols="12" md="4" xl="auto">
            <v-layout justify-center align-center>
              <v-switch v-model="cambioPwd" label="Cambiar Password" />
            </v-layout>
          </v-col>
        </v-row>
        <v-row v-if="cambioPwd">
          <v-col cols="12" md="4" xl="auto">
            <v-text-field
              outlined
              dense
              clearable
              label="Nueva Contraseña"
              v-model="resource.newPassword"
              :error-messages="errors.newPassword"
              :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPwd = !showPwd"
              :type="showPwd ? 'text' : 'password'"
              autocomplete="new-password"
            />
          </v-col>
          <v-col cols="12" md="4" xl="auto">
            <v-text-field
                outlined
                dense
                clearable
                label="Confirmar Contraseña"
                v-model="resource.newPassword_confirmation"
                :error-messages="errors.newPassword"
                :type="showPwd ? 'text' : 'password'"
                autocomplete="new-password"
              />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" type="submit" :disabled="loader || !modificado">
          actualizar
        </v-btn>
        <v-btn color="error" @click="$router.go(-1)">
          cancelar
        </v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Editar",
  data: () => ({
    original: {},
    cambioPwd: false,
    showPwd: false
  }),
  computed: {
    ...mapState({
      resource: state => state.Auth.resource,
      loader: state => state.Auth.loader,
      errors: state => state.errors
    }),
    modificado() {
      return JSON.stringify(this.original) !== JSON.stringify(this.resource);
    }
  },
  methods: {
    ...mapActions("Auth", ["updateMe"])
  },
  mounted() {
    this.original = Object.assign({}, this.resource);
  },
  watch: {
    resource(val) {
      this.original = Object.assign({}, val);
    }
  }
};
</script>
