<!-- eslint-disable -->
<template>
  <v-expansion-panel-content>
    <v-form :disabled="loader" @submit.prevent="enviar">
      <v-row class="mt-3">
        <v-col cols="12" md="auto">
          <v-text-field
            dense
            outlined
            clearable
            label="Fecha"
            type="date"
            v-model="resource.date"
            :error-messages="errors.date"
          />
        </v-col>
        <v-col cols="12" md="auto">
          <v-text-field
            dense
            outlined
            clearable
            label="Kilometraje"
            type="number"
            v-model="resource.odometer"
            :error-messages="errors.odometer"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            dense
            outlined
            clearable
            label="Descripción"
            v-model="resource.description"
            :error-messages="errors.description"
          />
        </v-col>
      </v-row>
      <v-card-actions>
        <v-btn :disabled="loader" color="success" type="submit">
          {{ title }}
        </v-btn>
        <v-btn :disabled="loader" color="error" @click="$emit('cancelar')">
          cancelar
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-expansion-panel-content>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Formulario",
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  emits: ["cancelar", "actualizar"],
  computed: {
    ...mapState({
      loader: state => state.loader,
      errors: state => state.errors
    }),
    title() {
      return this.resource.id ? "actualizar" : "guardar";
    }
  },
  methods: {
    ...mapMutations(["setLoader", "setErrors"]),
    ...mapActions(["errores", "message"]),
    enviar() {
      this.setLoader();
      this.setErrors();
      if (this.resource.id) {
        let url = `Gerencia/vehicles/maintenances/${this.resource.id}`;
        window.axios
          .put(url, this.resource)
          .then(resp => {
            this.message(resp.data.message);
            this.$emit("actualizar");
          })
          .catch(e => this.errores(e))
          .finally(() => this.setLoader(false));
      } else {
        let url = `Gerencia/vehicles/maintenances/${this.$route.params.id}`;
        window.axios
          .post(url, this.resource)
          .then(resp => {
            this.message(resp.data.message);
            this.$emit("actualizar");
          })
          .catch(e => this.errores(e))
          .finally(() => this.setLoader(false));
      }
    }
  },
  beforeMount() {
    this.setErrors();
  }
};
</script>
