<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-expansion-panels multiple v-if="resource">
      <PanelVenta />
      <PanelDetalles />
    </v-expansion-panels>
    <v-card-actions>
      <v-btn color="primary" @click="$router.push({ name: 'ventas' })">
        regresar a ventas
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PanelVenta from "./edit/PanelVenta.vue";
import PanelDetalles from "./edit/PanelDetalles.vue";
export default {
  name: "Edit",
  components: { PanelVenta, PanelDetalles },
  computed: {
    ...mapState({
      resource: state => state.Ventas.resource,
      details: state => state.Ventas.details
    })
  },
  methods: {
    ...mapActions("Ventas", ["editVenta"])
  },
  mounted() {
    if (!this.resource) {
      this.editVenta(this.$route.params.id);
    }
  }
};
</script>
