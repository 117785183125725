var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[(_vm.items)?_c('v-card-text',[(_vm.productos.length)?_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.actualizar($event)}}},[_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","clearable":"","label":"Producto","items":_vm.productos},model:{value:(_vm.producto),callback:function ($$v) {_vm.producto=$$v},expression:"producto"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Precio Unitario","type":"number","step":"0.01"},model:{value:(_vm.ValorUnitario),callback:function ($$v) {_vm.ValorUnitario=_vm._n($$v)},expression:"ValorUnitario"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"success","disabled":_vm.listo,"type":"submit"}},[_c('v-icon',[_vm._v("mdi-content-save")])],1)],1)],1)],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 mt-2",attrs:{"dense":"","mobile-breakpoint":"0","items-per-page":_vm.items.length,"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","loading":_vm.loader},scopedSlots:_vm._u([{key:"item.Cantidad",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numero")(item.Cantidad))+" ")]}},{key:"item.ValorUnitario",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.ValorUnitario))+" "),(item.ValorUnitario)?_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.limpiar(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}},{key:"item.Importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.Importe))+" ")]}}],null,true)}),_c('v-data-table',{staticClass:"elevation-1 mt-5",attrs:{"dense":"","mobile-breakpoint":"0","items-per-page":_vm.articulos.length,"headers":_vm.headers2,"items":_vm.articulos,"hide-default-footer":"","loading":_vm.loader},scopedSlots:_vm._u([{key:"item.Cantidad",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numero")(item.Cantidad))+" ")]}},{key:"item.ValorUnitario",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.ValorUnitario))+" ")]}},{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe))+" ")]}},{key:"item.iva",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.iva))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteArticulo(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)}),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_vm._v(" SubTotal: "+_vm._s(_vm._f("moneda")((_vm.totalAguacates + _vm.totalArticulos)))+" ")]),_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_vm._v(" Iva: "+_vm._s(_vm._f("moneda")(_vm.iva))+" ")]),_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_vm._v(" Importe de la Factura: "+_vm._s(_vm._f("moneda")((_vm.totalAguacates + _vm.totalArticulos + _vm.iva)))+" ")])],1)],1):_vm._e(),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"success","disabled":_vm.preparado},on:{"click":_vm.mandarDatosFactura}},[_vm._v("Facturar")]),(_vm.supplies)?[(_vm.supplies.length > _vm.articulos.length)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" agregar articulos ")]):_vm._e()]:_vm._e(),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("cancelar")])],2),(_vm.dialog)?_c('ArticuloForm',{attrs:{"dialog":_vm.dialog},on:{"cancelar":function($event){_vm.dialog = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }