<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Archivos
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <template v-if="factura">
          <v-col cols="12" xl="6">
            <v-file-input
              class="mt-5"
              v-model="file"
              dense
              outlined
              placeholder="Seleccionar archivo para subir"
              prepend-icon="mdi-attachment"
              accept="application/xml"
              label="Subir Archivo XML de factura"
            />
          </v-col>
          <v-col cols="12" xl="2">
            <v-btn
              class="mt-5"
              color="success"
              v-if="esXml"
              :disabled="isLoading"
              @click="enviar"
              >Subir</v-btn
            >
          </v-col>
        </template>
        <v-col cols="12" xl="8" v-else> </v-col>

        <v-col cols="12" xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        class="elevation-1"
        :search.sync="search"
      >
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at | dateTime }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="error"
            v-if="item.name.includes('.xml')"
            @click="eliminar(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "Archivos",
  data: () => ({
    file: undefined,
    search: undefined,
    headers: [
      { text: "Archivo", value: "name" },
      { text: "Fecha / Hora", value: "created_at" },
      { text: "Usuario", value: "user" }
    ]
  }),
  computed: {
    ...mapState({
      isLoading: state => state.Embarques.loader,
      items: state => state.Embarques.files,
      embarqueId: state => state.Embarques.resource.id
    }),
    factura() {
      let archivos = this.items.filter(x => x.name.includes(".xml"));
      return archivos.length ? false : true;
    },
    esXml() {
      return this.file && this.file.name.includes(".xml");
    }
  },
  methods: {
    ...mapActions("Embarques", ["getEmbarque"]),
    enviar() {
      let formData = new FormData();
      formData.append("file", this.file);
      window.axios
        .post("/Gerencia/embarques/subirFactura/" + this.embarqueId, formData)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1500 });
          this.getEmbarque(this.embarqueId);
        })
        .catch(e => {
          window.swal(e.response.data.message, { icon: "error", timer: 5000 });
        });
    },
    eliminar(item) {
      let pregunta = `¿Eliminar el archivo ${item.name}?`;
      window
        .swal({
          title: pregunta,
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true
        })
        .then(confirmed => {
          if (confirmed) {
            //this.deleteFile(item.id);
          }
        });
    }
  }
};
</script>
