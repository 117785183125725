import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";
import router from "../router";
Vue.use(Vuex);
import { Printd } from "printd";
import { isMobile } from "mobile-device-detect";
export default new Vuex.Store({
  modules,
  state: {
    appName: "Zamgod Solutions",
    errors: [],
    loader: false,
    d: new Printd(),
    isMobile
  },
  mutations: {
    setErrors(state, payload = []) {
      state.errors = payload;
    },
    setLoader(state, payload = true) {
      state.loader = payload;
    }
  },
  actions: {
    reset({ commit }) {
      // resets state of all the modules
      Object.keys(modules).forEach(moduleName => {
        commit(`${moduleName}/SET_INITIAL_STATE`);
      });
    },
    errores({ commit, dispatch }, e) {
      const status = e.response.status;
      switch (status) {
        case 402:
          Vue.swal({
            title: e.response.data.message,
            icon: "error",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2500
          });
          dispatch("reset");
          localStorage.clear();
          window.axios.defaults.headers.common.Authorization =
            "Bearer token-falso";
          router.push("/").catch(() => {});
          break;
        case 404:
          window.swal("No existe la ruta o recurso", {
            icon: "error",
            timer: 1500
          });
          break;
        case 403:
          window.swal("No cuentas con los permisos suficientes", {
            icon: "error",
            timer: 1500
          });
          break;
        case 401:
          window.swal("La sesion expiró", {
            icon: "error",
            timer: 1500
          });
          dispatch("reset");
          localStorage.clear();
          window.axios.defaults.headers.common.Authorization =
            "Bearer token-falso";
          router.push("/").catch(() => {});
          break;
        case 422:
          commit("setErrors", e.response.data.errors);
          if (!e.response.data.errors) {
            window.swal(e.response.data.message, {
              icon: "error",
              timer: 2000
            });
          }
          break;
        default:
          window.swal(e.response.data.message, { icon: "error", timer: 1500 });
          router.push({ name: "Home" });
          break;
      }
    },
    /* jshint -W137 */
    message({}, string) {
      Vue.swal({
        icon: "success",
        title: string,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2500
      });
    },
    print(url) {
      console.log(url)
      if (this.isMobile) {
        window.open(url);
      } else {
        const el = document.createElement("p");
        window.axios.get(url).then(resp => {
          el.innerHTML = resp.data;
          state.d.print(el);
        });
      }
    }
  },
  strict: false
});
