import router from "../../router";
const url = "Gerencia/lotes";
const initialState = () => ({
  collection: undefined,
  resource: undefined,
  loader: false,
  fechas: {
    inicio: undefined,
    fin: undefined
  },
  paginado: { currentPage: 1 }
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    setCollection(state, payload = undefined) {
      state.collection = payload;
    },
    setResource(state, payload = undefined) {
      state.resource = payload;
    },
    setLoader(state, payload) {
      state.loader = payload;
    },
    SET_FECHAS(state, payload) {
      state.fechas = payload;
    },
    setPaginado(state, payload) {
      state.paginado = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch, state }) {
      commit("setCollection", []);
      commit("setLoader", true);
      let inicio = state.fechas.inicio ? state.fechas.inicio : null,
        fin = state.fechas.fin ? state.fechas.fin : null;
      window.axios
        .get(url, {
          params: {
            inicio,
            fin,
            page: state.paginado.currentPage,
            lote: state.paginado.lote,
            huerta: state.paginado.huerta
          }
        })
        .then(resp => {
          commit("setCollection", resp.data.collection);
          commit("SET_FECHAS", resp.data.fechas);
          commit("setPaginado", resp.data.paginado);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async getLote({ commit, dispatch }, id) {
      commit("setLoader", true);
      await window.axios
        .get(`${url}/${id}`)
        .then(resp => {
          let nombre = resp.data.title;
          commit("setResource", resp.data.resource);
          router
            .push({ name: "lotes.details", params: { id, nombre } })
            .catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    }
  }
};
