<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-row>
        <v-col cols="12" xl="4">
          <v-text-field
            outlined
            dense
            v-model="search"
            append-icon="mdi-magnify"
            label="Busqueda"
            single-line
            hide-details
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-btn color="success" @click="nuevo" :disabled="loader">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn color="info" class="ml-2" @click="getAll" :disabled="loader">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :items="activos"
        :headers="headers"
        class="elevation-1 mt-3"
        :search.sync="search"
        :items-per-page="items.length"
        hide-default-footer
        :loading="loader"
      >
        <template v-slot:[`item.lastLogin`]="{ item }">
          {{ item.lastLogin | dateTime }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="success"
            v-if="item.actions.edit"
            @click="editar(item)"
            :disabled="loader"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            color="red"
            v-if="item.actions.delete"
            @click="eliminar(item)"
            :disabled="loader"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "Users",
  data: () => ({
    search: undefined
  }),
  computed: {
    ...mapState({
      items: state => state.Users.collection,
      headers: state => state.Users.headers,
      loader: state => state.Users.loader
    }),
    activos() {
      return this.items.filter(x => !x.trashed);
    }
  },
  mounted() {
    if (!this.items.length) this.getAll();
  },
  beforeDestroy() {},
  methods: {
    ...mapActions("Users", ["getAll", "edit", "deleteUser"]),
    ...mapMutations("Users", ["setCollection"]),
    editar(item) {
      let id = item.id,
        nombre = `Editando a ${item.nombre}`;
      this.$router
        .push({ name: "users.edit", params: { id, nombre } })
        .catch(() => {});
    },
    nuevo() {
      this.$router.push({ name: "users.create" }).catch(() => {});
    },
    eliminar(item) {
      let pregunta = `¿Deseas eliminar a ${item.nombre}?`,
        text = "El usuario no podrá usar el sistema";
      window
        .swal({
          title: pregunta,
          text: text,
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true
        })
        .then(confirmado => {
          if (confirmado) {
            this.deleteUser(item.id);
          }
        });
    }
  }
};
</script>
