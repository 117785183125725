<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-row class="mt-3">
        <v-col xl="2">
          Fecha : <strong>{{ precioDolar.date | date }}</strong>
        </v-col>
        <v-col xl="2">
          Precio Dolar Banxico:
          <strong>{{ precioDolar.price | monedaSat }}</strong>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="2">
          <v-switch v-model="fijos" label="Precios Fijos" />
        </v-col>
        <v-col xl="2">
          <v-switch v-model="banda" label="Sobre Banda" />
        </v-col>
      </v-row>
      <!-- Fijos -->
      <v-row class="mt-3" v-if="fijos">
        <v-col>
          <v-card height="100%">
            <v-card-text>
              <h3>Precios Fijos</h3>
              <tabla-fijos
                :tiposCorte="tiposCorte"
                :items="preciosFijos"
                @actualizar="getAll"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Banda -->
      <v-row class="mt-3" v-if="banda">
        <v-col>
          <v-card height="100%">
            <v-card-text>
              <h3>Sobre Banda Orgánico</h3>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card height="100%">
            <v-card-text>
              <h3>Sobre Banda Convencional</h3>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import TablaFijos from "./TablaFijos.vue";
export default {
  name: "Prices",
  components: { TablaFijos },
  data: () => ({
    precioDolar: {},
    fijos: false,
    banda: false,
    tiposCorte: [],
    preciosFijos: [],
  }),
  computed: {
    ...mapState({
      loader: state => state.loader
    })
  },
  methods: {
    ...mapMutations(["setLoader"]),
    ...mapActions(["errores"]),
    getAll() {
      this.setLoader();
      window.axios
        .get("Gerencia/prices")
        .then(resp => {
          this.precioDolar = resp.data.precioDolar;
          this.tiposCorte = resp.data.tiposCorte;
          this.preciosFijos = resp.data.preciosFijos;
        })
        .catch(e => this.errores(e))
        .finally(() => this.setLoader(false));
    }
  },
  mounted() {
    this.getAll();
  }
};
</script>
