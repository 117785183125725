import router from "../../router";
const url = "Gerencia/invoice_pagos";
const initialState = () => ({
  headers: [],
  collection: undefined,
  resource: undefined,
  loader: false,
  paginado: { currentPage: 1 },
  relacionados: undefined,
  pendientes: undefined
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    setLoader(state, payload = true) {
      state.loader = payload;
    },
    setCollection(state, payload = undefined) {
      state.collection = payload;
    },
    setPaginado(state, payload) {
      state.paginado = payload;
    },
    setHeaders(state, payload) {
      state.headers = payload;
    },
    setResource(state, payload) {
      state.resource = payload;
    },
    setRelacionados(state, payload) {
      state.relacionados = payload;
    },
    setPendientes(state, payload) {
      state.pendientes = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch, state }) {
      commit("setLoader");
      commit("setCollection");
      window.axios
        .get(url, {
          params: {
            page: state.paginado.currentPage,
            inicio: state.paginado.inicio,
            fin: state.paginado.fin,
            customer_id: state.paginado.customer_id
          }
        })
        .then(resp => {
          commit("setPaginado", resp.data.paginado);
          commit("setCollection", resp.data.collection);
          commit("setHeaders", resp.data.headers);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async getResource({ commit, dispatch }, id) {
      commit("setLoader");
      await window.axios
        .get(`${url}/${id}`)
        .then(resp => {
          commit("setRelacionados", resp.data.relacionados);
          commit("setResource", resp.data.resource);
          router
            .push({ name: "payments.show", params: { id } })
            .catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async newResource({ commit, dispatch }) {
      commit("setLoader");
      await window.axios
        .get("Gerencia/createPago")
        .then(resp => {
          commit("setPendientes", resp.data.pendientes);
          router.push({ name: "payments.create" }).catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    }
  }
};
