<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text v-if="resource">
      <v-row>
        <v-col xl="3">
          Cliente: <strong>{{ resource.Nombre }}</strong>
        </v-col>
        <v-col xl="2">
          Fecha de Pago: <strong>{{ resource.FechaPago | date }}</strong>
        </v-col>
        <v-col xl="2">
          Importe:
          <strong>{{ resource.Monto | moneda }} {{ resource.MonedaP }}</strong>
        </v-col>
        <v-col xl="2">
          Tipo de Cambio:
          <strong>{{ resource.TipoCambioP | monedaSat }}</strong>
        </v-col>
        <v-col xl="2">
          Cuenta Destino: <strong>{{ resource.CtaBeneficiario }}</strong>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="mt-1" v-if="items">
      <h3>Facturas Relacionadas al Pago</h3>
      <v-data-table
        dense
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        class="elevation-1 mt-2"
        hide-default-footer
        :items-per-page="items.length"
      >
        <template v-slot:[`item.factura`]="{ item }">
          {{ item.Serie }} {{ item.Folio }}
        </template>
        <template v-slot:[`item.ImpSaldoAnt`]="{ item }">
          {{ item.ImpSaldoAnt | moneda }}
        </template>
        <template v-slot:[`item.ImpPagado`]="{ item }">
          {{ item.ImpPagado | moneda }}
        </template>
        <template v-slot:[`item.ImpSaldoInsoluto`]="{ item }">
          {{ item.ImpSaldoInsoluto | moneda }}
        </template>
        <template v-slot:[`item.pesos`]="{ item }">
          {{ importePesos(item) | moneda }}
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="$router.go(-1)">regresar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Show",
  data: () => ({
    headers: [
      { text: "Factura", value: "factura" },
      { text: "Folio Sat", value: "IdDocumento" },
      { text: "Saldo Anterior", value: "ImpSaldoAnt", align: "end" },
      { text: "Importe", value: "ImpPagado", align: "end" },
      { text: "Saldo Actual", value: "ImpSaldoInsoluto", align: "end" },
      { text: "Importe en Pesos ", value: "pesos", align: "end" },
    ]
  }),
  computed: {
    ...mapState({
      resource: state => state.Payments.resource,
      items: state => state.Payments.relacionados
    })
  },
  methods: {
    ...mapActions("Payments", ["getResource"]),
    importePesos(item){
        return parseFloat(item.ImpPagado)*this.resource.TipoCambioP
    }
  },
  beforeMount() {
    if (!this.resource) {
      this.getResource(this.$route.params.id);
    }
  }
};
</script>
