var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[_c('v-card-text',[_c('v-form',{attrs:{"disabled":_vm.loader},on:{"submit":function($event){$event.preventDefault();return _vm.getAll()}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","xl":"2"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Fecha Inicial","type":"date"},model:{value:(_vm.paginado.inicio),callback:function ($$v) {_vm.$set(_vm.paginado, "inicio", $$v)},expression:"paginado.inicio"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","xl":"2"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Fecha Final","type":"date"},model:{value:(_vm.paginado.fin),callback:function ($$v) {_vm.$set(_vm.paginado, "fin", $$v)},expression:"paginado.fin"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","xl":"4"}},[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","clearable":"","label":"Filtrar por Cliente","items":_vm.paginado.customers},model:{value:(_vm.paginado.customer_id),callback:function ($$v) {_vm.$set(_vm.paginado, "customer_id", $$v)},expression:"paginado.customer_id"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"info","type":"submit","disabled":_vm.loader}},[_c('v-icon',[_vm._v("mdi-magnify")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"success","disabled":_vm.loader},on:{"click":_vm.newResource}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","mobile-breakpoint":"0","hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"item-class":_vm.rowClasses,"loading":_vm.loader,"items-per-page":_vm.paginado.perPage},scopedSlots:_vm._u([{key:"item.FechaPago",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.FechaPago))+" ")]}},{key:"item.Monto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.Monto))+" ")]}},{key:"item.TipoCambioP",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("monedaSat")(item.TipoCambioP))+" ")]}},{key:"item.Importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.Importe))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary","disabled":_vm.loader},on:{"click":function($event){return _vm.mostrar(item)}}},[_c('v-icon',[_vm._v("mdi-information")])],1),_c('v-btn',{attrs:{"icon":"","color":"secondary"},on:{"click":function($event){return _vm.print(item)}}},[_c('v-icon',[_vm._v("mdi-printer")])],1)]}}],null,true)}),_c('Paginado',{attrs:{"loader":_vm.loader,"paginado":_vm.paginado}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }