<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-expansion-panels multiple v-if="resource">
        <Invoice />
        <Customer />
        <Conceptos />
        <!-- <Comercio v-if="ComercioExterior" /> -->
        <ComplementoPagos v-if="pagos" />
        <Notas />
      </v-expansion-panels>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="$router.go(-1)">Regresar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Invoice from "./PanelInvoice.vue";
import Customer from "./PanelCustomer.vue";
import Conceptos from "./PanelConceptos.vue";
import Notas from "./PanelNotas.vue";
// import Comercio from "./PanelComerCioExterior.vue";
import ComplementoPagos from "./PanelPagos.vue";
export default {
  name: "Show",
  components: { ComplementoPagos, Conceptos, Customer, Invoice, Notas },
  computed: {
    ...mapState({
      resource: state => state.Invoices.resource,
      ComercioExterior: state =>
        state.Invoices.xml.Complemento.ComercioExterior,
      pagos: state => state.Invoices.pagos,
      notas: state => state.Invoices.notas
    })
  },
  methods: {
    ...mapActions("Invoices", ["getResource"])
  },
  mounted() {
    if (!this.resource) {
      this.getResource(this.$route.params.id);
    }
  }
};
</script>
