<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-form @submit.prevent="enviar">
      <v-card-text>
        <v-row>
          <v-col>
            <v-file-input
              multiple
              v-model="files"
              outlined
              dense
              label="Seleccione los Archivos XML"
              small-chips
              clearable
              counter
              show-size
              truncate-length="50"
              accept=".xml"
              :error-messages="errors.files"
              max="20"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn color="success" type="submit">enviar</v-btn>
        <v-btn color="error" @click="cancelar">cancelar</v-btn>
      </v-card-actions>
    </v-form>
    <v-card-text>
      <v-row>
        <v-col cols="12" xl="3" v-if="egresos.length">
          <v-card height="100%">
            <v-card-title primary-title>
              {{ egresos.length }} Archivos de Egresos
            </v-card-title>
            <v-card-text>
              <ul>
                <li v-for="item in egresos" :key="item">{{ item }}</li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" xl="3" v-if="nominas.length">
          <v-card height="100%">
            <v-card-title primary-title>
              {{ nominas.length }} Archivos de Nómina
            </v-card-title>
            <v-card-text>
              <ul>
                <li v-for="item in nominas" :key="item">{{ item }}</li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" xl="3" v-if="ingresos.length">
          <v-card height="100%">
            <v-card-title primary-title>
              {{ ingresos.length }} Archivos de Ingresos <br />
            </v-card-title>
            <v-card-text>
              <ul>
                <li v-for="item in ingresos" :key="item">{{ item }}</li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" xl="3" v-if="invalidos.length">
          <v-card height="100%">
            <v-card-title primary-title>
              {{ invalidos.length }} Archivos Inválidos
            </v-card-title>
            <v-card-text>
              <ul>
                <li v-for="item in invalidos" :key="item">{{ item }}</li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Pruebas",
  data: () => ({
    files: [],
    invalidos: [],
    ingresos: [],
    egresos: [],
    nominas: []
  }),
  computed: {
    ...mapState({
      errors: state => state.errors
    })
  },
  methods: {
    ...mapMutations(["setErrors"]),
    ...mapActions(["errores", "message"]),
    enviar() {
      this.setErrors();
      this.nominas = this.invalidos = this.procesados = this.ingresos = this.egresos = [];
      let formData = new FormData();
      for (var i = 0; i < this.files.length; i++) {
        formData.append("files[]", this.files[i]);
      }
      window.axios
        .post("Gerencia/uploadXml", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(resp => {
          this.message(resp.data.message);
          this.egresos = resp.data.egresos;
          this.ingresos = resp.data.ingresos;
          this.invalidos = resp.data.invalidos;
          this.nominas = resp.data.nominas;
          this.files = undefined;
        })
        .catch(e => this.errores(e));
    },
    cancelar() {
      this.files = this.nominas = this.invalidos = this.procesados = this.ingresos = this.egresos = [];
    }
  }
};
</script>
