<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Datos del Empleado  {{resource.Nombre}}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-form disabled>
        <v-row class="mt-3">
          <v-col cols="12" xl="5">
            <v-text-field
              dense
              outlined
              label="Nombre"
              v-model="resource.Nombre"
            />
          </v-col>
          <v-col cols="12" xl="2">
            <v-text-field dense outlined label="RFC" v-model="resource.Rfc" />
          </v-col>
          <v-col>
            <v-text-field dense outlined label="CURP" v-model="resource.Curp" />
          </v-col>
          <v-col cols="12" xl="2">
            <v-text-field
              dense
              outlined
              label="NSS"
              v-model="resource.NumSeguridadSocial"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              dense
              outlined
              label="Fecha Ingreso"
              v-model="resource.FechaInicioRelLaboral"
              type="date"
            />
          </v-col>
          <v-col>
            <v-text-field
              dense
              outlined
              label="Puesto"
              v-model="resource.Puesto"
            />
          </v-col>
          <v-col>
            <v-text-field
              dense
              outlined
              label="Departamento"
              v-model="resource.Departamento"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Employe",
  computed: {
    ...mapState({
      resource: state => state.Employes.resource
    })
  }
};
</script>
