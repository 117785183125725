<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-form @submit.prevent="getAll">
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
              outlined
              dense
              clearable
              type="date"
              label="Fecha Incial"
              v-model="fechas.inicio"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              outlined
              dense
              clearable
              type="date"
              label="Fecha Final"
              v-model="fechas.fin"
            />
          </v-col>
          <v-col>
            <v-btn color="primary" type="submit" :disabled="loader">
              buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-expansion-panels multiple>
        <Productores />
        <Transportistas />
        <Cuadrillas />
        <Egresos />
      </v-expansion-panels>
      <v-row class="mt-3">
        <v-col> Importe del Periodo: {{ importe | moneda }} </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Productores from "./pagos/PanelProductores";
import Transportistas from "./pagos/PanelTransportistas";
import Cuadrillas from "./pagos/PanelCuadrillas";
import Egresos from "./pagos/PanelEgresos.vue";
export default {
  name: "Pagos",
  components: { Productores, Transportistas, Cuadrillas, Egresos },
  computed: {
    ...mapState({
      fechas: state => state.Pagos.fechas,
      loader: state => state.Pagos.loader,
      items: state => state.Pagos.collection,
      egresos: state => state.Pagos.egresos
    }),
    importe() {
      return (
        window._.sumBy(this.items, "importe") +
        window._.sumBy(this.egresos, "importe")
      );
    }
  },
  methods: {
    ...mapActions("Pagos", ["getAll"])
  }
};
</script>
