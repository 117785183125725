<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-expansion-panels multiple v-if="resource">
        <Provider />
        <Invoices />
        <Pagos />
      </v-expansion-panels>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="$router.push({ name: 'providers' })">
        regresar a proveedores
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Provider from "./PanelProvider.vue";
import Invoices from "./PanelFacturas.vue";
import Pagos from "./PanelPagos.vue";
export default {
  name: "Show",
  components: { Invoices, Pagos, Provider },
  computed: {
    ...mapState({
      resource: state => state.Providers.resource
    })
  },
  mounted() {
    if (!this.resource) {
      this.getResource(this.$route.params.id);
    }
  },
  methods: {
    ...mapActions("Providers", ["getResource"])
  }
};
</script>
