<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text v-if="resource">
      <v-expansion-panels multiple>
        <Employe />
        <Pagos />
        <Files />
      </v-expansion-panels>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="$router.push({ name: 'employes' })">
        regresar a empleados
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Employe from "./PanelEmploye.vue";
import Pagos from "./PanelPagos.vue";
import Files from "./PanelArchivos.vue";
export default {
  name: "Show",
  components: { Employe, Files, Pagos },
  computed: {
    ...mapState({
      loader: state => state.Employes.loader,
      resource: state => state.Employes.resource
    })
  },
  mounted() {
    if (!this.resource) {
      this.getResource(this.$route.params.id);
    }
  },
  methods: {
    ...mapActions("Employes", ["getResource"])
  }
};
</script>
