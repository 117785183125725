<!-- eslint-disable -->
<template>
  <div class="mt-3">
    <v-row>
      <v-col cols="12" xl="4" offset-xl="8">
        <v-container>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Busqueda"
            single-line
            hide-details
          />
        </v-container>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      class="elevation-1"
      :search.sync="search"
    >
      <template v-slot:[`item.pallets`]="{ item }">
        <template v-for="pallet in item.pallets">
          {{ `${pallet} ` }}
        </template>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  name: "Completos",
  data: () => ({
    headers: [
      { text: "Producto", value: "producto", width: "25%" },
      { text: "Pallets", value: "pallets", width: "65%" },
      { text: "Cajas", value: "cajas", align: "end" }
    ],
    items: [],
    search: undefined
  }),
  computed: {
    ...mapState({
      detalles: state => state.Inventario.detalles.pallets
    }),
    productos() {
      let validos = this.detalles.filter(x => x.presentacion !== "KGS");
      return [...new Set(validos.map(x => x.producto))].sort();
    }
  },
  methods: {
    armarPallets(producto) {
      let filtrados = this.detalles.filter(x => x.producto == producto),
        pallets = [...new Set(filtrados.map(x => x.pallet))].sort(),
        listos = [];
      pallets.forEach(pallet => {
        let porPallet = filtrados.filter(x => x.pallet == pallet),
          obj = { pallet, producto, cantidad: _.sumBy(porPallet, "cantidad") };
        if (obj.cantidad > 79) listos.push(obj);
      });
      let productos = [...new Set(listos.map(x => x.producto))];
      productos.forEach(producto => {
        let pallets = listos.filter(x => x.producto == producto),
          item = {
            producto,
            pallets: [...new Set(pallets.map(x => x.pallet))],
            cajas: _.sumBy(pallets, "cantidad")
          };
        this.items.push(item);
      });
    }
  },
  mounted() {
    this.productos.forEach(x => {
      this.armarPallets(x);
    });
  }
};
</script>
