<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-form @submit.prevent="getAll">
        <v-row>
          <v-col cols="12" md="6" xl="2">
            <v-text-field
              dense
              outlined
              clearable
              label="Fecha Inicio"
              type="date"
              v-model="paginado.inicio"
            />
          </v-col>
          <v-col cols="12" md="6" xl="2">
            <v-text-field
              dense
              outlined
              clearable
              label="Fecha Final"
              type="date"
              v-model="paginado.fin"
            />
          </v-col>
          <v-col cols="12" md="6" xl="4">
            <v-autocomplete
              dense
              outlined
              clearable
              label="Filtrar por Status"
              v-model="paginado.status"
              :items="paginado.estados"
            />
          </v-col>
          <v-col cols="12" md="6" xl="4">
            <v-autocomplete
              dense
              outlined
              clearable
              label="Filtrar por Proveedor"
              :items="paginado.providers"
              v-model="paginado.provider_id"
            />
          </v-col>
          <v-col cols="12" md="6" xl="4">
            <v-autocomplete
              dense
              outlined
              clearable
              label="Filtrar por Partida"
              :items="paginado.categories"
              v-model="paginado.category"
            />
          </v-col>
          <v-col cols="12" md="6" xl="4">
            <v-text-field
              dense
              outlined
              clearable
              label="Buscar Factura"
              v-model="paginado.search"
            />
          </v-col>
          <v-col>
            <v-btn color="info" type="submit">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn color="primary" class="ml-2" @click="solicitar"
              >solicitar al SAT</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
      <v-data-table
        dense
        mobile-breakpoint="0"
        hide-default-footer
        :headers="headers"
        :items="items"
        class="elevation-1"
        :item-class="rowClasses"
        :loading="loader"
        :items-per-page="paginado.perPage"
      >
        <template v-slot:[`item.date`]="{ item }">
          {{ item.date | date }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.total`]="{ item }">
          {{ item.total | moneda }}
        </template>
        <template v-slot:[`item.tipo_cambio`]="{ item }">
          {{ item.tipo_cambio | monedaSat }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <template v-if="item.status == 'PENDIENTE'">
            <v-btn
              icon
              color="error"
              :disabled="loader"
              @click="eliminar(item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <v-btn
            icon
            :disabled="loader"
            color="primary"
            @click="getResource(item.id)"
          >
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.url`]="{ item }">
          <v-btn icon :color="colorBtn" @click="print(item.url)">
            <v-icon>{{ isMobile ? "mdi-file-pdf" : "mdi-printer" }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <Paginado :loader="loader" :paginado="paginado" />
      <v-row v-if="paginado.importe > 0 && !loader">
        <v-col cols="12" md="auto">
          Importe del Periodo en pesos:
          <strong>{{ paginado.importe | moneda }}</strong>
        </v-col>
        <v-col cols="12" md="auto">
          Última solicitud al Sat:
          <strong>{{ paginado.scraper.hora | dateTime }}</strong>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Printd } from "printd";
export default {
  name: "Index",
  data: () => ({
    d: new Printd()
  }),
  mounted() {
    this.getAll();
  },
  computed: {
    ...mapState({
      loader: state => state.Egresos.loader,
      items: state => state.Egresos.collection,
      headers: state => state.Egresos.headers,
      paginado: state => state.Egresos.paginado,
      isMobile: state => state.isMobile
    }),
    actual() {
      return this.paginado.currentPage;
    },
    estado() {
      return this.paginado.status;
    },
    proveedor() {
      return this.paginado.provider_id;
    },
    categoria() {
      return this.paginado.category;
    },
    colorBtn() {
      return this.isMobile ? "red" : "secondary";
    }
  },
  methods: {
    ...mapActions("Egresos", [
      "getAll",
      "getResource",
      "deleteResource",
      "solicitar"
    ]),

    xml() {
      this.$router.push({ name: "egresos.upload" }).catch(() => {});
    },
    eliminar(item) {
      this.$swal({
        title: `¿Eliminar la Factura ${item.folio}?`,
        text: "Esta acción no puede deshacerse",
        icon: "warning",
        showCancelButton: true
      }).then(result => {
        if (result.isConfirmed) {
          this.deleteResource(item.id);
        }
      });
    },
    print(url) {
      if (!this.isMobile) {
        window.axios.get(url).then(resp => {
          const el = document.createElement("p");
          el.innerHTML = resp.data;
          this.d.print(el);
        });
      } else {
        window.open(url);
      }
    }
  },
  watch: {
    actual: function() {
      this.getAll();
    }
  }
};
</script>
