<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      {{ paginado.registros }} Pagos Registrados
    </v-expansion-panel-header>
    <v-expansion-panel-content class="mt-3" v-if="detalle">
      <v-card>
        <v-card-title primary-title>
          Detalle del Pago del día {{ fecha | date }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" xl="4">
              <h3>Percepciones</h3>
              <v-data-table
                dense
                mobile-breakpoint="0"
                :headers="headers2"
                :items="percepciones"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:[`item.ImporteGravado`]="{ item }">
                  {{ item.ImporteGravado | moneda }}
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" md="6" xl="4">
              <h3 class="mt-2">Otros Pagos</h3>
              <v-data-table
                dense
                mobile-breakpoint="0"
                :headers="headers3"
                :items="otros"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:[`item.importe`]="{ item }">
                  {{ item.importe | moneda }}
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" md="6" xl="4">
              <h3 class="mt-2">Deducciones</h3>
              <v-data-table
                dense
                mobile-breakpoint="0"
                :headers="headers3"
                :items="deducciones"
                class="elevation-1"
                hide-default-footer
              >
                <template v-slot:[`item.importe`]="{ item }">
                  {{ item.importe | moneda }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="cancelar">
            regresar a pagos
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-expansion-panel-content>
    <v-expansion-panel-content class="mt-3" v-else>
      <v-data-table
        dense
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        class="elevation-1"
        :loading="loader"
        hide-default-footer
      >
        <template v-slot:[`item.FechaPago`]="{ item }">
          {{ item.FechaPago | date }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.percepciones`]="{ item }">
          {{ item.percepciones | moneda }}
        </template>
        <template v-slot:[`item.TotalDeducciones`]="{ item }">
          {{ item.TotalDeducciones | moneda }}
        </template>
        <template v-slot:[`item.periodo`]="{ item }">
          del {{ item.FechaInicialPago | date }} al
          {{ item.FechaFinalPago | date }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon :disabled="loader" color="primary" @click="mostrar(item)">
            <v-icon>mdi-information</v-icon>
          </v-btn>
          <v-btn icon :disabled="loader" color="secondary" @click="print(item)">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <Paginado :loader="loader" :paginado="paginado" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { Printd } from "printd";
export default {
  name: "Pagos",
  computed: {
    ...mapState({
      items: state => state.Employes.pagos,
      loader: state => state.Employes.loader,
      paginado: state => state.Employes.paginado
    }),
    deducciones() {
      return this.pago.Complemento
        ? this.pago.Complemento.Nomina.Deducciones.Items
        : [];
    },
    percepciones() {
      return this.pago.Complemento
        ? this.pago.Complemento.Nomina.Percepciones.Items
        : [];
    },
    otros() {
      return this.pago.Complemento
        ? this.pago.Complemento.Nomina.OtrosPagos.Items
        : [];
    },
    fecha() {
      return this.pago.Comprobante
        ? this.pago.Complemento.Nomina.FechaPago
        : undefined;
    },
    actual() {
      return this.paginado.currentPage;
    }
  },
  data: () => ({
    headers: [
      { text: "Fecha de Pago", value: "FechaPago" },
      { text: "Dias Pagados", value: "NumDiasPagados", align: "end" },
      { text: "Periodo", value: "periodo" },
      { text: "Percepciones", value: "percepciones", align: "end" },
      { text: "Deducciones", value: "TotalDeducciones", align: "end" },
      { text: "Importe", value: "importe", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    search: undefined,
    detalle: false,
    pago: {},
    headers2: [
      { text: "Concepto", value: "Concepto" },
      { text: "Importe", value: "ImporteGravado", align: "end" }
    ],
    headers3: [
      { text: "Concepto", value: "Concepto" },
      { text: "Importe", value: "Importe", align: "end" }
    ],
    d: undefined
  }),
  methods: {
    ...mapActions("Employes", ["getPagos"]),
    ...mapMutations("Employes", ["setLoader"]),
    mostrar(item) {
      this.setLoader();
      window.axios
        .get(`Gerencia/showNomina/${item.id}`)
        .then(resp => {
          this.detalle = true;
          this.pago = resp.data;
        })
        .catch(e => {})
        .finally(() => this.setLoader(false));
    },
    cancelar() {
      this.setLoader(false);
      this.detalle = false;
    },
    print(item) {
      const el = document.createElement("p");
      let url = `Gerencia/employes/printRecibo/${item.id}`;
      this.$store.commit("Employes/setLoader");
      window.axios
        .get(url)
        .then(resp => {
          el.innerHTML = resp.data;
          this.d.print(el);
        })
        .catch(e => this.$store.dispatch("errores", e))
        .finally(() => this.$store.commit("Employes/setLoader", false));
    }
  },
  mounted() {
    this.d = new Printd();
  },
  watch: {
    actual() {
      this.getPagos();
    }
  }
};
</script>
