var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[_c('v-card-text',[_c('v-form',{attrs:{"disabled":_vm.loader},on:{"submit":function($event){$event.preventDefault();return _vm.getAll()}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","xl":"2"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Fecha Inicial","type":"date"},model:{value:(_vm.paginado.inicio),callback:function ($$v) {_vm.$set(_vm.paginado, "inicio", $$v)},expression:"paginado.inicio"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","xl":"2"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Fecha Final","type":"date"},model:{value:(_vm.paginado.fin),callback:function ($$v) {_vm.$set(_vm.paginado, "fin", $$v)},expression:"paginado.fin"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","clearable":"","label":"Filtrar por Cliente","items":_vm.paginado.customers},model:{value:(_vm.paginado.customer_id),callback:function ($$v) {_vm.$set(_vm.paginado, "customer_id", $$v)},expression:"paginado.customer_id"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"info","type":"submit","disabled":_vm.loader}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-2",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.paginado.perPage,"hide-default-footer":"","mobile-breakpoint":"0","loading":_vm.loader},scopedSlots:_vm._u([{key:"item.TipoCambio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("monedaSat")(item.TipoCambio))+" ")]}},{key:"item.Importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe))+" ")]}},{key:"item.Total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.Total))+" ")]}},{key:"item.Fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.Fecha))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.CfdiRelacionado)?_c('v-btn',{attrs:{"icon":"","color":"success","disabled":_vm.loader},on:{"click":function($event){return _vm.modificar(item)}}},[_c('v-icon',[_vm._v("mdi-check-box-multiple-outline")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":"","color":"secondary"},on:{"click":function($event){return _vm.print(item)}}},[_c('v-icon',[_vm._v("mdi-printer")])],1)]}},{key:"item.CfdiRelacionado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.CfdiRelacionado)+" "),(item.CfdiRelacionado && !item.Folio.includes('NOTAS'))?_c('v-btn',{staticClass:"ml-4",attrs:{"icon":"","color":"error","disabled":_vm.loader},on:{"click":function($event){return _vm.eliminarRelacion(item)}}},[_c('v-icon',[_vm._v("mdi-file-remove-outline")])],1):_vm._e()]}}],null,true)}),_c('Paginado',{attrs:{"loader":_vm.loader,"paginado":_vm.paginado}})],1),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"loading":_vm.loader}},[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Relacionar con Factura ")]),_c('v-card-text',{staticClass:"mt-3"},[_c('v-row',{staticClass:"mb-3"},[_c('v-col',[_vm._v(" Importe de la Nota : "+_vm._s(_vm._f("moneda")(_vm.nota.Total))+" ")])],1),_c('v-autocomplete',{attrs:{"dense":"","outlined":"","clearable":"","label":"Seleccione Factura","items":_vm.nota.posibles,"disabled":_vm.loader},model:{value:(_vm.nota.CfdiRelacionado),callback:function ($$v) {_vm.$set(_vm.nota, "CfdiRelacionado", $$v)},expression:"nota.CfdiRelacionado"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","disabled":_vm.loader},on:{"click":_vm.guardar}},[_vm._v(" guardar ")]),_c('v-btn',{attrs:{"color":"error","disabled":_vm.loader},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }