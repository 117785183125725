<!-- eslint-disable -->
<template>
  <v-expansion-panel v-if="embarque">
    <v-expansion-panel-header color="green lighten-4">
      Datos del Embarque
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="my-3">
        <v-col cols="12" md="4">
          N° de Embarque: {{ embarque.id | folio }} <br />
          Fecha: {{ embarque.fecha | date }} <br />
          Cliente: {{ embarque.cliente }} <br />
          Orden de Compra(PO): {{ embarque.po }} <br />
        </v-col>
        <v-col cols="12" md="4">
          Certificado: {{ embarque.certificado }} <br />
          Agente Americano: {{ embarque.agenteAmericano }} <br />
          Agente Mexicano: {{ embarque.agenteMexicano }} <br />
        </v-col>
        <v-col cols="12" md="4">
          N° de Expediente: {{ embarque.expediente }} <br />
          Transporte: {{ embarque.transporte }} <br />
          Operador: {{ embarque.operador }} <br />
          Termógrafo: {{ embarque.termografo }} <br />
          Placas Camión: {{ embarque.placa_camion }} <br />
          Placas Thermo: {{ embarque.placas_termo }}
        </v-col>
      </v-row>
      <v-form :disabled="loader" @submit.prevent="enviar">
        <v-row>
          <v-col cols="12" md="auto">
            <v-text-field
              dense
              outlined
              clearable
              label="Fecha de Salida"
              type="date"
              v-model="embarque.fecha_salida"
              :error-messages="errors.fecha_salida"
            />
          </v-col>
          <v-col cols="12" md="auto">
            <v-text-field
              dense
              outlined
              clearable
              label="Hora de Salida"
              type="time"
              v-model="embarque.hora_salida"
              :error-messages="errors.hora_salida"
            />
          </v-col>
          <v-col cols="12" md="auto">
            <v-text-field
              dense
              outlined
              clearable
              label="Fecha de Llegada"
              type="date"
              v-model="embarque.fecha_llegada"
              :error-messages="errors.fecha_llegada"
            />
          </v-col>
          <v-col cols="12" md="auto">
            <v-text-field
              dense
              outlined
              clearable
              label="Hora de Llegada"
              type="time"
              v-model="embarque.hora_llegada"
              :error-messages="errors.hora_llegada"
            />
          </v-col>
          <v-col>
            <v-btn
              color="success"
              class="mr-3"
              v-if="modificado"
              type="submit"
              :disabled="loader"
            >
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
            <v-btn
              color="error"
              class="mr-3"
              v-if="modificado"
              @click="cancelar"
              :disabled="loader"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn
              color="secondary"
              v-if="!modificado && original.hora_llegada"
              :disabled="loader"
              @click="imprimir"
            >
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { isMobile } from "mobile-device-detect";
import { Printd } from "printd";
export default {
  name: "Datos",
  data: () => ({
    original: undefined,
    d: new Printd(),
    isMobile
  }),
  computed: {
    ...mapState({
      embarque: state => state.Embarques.resource,
      loader: state => state.loader,
      errors: state => state.errors
    }),
    modificado() {
      return JSON.stringify(this.original) !== JSON.stringify(this.embarque);
    }
  },
  mounted() {
    this.setErrors();
    this.original = Object.assign({}, this.embarque);
  },
  methods: {
    ...mapMutations(["setLoader", "setErrors"]),
    ...mapActions(["errores", "message", "print"]),
    enviar() {
      this.setLoader();
      this.setErrors();
      let url = `/Gerencia/embarques/updateCartaPorte/${this.$route.params.id}`;
      window.axios
        .post(url, this.embarque)
        .then(resp => {
          this.message(resp.data.message);
          this.$store.dispatch("Embarques/getEmbarque", this.$route.params.id);
        })
        .catch(e => this.errores(e))
        .finally(() => this.setLoader(false));
    },
    cancelar() {
      this.$store.dispatch("Embarques/getEmbarque", this.$route.params.id);
    },
    imprimir() {
      if (this.isMobile) {
        window.open(this.embarque.url);
      } else {
        const el = document.createElement("p");
        window.axios.get(this.embarque.url).then(resp => {
          el.innerHTML = resp.data;
          this.d.print(el);
        });
      }
    }
  },
  watch: {
    embarque(val) {
      this.original = Object.assign({}, val);
    }
  }
};
</script>
