const routes = [
  {
    path: "/Articulos",
    name: "supplies",
    component: require("@/views/supplies/Index.vue").default,
    meta: {
      name: "Catálogo de Artículos"
    }
  },
  {
    path: "/Articulos/nuevo",
    name: "supplies.create",
    component: require("@/views/supplies/Formulario.vue").default,
    meta: {
      name: "Nuevo Artículo"
    }
  },
  {
    path: "/Articulos/editar/:id",
    name: "supplies.edit",
    component: require("@/views/supplies/Formulario.vue").default,
    meta: {
      name: "Editar Artículo"
    }
  }
];
export default routes;
