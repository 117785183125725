<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-form @submit.prevent="buscar">
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
              outlined
              dense
              clearable
              type="date"
              label="Fecha Incial"
              v-model="fechas.inicio"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              outlined
              dense
              clearable
              type="date"
              label="Fecha Final"
              v-model="fechas.fin"
            />
          </v-col>
          <v-col>
            <v-btn color="primary" type="submit" :disabled="loader">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-expansion-panels multiple>
        <Cuadrillas />
        <Transportistas />
        <Productores />
        <panel-comisionistas />
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Cuadrillas from "./PanelCuadrillas";
import Transportistas from "./PanelTransoportistas";
import Productores from "./PanelProductores";
import PanelComisionistas from "./PanelComisionistas.vue";
export default {
  components: { Cuadrillas, Transportistas, Productores, PanelComisionistas },
  name: "Calculadora",
  computed: {
    ...mapState({
      fechas: state => state.Calculadora.fechas,
      loader: state => state.Calculadora.loader
    })
  },
  mounted() {
    if (!this.fechas.inicio) this.buscar();
  },
  methods: {
    ...mapActions("Calculadora", ["buscar"])
  }
};
</script>
