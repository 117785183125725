<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-title>Egresos {{ mes }}</v-card-title>
    <v-card-text>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        mobile-breakpoint="0"
        class="elevation-1"
        hide-default-footer
        :items-per-page="items.length"
        :loading="loader"
        sort-by="partida"
      >
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
      </v-data-table>
      <v-row>
        <v-col class="mt-2">
          <h3>Importe Total: {{total|moneda}}</h3>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="$emit('actualizar')">actualizar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Egresos",
  props: {
    mes: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    loader: {
      type: Boolean
    }
  },
  data: () => ({
    headers: [
      { text: "Partida", value: "partida" },
      { text: "Importe", value: "importe", align: "end", width: "20" }
    ]
  }),
  computed: {
    total() {
      return window._.sumBy(this.items, "importe");
    }
  }
};
</script>
