import router from "../../router";
const initialState = () => ({
  headers: [],
  collection: [],
  resource: {},
  loader: false
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    SET_HEADERS(state, payload) {
      state.headers = payload;
    },
    setCollection(state, payload) {
      state.collection = payload;
    },
    setResource(state, payload) {
      state.resource = payload;
    },
    setLoader(state, payload = true) {
      state.loader = payload;
    }
  },
  actions: {
    async getAll({ commit }) {
      commit("setCollection", []);
      commit("setLoader");
      window.axios
        .get("users", { params: { tipo: "Gerencia" } })
        .then(resp => {
          commit("setCollection", resp.data.collection);
          commit("SET_HEADERS", resp.data.headers);
        })
        .catch(e => {
          dispatch("errores", e, { root: true });
        })
        .finally(() => commit("setLoader", false));
    },
    async edit({ commit }, id) {
      window.axios
        .get(`users/${id}`)
        .then(resp => {
          commit("setResource", resp.data);
        })
        .catch(e => {
          dispatch("errores", e, { root: true });
          commit("setLoader", false);
        });
    },
    async sendUser({ commit, state, dispatch }) {
      commit("setErrors", [], { root: true });
      commit("setLoader", true);
      state.resource.tipo = "Gerencia";
      if (state.resource.id) {
        await window.axios
          .put(`users/${state.resource.id}`, state.resource)
          .then(resp => {
            window.swal(resp.data.message, { icon: "success", timer: 1500 });
            let index = state.collection.findIndex(
              x => x.id == state.resource.id
            );
            state.collection.splice(index, 1, resp.data.user);
            commit("setLoader", false);
            router.push({ name: "users" });
          })
          .catch(e => {
            dispatch("errores", e, { root: true });
            commit("setLoader", false);
          });
      } else {
        await window.axios
          .post("users", state.resource)
          .then(resp => {
            window.swal(resp.data.message, { icon: "success", timer: 1500 });
            state.collection.unshift(resp.data.user);
            commit("setLoader", false);
            router.push({ name: "users" });
          })
          .catch(e => {
            dispatch("errores", e, { root: true });
            commit("setLoader", false);
          });
      }
    },
    async deleteUser({ commit, dispatch, state }, id) {
      commit("setLoader", true);
      window.axios
        .delete(`users/${id}`)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1500 });
          commit("setLoader", false);
          let index = state.collection.findIndex(x => x.id == id);
          state.collection.splice(index, 1);
        })
        .catch(e => {
          dispatch("errores", e, { root: true });
          commit("setLoader", false);
        });
    }
  }
};
