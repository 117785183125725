import Lotes from "./../views/lotes/Index.vue";
import Details from "./../views/lotes/Details.vue";
const routes = [
  {
    path: "/Lotes",
    name: "lotes",
    component: Lotes,
    meta: {
      name: "Búsqueda de Lotes"
    }
  },
  {
    path: "/Lotes/detalle/:id",
    name: "lotes.details",
    component: Details,
    meta: {
      name: "Detalles del lote"
    }
  }
];
export default routes;
