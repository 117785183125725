<template>
  <v-row v-if="!loader">
    <v-col class="mt-5">
      Registros Encontrados: <strong>{{ paginado.registros | numero }}</strong>
    </v-col>
    <v-col
      cols="12"
      md="8"
    >
      <v-pagination
        v-model="paginado.currentPage"
        class="mt-3"
        :length="paginado.lastPage"
        total-visible="8"
      />
    </v-col>
    <v-col />
  </v-row>
</template>

<script>
export default {
	name: 'Paginado',
	// eslint-disable-next-line vue/require-prop-types
	props: ['paginado', 'loader']
};
</script>