<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text v-if="detalles">
      <v-expansion-panels multiple>
        <v-expansion-panel>
          <v-expansion-panel-header color="green lighten-4">
            Pallets Completos
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Completos />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header color="green lighten-4">
            Pallets Incompletos
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Restos />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header color="green lighten-4">
            Restos Proximos a Vencer
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Proximos />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header color="green lighten-4">
            Producto Nacional
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Nacional />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
    <v-card-actions v-if="detalles">
      <v-btn color="primary" @click="getAll">{{btnTitle}}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
import Restos from "./inventario/restos";
import Proximos from "./inventario/proximos";
import Completos from "./inventario/completos";
import Nacional from "./inventario/nacional";
export default {
  name: "Inventario",
  components: { Restos, Proximos, Completos, Nacional },
  computed: {
    ...mapState({
      detalles: state => state.Inventario.detalles
    }),
    btnTitle(){
      return this.detalles?'actualizar':'actualizando'
    }
  },
  mounted() {
    this.getAll();
  },
  methods: {
    ...mapActions("Inventario", ["getAll"])
  }
};
</script>
