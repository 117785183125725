<!-- eslint-disable -->
<template>
  <v-expansion-panel v-if="items">
    <v-expansion-panel-header color="orange lighten-4">
      Productores
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="mt-2">
        <v-col cols="12" md="4">
          <v-autocomplete
            outlined
            dense
            clearable
            v-model="productor"
            label="Filtrar por productor"
            hide-details
            :items="productores"
            type="search"
          />
        </v-col>
        <v-col cols="12" md="4" v-if="productor">
          <v-autocomplete
            dense
            outlined
            clearable
            label="Forma de Pago"
            type="search"
            v-model="forma"
            :items="formas"
          />
        </v-col>
        <v-col>
          <v-btn color="primary" @click="print" v-if="forma" class="mr-2">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
          <v-btn color="info" v-if="productor" @click="cesion = true">
            cesion
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        dense
        class="elevation-1 mt-3"
        :search.sync="search"
        :headers="headers"
        :items="computedItems"
        hide-default-footer
        :items-per-page="computedItems.length"
      >
        <template v-slot:[`item.lote`]="{ item }">
          {{ item.lote | folio }}
        </template>
        <template v-slot:[`item.orden_corte`]="{ item }">
          {{ item.orden_corte | folio }}
        </template>
        <template v-slot:[`item.kilos_procesados`]="{ item }">
          {{ item.kilos_procesados | decimal }}
        </template>
        <template v-slot:[`item.fecha_corte`]="{ item }">
          {{ item.fecha_corte | date }}
        </template>
        <template v-slot:[`item.precio_kilo`]="{ item }">
          {{ item.precio_kilo | moneda }}
        </template>
        <template v-slot:[`item.importe_productor`]="{ item }">
          {{ item.importe_productor | moneda }}
        </template>
        <template v-slot:[`item.total_cuadrilla`]="{ item }">
          {{ item.total_cuadrilla | moneda }}
        </template>
      </v-data-table>
      <v-dialog v-if="cesion" persistent v-model="cesion" max-width="800">
        <card-cesion :items="computedItems" @cerrar="cesion = false" />
      </v-dialog>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
import { Printd } from "printd";
import CardCesion from "./CardCesion.vue";
export default {
  name: "Productores",
  components: { CardCesion },
  data: () => ({
    headers: [
      { text: "Lote", value: "lote" },
      { text: "Fecha", value: "fecha_corte" },
      { text: "Huerta", value: "huerta" },
      { text: "Productor", value: "productor" },
      { text: "Tipo de Corte", value: "tipo_corte" },
      { text: "Forma de Pago", value: "forma_pago_productor" },
      { text: "Precio Kilo", value: "precio_kilo", align: "end" },
      { text: "Kilos Procesados", value: "kilos_procesados", align: "end" },
      { text: "Importe", value: "importe_productor", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    search: undefined,
    perPage: 15,
    productor: undefined,
    d: new Printd(),
    forma: undefined,
    formas: [
      { text: "Pago en una sola Exibición", value: "PUE" },
      { text: "Pago en Parcialidades o Diferido", value: "PPD" }
    ],
    cesion: false,
    selected: []
  }),
  computed: {
    ...mapState({
      items: state => state.Calculadora.lotes,
      inicio: state => state.Calculadora.fechas.inicio,
      fin: state => state.Calculadora.fechas.fin
    }),
    productores() {
      return [...new Set(this.items.map(x => x.productor))].sort();
    },
    computedItems() {
      return this.productor
        ? this.items.filter(x => x.productor == this.productor)
        : this.items;
    }
  },
  methods: {
    print() {
      let payload = {
        productor: this.productor,
        inicio: this.inicio,
        fin: this.fin,
        forma: this.forma
      };
      let url = "pagos/printProductor";
      window.axios.post(url, payload).then(resp => {
        const el = document.createElement("p");
        el.innerHTML = resp.data;
        this.d.print(el);
      });
    }
  }
};
</script>
