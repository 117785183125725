<!-- eslint-disable -->
<template>
  <div class="mt-3">
    <v-row>
      <v-col cols="12" xl="4" offset-xl="8">
        <v-container>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Busqueda"
            single-line
            hide-details
          />
        </v-container>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      class="elevation-1"
      :search.sync="search"
    >
      <template v-slot:[`item.fecha`]="{ item }">
        {{ item.fecha | date }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  name: "Proximos",
  data: () => ({
    headers: [
      { text: "Producto", value: "producto" },
      { text: "Pallet ", value: "pallet" },
      { text: "Fecha de Corte", value: "fecha" },
      { text: "Cajas", value: "cantidad", align: "end" }
    ],
    items: [],
    search: undefined
  }),
  computed: {
    ...mapState({
      detalles: state => state.Inventario.detalles.pallets
    }),
    vencidos() {
      return this.detalles.filter(x => {
        return x.vencido && x.presentacion !== "KGS";
      });
    }
  },
  methods: {
    armarItems() {
      let productos = [...new Set(this.vencidos.map(x => x.producto))].sort();
      productos.forEach(producto => {
        let filtrados = this.vencidos.filter(x => x.producto == producto),
          pallets = [...new Set(filtrados.map(a => a.pallet))].sort();
        pallets.forEach(pallet => {
          let porPallet = filtrados.filter(b => b.pallet == pallet);
          if (_.sumBy(porPallet, "cantidad") < 80) {
            this.items.push({
              producto,
              pallet,
              fecha: porPallet[0].fecha_corte,
              cantidad: _.sumBy(porPallet, "cantidad")
            });
          }
        });
      });
    }
  },
  mounted() {
    this.armarItems();
  }
};
</script>
