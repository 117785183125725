<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4" xl="2">
          <v-text-field
            dense
            outlined
            clearable
            label="Fecha Inicial"
            v-model="inicio"
            type="date"
            hide-details="auto"
            :error-messages="errors.inicio"
            :disabled="loader"
          />
        </v-col>
        <v-col cols="12" md="4" xl="2">
          <v-text-field
            dense
            outlined
            clearable
            label="Fecha Final"
            v-model="fin"
            type="date"
            hide-details="auto"
            :error-messages="errors.fin"
            :disabled="loader"
          />
        </v-col>
        <v-col cols="12" md="4" xl="2">
          <v-btn color="info" @click="enviar" :disabled="loader">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="resultado" class="mt-2">
        <v-col cols="12" md="6">
          <v-card height="100%">
            <v-card-text>
              <pie-chart
                :chart-data="chartData"
                :options="options"
                :height="250"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card height="100%">
            <v-card-text>
              Lotes en el periodo: {{ lotes }}
              <v-data-table
                dense
                :headers="headers"
                :items="items"
                class="elevation-1"
                mobile-breakpoint="0"
                hide-default-footer
              >
                <template v-slot:[`item.value`]="{ item }">
                  {{ item.value | decimal }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import PieChart from "../PieChart";
export default {
  name: "Rendimiento",
  components: { PieChart },
  data: () => ({
    inicio: undefined,
    fin: undefined,
    resultado: false,
    chartData: undefined,
    options: {
      maintainAspectRatio: false
    },
    lotes: undefined,
    items: undefined,
    headers: [
      { text: "Tipo de Aguacate", value: "name" },
      { text: "Kilos", value: "value", align: "end" }
    ]
  }),
  computed: {
    ...mapState({
      loader: state => state.loader,
      errors: state => state.errors
    })
  },
  methods: {
    ...mapMutations(["setLoader", "setErrors"]),
    ...mapActions(["errores"]),
    enviar() {
      let data = {
        inicio: this.inicio,
        fin: this.fin
      };
      this.resultado = false;
      this.setLoader(true);
      this.setErrors();
      window.axios
        .post("Gerencia/dashboard/rendimiento", data)
        .then(resp => {
          this.resultado = true;
          this.chartData = resp.data.graph;
          this.lotes = resp.data.lotes;
          this.items = resp.data.items;
        })
        .catch(e => this.errores(e))
        .finally(this.setLoader(false));
    }
  },
  mounted() {
    this.setErrors();
    this.setLoader(false);
  }
};
</script>
