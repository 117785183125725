import router from "../../router";
const url = "Gerencia/employes";
const initialState = () => ({
  headers: [],
  collection: undefined,
  resource: undefined,
  loader: false,
  paginado: { currentPage: 1 },
  pagos: undefined,
  archivos: undefined
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    setLoader(state, payload = true) {
      state.loader = payload;
    },
    setCollection(state, payload = undefined) {
      state.collection = payload;
    },
    setPaginado(state, payload) {
      state.paginado = payload;
    },
    setHeaders(state, payload) {
      state.headers = payload;
    },
    setResource(state, payload) {
      state.resource = payload;
    },
    setPagos(state, payload = []) {
      state.pagos = payload;
    },
    setArchivos(state, payload) {
      state.archivos = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch, state }) {
      commit("setLoader");
      commit("setCollection");
      window.axios
        .get(url, {
          params: {
            page: state.paginado.currentPage,
            search: state.paginado.search
          }
        })
        .then(resp => {
          commit("setPaginado", resp.data.paginado);
          commit("setCollection", resp.data.collection);
          commit("setHeaders", resp.data.headers);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async getResource({ commit, dispatch }, id) {
      commit("setLoader");
      await window.axios
        .get(`${url}/${id}`)
        .then(resp => {
          commit("setResource", resp.data.resource);
          commit("setPagos", resp.data.pagos);
          commit("setArchivos", resp.data.archivos);
          commit("setPaginado", resp.data.paginado);
          router
            .push({ name: "employes.show", params: { id } })
            .catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async getPagos({ commit, dispatch, state }) {
      commit("setLoader");
      commit("setPagos");
      window.axios
        .get(`${url}/getPagos/${state.resource.id}`, {
          params: {
            page: state.paginado.currentPage
          }
        })
        .then(resp => {
          commit("setPaginado", resp.data.paginado);
          commit("setPagos", resp.data.pagos);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    }
  }
};
