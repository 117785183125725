<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Pagos Realizados
    </v-expansion-panel-header>
    <Formulario v-if="nuevo" @cancelar="nuevo = false" />
    <Tabla v-else @agregar="nuevo = true" />
  </v-expansion-panel>
</template>

<script>
import Formulario from "./FormularioPago.vue";
import Tabla from "./TablaPagos.vue";
export default {
  name: "Pagos",
  components: { Formulario, Tabla },
  data: () => ({
    nuevo: false
  })
};
</script>
