<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      {{ items.length ? items.length : "" }} Factura{{
        items.length > 1 ? "s" : ""
      }}
    </v-expansion-panel-header>
    <v-expansion-panel-content class="mt-3">
      <v-row>
        <v-col>
          <v-text-field
            dense
            outlined
            clearable
            label="Buscar"
            v-model="search"
          />
        </v-col>
      </v-row>
      <v-data-table
        dense
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        class="elevation-1"
        :item-class="rowClasses"
        :loading="loader"
        :search.sync="search"
      >
        <template v-slot:[`item.date`]="{ item }">
          {{ item.date | date }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.total`]="{ item }">
          {{ item.total | moneda }}
        </template>
        <template v-slot:[`item.tipo_cambio`]="{ item }">
          {{ item.tipo_cambio | monedaSat }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <template v-if="item.status == 'PENDIENTE'">
            <v-btn icon color="error" :disabled="loader">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <v-btn
            icon
            :disabled="loader"
            color="primary"
            @click="getResource(item.id)"
          >
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Invoices",
  data: () => ({
    headers: [
      { text: "Fecha", value: "date" },
      { text: "Partida", value: "category" },
      { text: "Factura", value: "folio" },
      { text: "Importe Factura", value: "total", align: "end" },
      { text: "Metodo Pago", value: "MetodoPago" },
      { text: "Moneda", value: "moneda" },
      { text: "Tipo de Cambio", value: "tipo_cambio", align: "end" },
      { text: "Importe en Pesos", value: "importe", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    search: undefined
  }),
  computed: {
    ...mapState({
      items: state => state.Providers.invoices,
      loader: state => state.Providers.loader
    })
  },
  methods: {
    ...mapActions("Egresos", ["getAll", "getResource"])
  }
};
</script>
