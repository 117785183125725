import router from "../../router";
const url = "Gerencia/comisionistas";
const base = "comisionistas";
const initialState = () => ({
  headers: [],
  collection: undefined,
  resource: undefined,
  loader: false,
  paginado: { currentPage: 1, search: null },
  panel: [],
  types: []
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setTypes(state, payload) {
      state.types = payload;
    },
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    setLoader(state, payload = true) {
      state.loader = payload;
    },
    setCollection(state, payload = undefined) {
      state.collection = payload;
    },
    setPaginado(state, payload) {
      state.paginado = payload;
    },
    setHeaders(state, payload) {
      state.headers = payload;
    },
    setResource(state, payload = {}) {
      state.resource = payload;
    },
    setPanel(state, payload = []) {
      state.panel = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch, state }) {
      commit("setLoader");
      commit("setCollection");
      window.axios
        .get(url, {
          params: {
            page: state.paginado.currentPage,
            search: state.paginado.search
          }
        })
        .then(resp => {
          commit("setPaginado", resp.data.paginado);
          commit("setCollection", resp.data.collection);
          commit("setHeaders", resp.data.headers);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async nuevo({ commit, dispatch }) {
      commit("setLoader");
      await window.axios
        .get(`${url}/create`)
        .then(resp => {
          commit("setTypes", resp.data.types);
          commit("setResource");
          commit("setPanel", [0]);
          router.push({ name: `${base}.create` }).catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async getResource({ commit, dispatch }, id) {
      commit("setLoader");
      await window.axios
        .get(`${url}/${id}`)
        .then(resp => {
          commit("setTypes", resp.data.types);
          commit("setResource", resp.data.resource);
          commit("setPanel");
          router.push({ name: `${base}.edit`, params: { id } }).catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async sendResource({ commit, dispatch, state }) {
      commit("setLoader");
      commit("setErrors", {}, { root: true });
      let resource = state.resource;
      if (resource.id) {
        window.axios
          .put(`${url}/${resource.id}`, resource)
          .then(resp => {
            dispatch("message", resp.data.message, { root: true });
            commit("setResource", resp.data.resource);
            commit("setPanel");
          })
          .catch(e => dispatch("errores", e, { root: true }))
          .finally(() => commit("setLoader", false));
      } else {
        window.axios
          .post(`${url}`, resource)
          .then(resp => {
            dispatch("message", resp.data.message, { root: true });
            dispatch("getResource", resp.data.id);
          })
          .catch(e => dispatch("errores", e, { root: true }))
          .finally(() => commit("setLoader", false));
      }
    },
    async disableResource({ commit, dispatch }, id) {
      commit("setLoader");
      await window.axios
        .delete(`${url}/${id}`)
        .then(resp => {
          dispatch("message", resp.data.message, { root: true });
          dispatch("getAll");
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async enableResource({ commit, dispatch }, id) {
      commit("setLoader");
      await window.axios
        .post(`${url}/enableResource/${id}`)
        .then(resp => {
          dispatch("message", resp.data.message, { root: true });
          dispatch("getAll");
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    }
  }
};
