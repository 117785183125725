<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Complementos de Pagos
    </v-expansion-panel-header>
    <v-expansion-panel-content class="mt-3">
      <v-data-table
        dense
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        class="elevation-1"
      >
        <template v-slot:[`item.Fecha`]="{ item }">
          {{ item.Fecha | dateTime }}
        </template>
        <template v-slot:[`item.ImpSaldoAnt`]="{ item }">
          {{ item.ImpSaldoAnt | moneda }}
        </template>
        <template v-slot:[`item.ImpPagado`]="{ item }">
          {{ item.ImpPagado | moneda }}
        </template>
        <template v-slot:[`item.ImpSaldoInsoluto`]="{ item }">
          {{ item.ImpSaldoInsoluto | moneda }}
        </template>
        <template v-slot:[`item.pesos`]="{ item }">
          {{ importePesos(item) | moneda }}
        </template>
        <template v-slot:[`item.TipoCambioP`]="{ item }">
          {{ item.TipoCambioP | monedaSat }}
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Pagos",
  computed: {
    ...mapState({
      items: state => state.Invoices.pagos,
      resource: state => state.Invoices.resource
    })
  },
  data: () => ({
    headers: [
      { text: "Fecha", value: "Fecha" },
      { text: "Parcialidad", value: "NumParcialidad" },
      { text: "Folio Sat", value: "UUID" },
      { text: "Moneda", value: "MonedaDR" },
      { text: "Saldo Anterior", value: "ImpSaldoAnt", align: "end" },
      { text: "Importe Pagado", value: "ImpPagado", align: "end" },
      { text: "Saldo", value: "ImpSaldoInsoluto", align: "end" },
      { text: "Tipo de Cambio", value: "TipoCambioP", align: "end" },
      { text: "Importe en Pesos ", value: "pesos", align: "end" }
    ]
  }),
  methods: {
    importePesos(item) {
      return parseFloat(item.ImpPagado) * parseFloat(item.TipoCambioP);
    }
  }
};
</script>
