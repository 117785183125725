<!-- eslint-disable -->
<template>
  <v-footer color="primary" app>
    <span class="white--text">
      {{ appName }} &copy; {{ new Date().getFullYear() }}
    </span>
  </v-footer>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      appName: state => state.appName
    })
  }
};
</script>
