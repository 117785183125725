const routes = [
  {
    path: "/Facturas-Nacionales",
    name: "nacionales",
    component: require("@/views/nacionales/index.vue").default,
    meta: {
      name: "Control de Facturas Nacionales"
    }
  },
  {
    path: "/Facturas-Nacionales/Nueva",
    name: "nacionales.create",
    component: require("@/views/nacionales/create.vue").default,
    meta: {
      name: "Nueva Factura Nacional"
    }
  }
];
export default routes;
