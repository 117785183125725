<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Datos del Proveedor {{ resource.name }}
    </v-expansion-panel-header>
    <v-expansion-panel-content class="mt-3">
      <v-form @submit.prevent="sendResource" :disabled="loader">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              outlined
              label="Nombre"
              v-model="resource.name"
              disabled
              :error-messages="errors.name"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              dense
              outlined
              label="RFC"
              v-model="resource.rfc"
              disabled
              :error-messages="errors.rfc"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              dense
              outlined
              label="Correo"
              v-model="resource.email"
              :error-messages="errors.email"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              dense
              outlined
              label="Teléfono"
              v-model="resource.telefono"
              :error-messages="errors.telefono"
            />
          </v-col>
          <v-col cols="12" xl="6">
            <v-text-field
              dense
              outlined
              label="Nombre Contador"
              v-model="resource.contador"
              :error-messages="errors.contador"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              outlined
              label="Teléfono Contador"
              v-model="resource.telefono_contador"
              :error-messages="errors.telefono_contador"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              outlined
              label="Correo Contador"
              v-model="resource.email_contador"
              :error-messages="errors.email_contador"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="primary" type="submit" :disabled="modificado">actualizar</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Provider",
  data: () => ({
    original: undefined
  }),
  computed: {
    ...mapState({
      resource: state => state.Providers.resource,
      loader: state => state.Providers.loader,
      errors: state => state.errors
    }),
    modificado() {
      return JSON.stringify(this.original) == JSON.stringify(this.resource);
    }
  },
  mounted() {
    this.original = Object.assign({}, this.resource);
  },
  methods:{
    ...mapActions('Providers',['sendResource'])
  },
  watch:{
    resource(val){
      this.original=Object.assign(val);
    }
  }
};
</script>
