import router from "../../router";
const url = "Gerencia/clientes";
const initialState = () => ({
  headers: [],
  collection: [],
  resource: undefined,
  loader: false
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    SET_HEADERS(state, payload) {
      state.headers = payload;
    },
    setCollection(state, payload) {
      state.collection = payload;
    },
    setResource(state, payload) {
      state.resource = payload;
    },
    setLoader(state, payload) {
      state.loader = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch }) {
      commit("setCollection", []);
      window.axios
        .get(url)
        .then(resp => {
          commit("setCollection", resp.data.collection);
          commit("SET_HEADERS", resp.data.headers);
        })
        .catch(e => dispatch("errores", e, { root: true }));
    },
    async editar({ commit, dispatch }, id) {
      commit("setLoader", true);
      await window.axios
        .get(`${url}/${id}`)
        .then(resp => {
          let cliente = resp.data.resource,
            id = cliente.id,
            nombre = `Cliente ${cliente.nombre}`;
          commit("setResource", cliente);
          router.push({ name: "cliente.edit", params: { id } }).catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async update({ commit, dispatch, state }) {
      commit("setErrors", [], { root: true });
      commit("setLoader", true);
      let cliente = state.resource;
      if (cliente.id) {
        await window.axios
          .put(`${url}/${cliente.id}`, cliente)
          .then(resp => {
            window.swal(resp.data.message, { icon: "success", timer: 1500 });
            commit("setResource", resp.data.resource);
            let index = state.collection.findIndex(
              x => x.id == resp.data.resource.id
            );
            state.collection.splice(index, 1, resp.data.tabla);
          })
          .catch(e => dispatch("errores", e, { root: true }))
          .finally(() => commit("setLoader", false));
      } else {
        await window.axios
          .post(`${url}`, cliente)
          .then(resp => {
            window.swal(resp.data.message, { icon: "success", timer: 1500 });
            state.collection.unshift(resp.data.tabla);
            dispatch("editar", resp.data.tabla.id);
          })
          .catch(e => dispatch("errores", e, { root: true }))
          .finally(() => commit("setLoader", false));
      }
    }
  }
};
