var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[(_vm.resource)?_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"xl":"3"}},[_vm._v(" Cliente: "),_c('strong',[_vm._v(_vm._s(_vm.resource.Nombre))])]),_c('v-col',{attrs:{"xl":"2"}},[_vm._v(" Fecha de Pago: "),_c('strong',[_vm._v(_vm._s(_vm._f("date")(_vm.resource.FechaPago)))])]),_c('v-col',{attrs:{"xl":"2"}},[_vm._v(" Importe: "),_c('strong',[_vm._v(_vm._s(_vm._f("moneda")(_vm.resource.Monto))+" "+_vm._s(_vm.resource.MonedaP))])]),_c('v-col',{attrs:{"xl":"2"}},[_vm._v(" Tipo de Cambio: "),_c('strong',[_vm._v(_vm._s(_vm._f("monedaSat")(_vm.resource.TipoCambioP)))])]),_c('v-col',{attrs:{"xl":"2"}},[_vm._v(" Cuenta Destino: "),_c('strong',[_vm._v(_vm._s(_vm.resource.CtaBeneficiario))])])],1)],1):_vm._e(),(_vm.items)?_c('v-card-text',{staticClass:"mt-1"},[_c('h3',[_vm._v("Facturas Relacionadas al Pago")]),_c('v-data-table',{staticClass:"elevation-1 mt-2",attrs:{"dense":"","mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","items-per-page":_vm.items.length},scopedSlots:_vm._u([{key:"item.factura",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Serie)+" "+_vm._s(item.Folio)+" ")]}},{key:"item.ImpSaldoAnt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.ImpSaldoAnt))+" ")]}},{key:"item.ImpPagado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.ImpPagado))+" ")]}},{key:"item.ImpSaldoInsoluto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.ImpSaldoInsoluto))+" ")]}},{key:"item.pesos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(_vm.importePesos(item)))+" ")]}}],null,true)})],1):_vm._e(),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("regresar")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }