var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.cortes)?_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"green lighten-4"}},[_vm._v(" Transportistas ")]),_c('v-expansion-panel-content',[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","xl":"4"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","append-icon":"mdi-magnify","label":"Busqueda","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.print}},[_c('v-icon',[_vm._v("mdi-printer")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-3",attrs:{"dense":"","search":_vm.search,"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","items-per-page":_vm.items.length},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.lote",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("folio")(item.lote))+" ")]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fecha))+" ")]}},{key:"item.importe_transportista",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe_transportista))+" ")]}},{key:"item.iva_transportista",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.iva_transportista))+" ")]}},{key:"item.total_transportista",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.total_transportista))+" ")]}},{key:"item.retencion_iva_transportista",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.retencion_iva_transportista))+" ")]}}],null,true)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }