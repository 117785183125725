<!-- eslint-disable -->
<template>
  <div class="mt-3">
    <v-row>
      <v-col cols="12" xl="8">
        <p class="mt-5">Total de kilos: {{ totalKilos | decimal }}</p>
      </v-col>
      <v-col cols="12" xl="4">
        <v-container>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Busqueda"
            single-line
            hide-details
          />
        </v-container>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      class="elevation-1"
      :search.sync="search"
    >
      <template v-slot:[`item.lotes`]="{ item }">
        <template v-for="lote in item.lotes">
          {{ `${lote.lote} ` }}
        </template>
      </template>
      <template v-slot:[`item.kilos`]="{ item }">
        {{ item.kilos | decimal }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  name: "Nacional",
  data: () => ({
    headers: [
      { text: "Producto", value: "producto", width: "25%" },
      /*  { text: "Lotes", value: "lotes", width: "65%" }, */
      { text: "Kilos", value: "kilos", align: "end" }
    ],
    search: undefined
  }),
  computed: {
    ...mapState({
      items: state => state.Inventario.detalles.nacional
    }),
    totalKilos() {
      //let filtrados=this.detalles.filter(x=>x.presentacion=='KGS')
      return _.sumBy(this.items, "kilos");
    }
  },
  methods: {
    ...mapActions("Inventario", ["getAll"]),
    armarItems(producto) {
      let filtrados = this.nacional.filter(x => x.producto == producto),
        obj = {
          producto,
          lotes: filtrados,
          kilos: _.sumBy(filtrados, "kilos")
        };
      this.items.push(obj);
    }
  },
  mounted() {
    this.getAll;
  }
};
</script>
