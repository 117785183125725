var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[_c('v-card-text',[_c('v-form',{attrs:{"disabled":_vm.loader},on:{"submit":function($event){$event.preventDefault();return _vm.getAll('ex')}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","xl":"2"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Fecha Inicial","type":"date"},model:{value:(_vm.paginado.inicio),callback:function ($$v) {_vm.$set(_vm.paginado, "inicio", $$v)},expression:"paginado.inicio"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","xl":"2"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Fecha Final","type":"date"},model:{value:(_vm.paginado.fin),callback:function ($$v) {_vm.$set(_vm.paginado, "fin", $$v)},expression:"paginado.fin"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","clearable":"","label":"Filtrar por Cliente","items":_vm.paginado.customers},model:{value:(_vm.paginado.customer_id),callback:function ($$v) {_vm.$set(_vm.paginado, "customer_id", $$v)},expression:"paginado.customer_id"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"info","type":"submit"}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-2",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.paginado.perPage,"hide-default-footer":"","mobile-breakpoint":"0","loading":_vm.loader,"item-class":_vm.clase},scopedSlots:_vm._u([{key:"item.TipoCambio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("monedaSat")(item.TipoCambio))+" ")]}},{key:"item.Importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.Importe))+" ")]}},{key:"item.Total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.Total))+" ")]}},{key:"item.Fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.Fecha))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary","disabled":_vm.loader},on:{"click":function($event){return _vm.getResource(item.id)}}},[_c('v-icon',[_vm._v("mdi-information")])],1),_c('v-btn',{attrs:{"icon":"","color":"secondary"},on:{"click":function($event){return _vm.print(item)}}},[_c('v-icon',[_vm._v("mdi-printer")])],1),_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.email(item)}}},[_c('v-icon',[_vm._v("mdi-email")])],1)]}}],null,true)}),_c('Paginado',{attrs:{"loader":_vm.loader,"paginado":_vm.paginado}}),(_vm.items && _vm.items.length)?_c('v-row',{staticClass:"mt-2"},[_c('v-col',[_vm._v(" Importe del Periodo en Pesos: "+_vm._s(_vm._f("moneda")(_vm.paginado.pesos))+" ")])],1):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Enviar Factura "+_vm._s(_vm.seleccionado.Folio)+" por Correo ")]),_c('v-card-text',{staticClass:"mt-2"},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Destinatarios","hint":"Ingresa los correos de destino separados por punto y coma ( ; )","error-messages":_vm.errors.emails},model:{value:(_vm.seleccionado.email),callback:function ($$v) {_vm.$set(_vm.seleccionado, "email", $$v)},expression:"seleccionado.email"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.enviar}},[_vm._v(" enviar ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.cancelar}},[_vm._v("cancelar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }