import router from "../../router";
const url = "Gerencia/articulos";
const initialState = () => ({
  headers: [],
  collection: undefined,
  resource: undefined,
  loader: false,
  paginado: { currentPage: 1 },
  ClavesUnidad: undefined,
  UnidadesAduana: undefined,
  Impuestos: undefined,
  TiposFactor: undefined
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setTiposFactor(state, payload) {
      state.TiposFactor = payload;
    },
    setUnidadesAduana(state, payload) {
      state.UnidadesAduana = payload;
    },
    setClavesUnidad(state, payload) {
      state.ClavesUnidad = payload;
    },
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    setLoader(state, payload = true) {
      state.loader = payload;
    },
    setCollection(state, payload = undefined) {
      state.collection = payload;
    },
    setPaginado(state, payload) {
      state.paginado = payload;
    },
    setHeaders(state, payload) {
      state.headers = payload;
    },
    setResource(state, payload) {
      state.resource = payload;
    },
    setImpuestos(state, payload) {
      state.Impuestos = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch, state }) {
      commit("setLoader");
      commit("setCollection");
      window.axios
        .get(url, {
          params: {
            page: state.paginado.currentPage,
            search: state.paginado.search
          }
        })
        .then(resp => {
          commit("setPaginado", resp.data.paginado);
          commit("setCollection", resp.data.collection);
          commit("setHeaders", resp.data.headers);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async nuevo({ commit, dispatch }) {
      commit("setLoader");
      await window.axios
        .get(`${url}/create`)
        .then(resp => {
          commit("setResource", {});
          commit("setClavesUnidad", resp.data.ClavesUnidad);
          commit("setUnidadesAduana", resp.data.UnidadesAduana);
          commit("setImpuestos", resp.data.Impuestos);
          commit("setTiposFactor", resp.data.TiposFactor);
          router.push({ name: "supplies.create" }).catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async getResource({ commit, dispatch }, id) {
      commit("setLoader");
      await window.axios
        .get(`${url}/${id}`)
        .then(resp => {
          commit("setResource", resp.data.resource);
          commit("setClavesUnidad", resp.data.ClavesUnidad);
          commit("setUnidadesAduana", resp.data.UnidadesAduana);
          commit("setImpuestos", resp.data.Impuestos);
          commit("setTiposFactor", resp.data.TiposFactor);
          router
            .push({ name: "supplies.edit", params: { id } })
            .catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async sendResource({ commit, dispatch, state }) {
      commit("setLoader");
      commit("setErrors", {}, { root: true });
      if (state.resource.id) {
        await window.axios
          .put(`${url}/${state.resource.id}`, state.resource)
          .then(resp => {
            dispatch("message", resp.data.message, { root: true });
            router.push({ name: "supplies" }).catch(() => {});
          })
          .catch(e => dispatch("errores", e, { root: true }))
          .finally(() => commit("setLoader", false));
      } else {
        await window.axios
          .post(url, state.resource)
          .then(resp => {
            dispatch("message", resp.data.message, { root: true });
            router.push({ name: "supplies" }).catch(() => {});
          })
          .catch(e => dispatch("errores", e, { root: true }))
          .finally(() => commit("setLoader", false));
      }
    }
  }
};
