import router from "../../router";
const url = "FR/dashboard";
const initialState = () => ({
  inventario: [],
  ventas: {},
  facturas: {},
  pagos: {}
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    SET_INVENTARIO(state, payload) {
      state.inventario = payload;
    },
    SET_VENTAS(state, payload) {
      state.ventas = payload;
    },
    SET_FACTURAS(state, payload) {
      state.facturas = payload;
    },
    SET_PAGOS(state, payload) {
      state.pagos = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch }) {
      commit("SET_INVENTARIO", []);
      commit("SET_VENTAS", {});
      commit("SET_FACTURAS", {});
      commit("SET_PAGOS", {});
      window.axios
        .get(url)
        .then(resp => {
          commit("SET_INVENTARIO", resp.data.inventario);
          commit("SET_VENTAS", resp.data.ventas);
          commit("SET_FACTURAS", resp.data.facturas);
          commit("SET_PAGOS", resp.data.pagos);
        })
        .catch(e => dispatch("errores", e, { root: true }));
    }
  }
};
