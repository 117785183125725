<!-- eslint-disable -->
<template>
  <div>
    <v-navigation-drawer v-model="drawer" app>
      <div class="text-center py-5">
        <img :src="require('./logo.jpeg')" width="40%" alt="" />
      </div>
      <v-list dense shaped>
        <v-list-item-group color="primary">
          <v-list-group :value="false" prepend-icon="mdi-folder">
            <template v-slot:activator>
              <v-list-item-title>Catálogos</v-list-item-title>
            </template>
            <v-list-item
              link
              :to="{ name: item.link }"
              v-for="item in catalogos"
              :key="item.title"
            >
              <v-list-item-action>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-group :value="false" prepend-icon="mdi-folder">
            <template v-slot:activator>
              <v-list-item-title>Facturas</v-list-item-title>
            </template>
            <v-list-item
              link
              :to="{ name: item.link }"
              v-for="item in facturas"
              :key="item.title"
            >
              <v-list-item-action>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item
            :href="url"
            target="_blank"
            v-if="user.email == 'developer@zamgod.mx'"
          >
            <v-list-item-action>
              <v-icon>mdi-bug</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logs</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            :to="{ name: item.link }"
            v-for="item in otros"
            :key="item.title"
            :class="item.class"
          >
            <v-list-item-action>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="primary">
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        class="white--text"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="white--text" v-text="name"></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        dark
        v-for="item in barra"
        :key="item.title"
        icon
        link
        :to="{ name: item.link }"
        class="d-none d-xl-flex"
        exact
      >
        <v-icon>{{ item.icon }}</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name:'Menu',
  data: () => ({
    drawer: null,
    catalogos: [
      { title: "Usuarios", link: "users", icon: "mdi-account" },
      { title: "Clientes", link: "clientes", icon: "mdi-account" },
      { title: "Empleados", link: "employes", icon: "mdi-account" },
      { title: "Proveedores", link: "providers", icon: "mdi-account" },
      { title: "Artículos", link: "supplies", icon: "mdi-alpha-a-box" },
      { title: "Vehículos", link: "vehicles", icon: "mdi-car" },
      { title: "Comisionistas", link: "comisionistas", icon: "mdi-account" },
    ],
    algo: [
      { title: "Inicio", link: "inicio", icon: "mdi-home" },
      { title: "Precios", link: "prices", icon: "mdi-cash-usd" },
      { title: "Inventario", link: "inventario", icon: "mdi-view-module" },
      { title: "Ventas Nacionales", link: "ventas", icon: "mdi-point-of-sale" },
      { title: "Embarques", link: "embarques", icon: "mdi-truck" },
      { title: "Pallets", link: "pallets", icon: "mdi-alpha-p-box" },
      { title: "Calculadora", link: "calculadora", icon: "mdi-calculator" },
      { title: "Lotes", link: "lotes", icon: "mdi-apps" },
      { title: "Pagos", link: "pagos", icon: "mdi-currency-usd-circle" },
      { title: "Egresos", link: "egresos", icon: "mdi-currency-usd-circle" },
      { title: "Gráficas", link: "charts", icon: "mdi-chart-box" },
      { title: "Salir", link: "logout", icon: "mdi-power" }
    ],
    facturas: [
      { title: "Nacionales", link: "nacionales", icon: "mdi-file-pdf" },
      { title: "Exportacion", link: "exportacion", icon: "mdi-file-pdf" },
      { title: "Notas Crédito", link: "notas", icon: "mdi-file-pdf" },
      { title: "Comp. de Pagos", link: "payments", icon: "mdi-cash" }
    ]
  }),
  computed: {
    ...mapState({
      user: state => state.Auth.user,
      otros: state => state.Auth.menu
    }),

    name() {
      if (this.$route.params.nombre) return this.$route.params.nombre;
      return this.$route.meta ? this.$route.meta.name : "";
    },
    url() {
      let urlBase = process.env.VUE_APP_BASE_URL,
        url = urlBase.substring(0, urlBase.length - 4);
      return url + "log-viewer/logs";
    },
    barra() {
      return this.otros.filter(x => x.class);
    }
  }
};
</script>
