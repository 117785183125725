<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-title primary-title>
      Toneladas Aguacate Nacional
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <bar-chart
            :chart-data="datacollection"
            :options="options"
            :height="250"
          />
        </v-col>
        <v-col>
          <v-data-table
            dense
            :headers="headers"
            :items="items"
            class="elevation-1"
            hide-default-footer
            mobile-breakpoint="0"
          >
            <template v-slot:[`item.total`]="{ item }">
              {{ Total(item) | decimal }}
            </template>
            <template v-slot:[`item.year`]="{ item }">
              {{ item.label }}
            </template>
            <template v-slot:[`item.0`]="{ item }">
              {{ item.data[0] }}
            </template>
            <template v-slot:[`item.1`]="{ item }">
              {{ item.data[1] }}
            </template>
            <template v-slot:[`item.2`]="{ item }">
              {{ item.data[2] }}
            </template>
            <template v-slot:[`item.3`]="{ item }">
              {{ item.data[3] }}
            </template>
            <template v-slot:[`item.4`]="{ item }">
              {{ item.data[4] }}
            </template>
            <template v-slot:[`item.5`]="{ item }">
              {{ item.data[5] }}
            </template>
            <template v-slot:[`item.6`]="{ item }">
              {{ item.data[6] }}
            </template>
            <template v-slot:[`item.7`]="{ item }">
              {{ item.data[7] }}
            </template>
            <template v-slot:[`item.8`]="{ item }">
              {{ item.data[8] }}
            </template>
            <template v-slot:[`item.9`]="{ item }">
              {{ item.data[9] }}
            </template>
            <template v-slot:[`item.10`]="{ item }">
              {{ item.data[10] }}
            </template>
            <template v-slot:[`item.11`]="{ item }">
              {{ item.data[11] }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="actualizar">actualizar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import BarChart from "../BarChart.js";
export default {
  name: "National",
  components: {
    BarChart
  },
  data() {
    return {
      datacollection: {},
      options: {
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                stepSize: 10
              }
            }
          ]
        }
      },
      minimo: 0
    };
  },
  mounted() {
    this.actualizar();
  },
 methods: {
    actualizar() {
      this.datacollection = {
        datasets: []
      };
      window.axios
        .get("Gerencia/dashboard/national")
        .then(resp => {
          this.datacollection = resp.data;
        })
        .catch(e =>
          window.swal(e.response.data.message, { icon: "error", timer: 2500 })
        );
    },
    Total(item) {
      let cantidad = 0;
      item.data.forEach(x => {
        cantidad += parseFloat(x);
      });
      return cantidad.toFixed(2);
    }
  },
  computed: {
    headers() {
      if (this.datacollection.labels) {
        let array = [{ text: "AÑO", value: "year" }];
        this.datacollection.labels.forEach(x => {
          let obj = {
            text: x,
            value: this.datacollection.labels.indexOf(x),
            align: "end"
          };
          array.push(obj);
        });
        array.push({ text: "TOTAL", value: "total", align: "end" });
        return array;
      }
      return [];
    },
    items() {
      return this.datacollection.datasets;
    }
  }
};
</script>
