<!-- eslint-disable -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="blue lighten-4">
      Seleccionados
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" md="6">
          <h4 class="mt-3">Pallets que se darán de baja</h4>
          <v-data-table
            dense
            mobile-breakpoint="0"
            :headers="headers"
            :items="items"
            class="elevation-1 mt-3"
            :search.sync="search"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon color="error" @click="eliminar(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.cantidad`]="{ item }">
              {{ item.cantidad | decimal }}
            </template>
            <template v-slot:[`item.kilos_vendidos`]="{ item }">
              <v-text-field
                dense
                type="number"
                step="0.01"
                min="0"
                class="righted-input"
                v-model.number="item.kilos_vendidos"
              />
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" md="6">
          <h4 class="mt-3">Concentrado</h4>
          <v-data-table
            dense
            mobile-breakpoint="0"
            :headers="headers2"
            :items="items2"
            class="elevation-1 mt-3"
          >
            <template v-slot:[`item.kilos`]="{ item }">
              {{ item.kilos | decimal }}
            </template>
            <template v-slot:[`item.precio_kilo`]="{ item }">
              <v-text-field
                dense
                type="number"
                step="0.01"
                min="0"
                class="righted-input text-right"
                v-model.number="item.precio_kilo"
              />
            </template>
            <template v-slot:[`item.importe`]="{ item }">
              {{ importe(item) | moneda }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Seleccionados from ".";
export default {
  name: "Seleccionados",
  data: () => ({
    search: undefined,
    headers: [
      { text: "Pallet", value: "pallet" },
      { text: "Producto", value: "producto" },
      { text: "Pesada", value: "cantidad", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    headers2: [
      { text: "Producto", value: "producto" },
      { text: "Kilos", value: "kilos", align: "end" },
      { text: "Precio X kilo", value: "precio_kilo", align: "end" },
      { text: "Importe", value: "importe", align: "end" }
    ],
    total: 0
  }),
  computed: {
    ...mapState({
      items: state => state.Ventas.seleccionados
    }),
    items2() {
      let productos = [...new Set(this.items.map(x => x.producto))].sort(),
        data = [];
      productos.forEach(producto => {
        let items = this.items.filter(x => x.producto == producto),
          item = {
            producto,
            producto_id: items[0].producto_id,
            kilos: window._.sumBy(items, "cantidad"),
            total: 0,
            precio_kilo: 0
          };
        data.push(item);
      });
      return data;
    },
    importeVenta() {
      return window._.sumBy(this.items2, "total");
    }
  },

  methods: {
    ...mapActions("Ventas", ["removeItem"]),
    importe(item) {
      let items = this.items.filter(x => x.producto == item.producto);
      items.forEach(x => (x.precio_kilo = item.precio_kilo));
      item.total = item.precio_kilo * item.kilos;
      return item.precio_kilo * item.kilos;
    },
    eliminar(item) {
      this.removeItem(item);
    }
  }
};
</script>
