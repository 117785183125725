<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <v-autocomplete
            dense
            outlined
            clearable
            label="Filtrar por Cliente"
            type="search"
            :items="paginado.customers"
            v-model="paginado.customer"
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            dense
            outlined
            label="Filtrar por Estatus"
            type="search"
            :items="paginado.statusses"
            v-model="paginado.status"
          />
        </v-col>
        <v-col cols="12" md="2">
          <v-btn color="success" @click="nueva" :disabled="loader">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn color="info" class=" ml-3" @click="getAll" :disabled="loader">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        class="elevation-1"
        :search.sync="search"
        hide-default-footer
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | folio }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | date }}
        </template>
        <template v-slot:[`item.kilos`]="{ item }">
          {{ item.kilos | decimal }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="success"
            @click="editVenta(item.id)"
            :disabled="loader"
            v-if="!item.factura"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            color="red"
            @click="eliminar(item)"
            :disabled="loader"
            v-if="!item.factura"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn
            icon
            color="primary"
            @click="detalleVenta(item.id)"
            :disabled="loader"
            v-else
          >
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <Paginado :loader="loader" :paginado="paginado" />
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  name: "Ventas",
  data: () => ({
    search: undefined
  }),
  computed: {
    ...mapState({
      headers: state => state.Ventas.headers,
      items: state => state.Ventas.collection,
      loader: state => state.Ventas.loader,
      paginado: state => state.Ventas.paginado
    }),
    currentPage() {
      return this.paginado.currentPage;
    },
    customer() {
      return this.paginado.customer;
    },
    status() {
      return this.paginado.status;
    }
  },
  mounted() {
    this.getAll();
  },
  methods: {
    ...mapActions("Ventas", [
      "getAll",
      "detalleVenta",
      "deleteVenta",
      "editVenta"
    ]),
    ...mapMutations("Ventas", ["setSeleccionados"]),
    nueva() {
      this.setSeleccionados([]);
      this.$router.push({ name: "venta.create" });
    },
    eliminar(item) {
      let pregunta = `¿Deseas eliminar la  venta ${item.folio}?`,
        text = "El producto estará disponible para venta de nuevo";
      window
        .swal({
          title: pregunta,
          text: text,
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true
        })
        .then(confirmado => {
          if (confirmado) {
            this.deleteVenta(item.id);
          }
        });
    }
  },
  watch: {
    currentPage() {
      this.getAll();
    },
    customer() {
      this.getAll();
    },
    status() {
      this.getAll();
    }
  }
};
</script>
