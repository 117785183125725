import Index from "./../views/egresos/Index.vue";
const routes = [
  {
    path: "/Egresos",
    name: "egresos",
    component: Index,
    meta: {
      name: "Control de Egresos"
    }
  },
  {
    path: "/Egresos/Subir-xml",
    name: "egresos.upload",
    component: require("@/views/egresos/Upload.vue").default,
    meta: {
      name: "Subir Xml"
    }
  },
  {
    path: "/Egresos/Detalles/:id",
    name: "egresos.show",
    component: require("@/views/egresos/Show.vue").default,
    meta: {
      name: "Detalles Egreso"
    }
  },
  {
    path: "/Notas-crédito",
    name: "notas",
    component: require("@/views/notas/Index.vue").default,
    meta: {
      name: "Notas de Crédito"
    }
  }
];
export default routes;
