var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Cesión de Derechos ")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.itemsNew,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.lote",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("folio")(item.lote))+" ")]}},{key:"item.importe_productor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe_productor))+" ")]}},{key:"item.fecha_corte",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.fecha_corte))+" ")]}},{key:"item.importe_ceder",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"dense":"","hide-details":"auto","type":"number"},model:{value:(item.importe_ceder),callback:function ($$v) {_vm.$set(item, "importe_ceder", _vm._n($$v))},expression:"item.importe_ceder"}})]}}],null,true)}),(_vm.selected.length)?[_c('v-row',{staticClass:"my-2"},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Importe a Ceder: "),_c('strong',[_vm._v(_vm._s(_vm._f("moneda")(_vm.total)))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Fecha","type":"date","hide-details":"auto"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","label":"Beneficiario","hide-details":"auto"},model:{value:(_vm.beneficiario),callback:function ($$v) {_vm.beneficiario=$$v},expression:"beneficiario"}})],1)],1)]:_vm._e()],2),_c('v-card-actions',[(_vm.selected.length && _vm.beneficiario && _vm.date)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.imprimir}},[_vm._v(" imprimir ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.$emit('cerrar')}}},[_vm._v(" cerrar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }